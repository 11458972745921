import { Link } from 'react-router-dom';
import './OrderItem.scss';
import PropTypes from 'prop-types';

function OrderItem({ position, posIdx, idx }) {
  const dishIsAvailable = !position.position.is_on_stop;

  const getDishLink = (position) => {
    const isMenuAvailable = position.position.menu2
      ? position.position.menu2
      : position.position.menu;
    if (isMenuAvailable) {
      const categoryId = isMenuAvailable.id;
      const categorySlug = isMenuAvailable.slug;

      const dishId = position.position.id;
      const dishSlug = position.position.slug;

      return `/${categoryId}-${categorySlug}/${dishId}-${dishSlug}`;
    }
    return '';
  };

  const dishLink = getDishLink(position);

  return (
    <div className="order__dish-wrapper">
      <div className="order__dish">
        {dishIsAvailable && dishLink ? (
          <Link
            className="order__dish-img"
            to={dishLink}
            style={{ backgroundImage: `url(${position.position.images[0]})` }}
          ></Link>
        ) : (
          <div
            className="order__dish-img dish-unavailable-img"
            style={{
              backgroundImage: `url(${position.position.images[0]})`,
            }}
          ></div>
        )}
        <div className="order__dish-info">
          <div className="order__dish-info-row">
            {dishIsAvailable && dishLink ? (
              <Link className="link green-link order__dish-title" to={dishLink}>
                {position.position.name}
              </Link>
            ) : (
              <span className="link green-link order__dish-title dish-unavailable-title">
                {`${position.position.name} (блюдо недоступно)`}
              </span>
            )}
            <div className="order__dish-amount-price-wrapper">
              <span className="order__dish-amount">{position.quantity}шт.</span>
              <span className="order__dish-price">
                {position.price_total} <span className="rub-sign"> ₽</span>
              </span>
            </div>
          </div>
          {position.parts?.length > 0 && (
            <div className="order__dish-info-row">
              <ul className="order__checklist checklist">
                {position.parts.map((part, partIdx) => (
                  <li
                    className="checklist__item"
                    key={`${idx}-${posIdx}-${partIdx}`}
                  >
                    {part.position.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {position.toppings?.length > 0 &&
            position.toppings.map((topping, toppingIdx) => (
              <div
                className="order__dish-info-row"
                key={`${idx}-${posIdx}--${toppingIdx}`}
              >
                <div className="order__dish-ingredient">
                  {topping.position.name}
                </div>
                <div className="order__dish-amount-price-wrapper">
                  <span className="order__dish-amount">
                    {topping.quantity}шт.
                  </span>
                  <span className="order__dish-price">
                    {topping.price_total} <span className="rub-sign">₽</span>
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="divider orders__dish-item-divider" />
    </div>
  );
}

OrderItem.propTypes = {
  position: PropTypes.shape({
    position: PropTypes.shape({
      is_on_stop: PropTypes.bool,
      menu2: PropTypes.object,
      menu: PropTypes.object,
      id: PropTypes.number,
      slug: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
    }).isRequired,
    quantity: PropTypes.number.isRequired,
    price_total: PropTypes.number.isRequired,
    parts: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
    toppings: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        quantity: PropTypes.number.isRequired,
        price_total: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  posIdx: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
};

export default OrderItem;
