import { Tab, withStyles } from '@material-ui/core';

export const PresenceInfoTabs = withStyles((theme) => ({
  root: {
    color: theme.palette.profileTabs.notActive,
    minWidth: '0',

    '&:hover': {
      color: theme.palette.profileTabs.text,
    },
    '&$selected': {
      color: theme.palette.profileTabs.text,
    },
    '&:focus': {
      color: theme.palette.profileTabs.text,
    },
  },
  selected: {},
  '@media (max-width: 450px)': {
    wrapper: {
      fontSize: '12px',
    },
  },
}))((props) => <Tab {...props} />);
