import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actionCreators from '../../../store/actions';
import './LogOutModal.scss';
import { MESSAGE_TYPES } from '../../../util/constants';

function LogOutModal() {
  const history = useHistory();
  const dispatch = useDispatch();

  function clearModal() {
    dispatch(actionCreators.clearModal());
  }

  async function handleLogout() {
    try {
      await dispatch(actionCreators.logOut());
      clearModal();
      history.push('/');
    } catch {
      dispatch(actionCreators.setIsLoadingFailed(true));
      dispatch(
        actionCreators.setMessage({
          messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
        }),
      );
    }
  }
  return (
    <>
      <h2 className="log-out-modal-title">Вы действительно хотите выйти?</h2>

      <div className="confirm-log-out-text">Подтверждение выхода</div>

      <div className="buttons-wrapper">
        <button
          className="button-secondary log-out-modal-btn"
          onClick={clearModal}
        >
          Отмена
        </button>
        <button
          onClick={handleLogout}
          className="button-standard log-out-modal-btn"
        >
          Да
        </button>
      </div>
    </>
  );
}

export default LogOutModal;
