import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function setCookie(name, value, expiresInHours) {
  if (expiresInHours) {
    const expirationDate = new Date();
    let time = expirationDate.getTime();
    time += expiresInHours * 60 * 60 * 1000;
    expirationDate.setTime(time);
    cookies.set(name, value, { expires: expirationDate });
    return;
  }
  cookies.set(name, value);
}

export function getCookie(name) {
  return cookies.get(name);
}

export const deleteCookie = (name) => {
  if (getCookie(name)) {
    cookies.remove(name);
  }
};
