import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { PATHS, MODAL_TYPES } from '../../util/constants';

export function useRefreshAddress() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const presenceState = useSelector(
    (state) => state.currentUser.userInfo?.presence_state,
  );
  const apiRefreshTime = useSelector(
    (state) => state.currentUser.userInfo?.presence_refresh_at,
  );

  useEffect(() => {
    const currentDate = Date.now();
    const location = window.location.pathname;

    if (
      isAuthorized &&
      presenceState &&
      (!apiRefreshTime || currentDate > new Date(apiRefreshTime).getTime()) &&
      !decodeURI(location).includes('отзыв') &&
      !decodeURI(location).includes(PATHS.privacyPolicy)
    ) {
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.PRESENCE_INFO,
          dontNeedCloseBtn: true,
          dontNeedCloseByEsc: true,
          classNameOfModalContainer: 'presence-info-modal',
        }),
      );
    }
  }, [isAuthorized, presenceState, apiRefreshTime, dispatch]);
}
