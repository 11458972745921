import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import clsx from 'clsx';
import { getSuggestionsDebounced } from '../../util/api';
import * as actionCreators from '../../store/actions';
import { usePopperControl } from '../../reusable/hooks/usePopperControl';

function SearchBar({ searching, setSearching }) {
  const { openPopper, closePopper, popperTop, popperAnchor } = usePopperControl(
    { offset: -12 },
  );
  const dispatch = useDispatch();
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);

  const { pathname } = useLocation();

  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const searchContainerRef = useRef();

  const history = useHistory();

  const menuCategories = useSelector((state) => state.menus.menuCategories);

  const closeAndClear = useCallback(() => {
    setSearchValue('');
    setSearching(false);
    closePopper();
  }, [closePopper, setSearching]);

  useEffect(() => {
    const isSearchPage = pathname.includes('поиск');
    if (!isSearchPage) closeAndClear();
  }, [pathname, closeAndClear]);

  const getMenuCategorySlug = (id, menuCategoryId) => {
    if (!id || !menuCategories?.length) return '';

    return menuCategories.find(({ id }) => id === menuCategoryId)?.slug;
  };

  function toggleSearching() {
    setSearchValue('');
    if (!!popperAnchor) {
      closePopper();
    } else {
      setTimeout(() => openPopper(searchContainerRef.current), 1);
    }
    setSearching((currentState) => !currentState);
  }

  useEffect(() => {
    window.addEventListener('click', onBackdropClick);

    return () => {
      window.removeEventListener('click', onBackdropClick);
    };
  }, []);
  useEffect(() => {
    getSuggestionsDebounced.cancel();
    if (searchValue.length > 1) {
      getSuggestionsDebounced(searchValue, setSuggestions, dispatch);
    } else {
      setSuggestions([]);
    }
  }, [searchValue, dispatch]);

  useEffect(() => {
    dispatch(
      actionCreators.setSearchResults({
        searchResult: suggestions,
        isEmpty: suggestions.length === 0,
        query: searchValue,
      }),
    );
  }, [suggestions, dispatch]);

  function onBackdropClick(e) {
    if (!searchContainerRef.current) {
      return;
    }

    const { clientX, clientY } = e;
    const { top, left, right, bottom } =
      searchContainerRef.current.getBoundingClientRect();
    const vertical = clientY < bottom && clientY > top;
    const horizontal = clientX < right && clientX > left;

    if (!vertical || !horizontal) {
      closePopper();
    }
  }

  const keyPressHandler = (e) => {
    if (e.key === 'Enter' && searchValue.length >= 2) {
      history.push(`/поиск/${searchValue}`);
    }
  };

  if (!searching) {
    return (
      <button
        className="empty-button search-button simple-flex"
        onClick={toggleSearching}
        title="Поиск"
      >
        <span className="icon-search" />
      </button>
    );
  }

  return (
    <>
      <div
        className={clsx(
          'simple-flex',
          'search-bar-container',
          { full: searching },
          { 'is-authorized': isAuthorized },
        )}
        ref={searchContainerRef}
      >
        <div className="search-icon-wrapper simple-flex">
          <span className="icon-search" />
        </div>
        <InputBase
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          onClick={() => openPopper(searchContainerRef.current)}
          style={{ height: '100%', width: '100%' }}
          autoFocus
          onKeyPress={keyPressHandler}
        />
        <button
          className="empty-button simple-flex close-search-button"
          onClick={closeAndClear}
        >
          <span className="icon-close-search" />
        </button>
      </div>

      {suggestions.length > 0 && searchValue.length > 1 && (
        <Menu
          open={!!popperAnchor && !history.location.pathname.includes('поиск')}
          anchorEl={popperAnchor}
          autoFocus={false}
          disableEnforceFocus={true}
          onClose={closePopper}
          hideBackdrop
          MenuListProps={{ style: { paddingBottom: 0 } }}
          PaperProps={{ square: true }}
          className={clsx('search-suggestions-container', {
            'search-suggestions-container-for-authorized': isAuthorized,
          })}
          classes={{ paper: 'search-suggestions-paper', list: 'simple-flex' }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: popperTop,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {suggestions.map(
            (
              {
                id: ownId,
                name: ownName,
                slug: ownSlug,
                category: { slug: categorySlug, menu, name: categoryName },
              },
              idx,
            ) => (
              <MenuItem
                component={Link}
                to={`/${menu.id}-${getMenuCategorySlug(
                  ownId,
                  menu.id,
                )}/${ownId}-${ownSlug}`}
                key={ownId}
                className="simple-flex"
                onClick={closeAndClear}
              >
                <div
                  className={clsx('suggestion-body', {
                    'no-divider': idx === suggestions.length - 1,
                  })}
                >
                  <span className="suggestion-title">{ownName}</span>
                  <span className="suggestion-info">{categoryName}</span>
                </div>
              </MenuItem>
            ),
          )}
        </Menu>
      )}
    </>
  );
}

SearchBar.propTypes = {
  searching: PropTypes.bool.isRequired,
  setSearching: PropTypes.func.isRequired,
};

export default SearchBar;
