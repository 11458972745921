import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

let adjustedDuration = 1;
const ADJUSTMENT = 50;

function Flier({
  children,
  from,
  to,
  animate,
  durationMs,
  wrapperClass,
  onAnimationEnd,
  withOpacity,
  portal,
  withWidth,
}) {
  const [currentPos, setCurrentPos] = useState({ ...from, opacity: 1 });
  const [stepData, setStepData] = useState({
    xStep: 0,
    yStep: 0,
    opacityStep: 0,
    widthStep: 0,
  });
  const [stepsDone, setStepsDone] = useState(0);

  useEffect(
    () => {
      adjustedDuration = Math.ceil(durationMs / ADJUSTMENT);

      const xPath = to.x - from.x;
      const yPath = to.y - from.y;
      const widthPath = withWidth ? to.width - from.width : 0;

      const xStep = xPath / adjustedDuration;
      const yStep = yPath / adjustedDuration;
      const widthStep = widthPath / adjustedDuration;
      const opacityStep = 1 / adjustedDuration;

      if (animate) {
        setCurrentPos({ ...from, opacity: 1 });
        setStepsDone(0);
        setStepData({ xStep, yStep, opacityStep, widthStep });
        makeStep();
      }
    },
    [from.x, from.y, from.width, to.x, to.y, to.width, animate, durationMs],
  );

  useEffect(() => {
    makeStep();
  }, [stepsDone]);

  function makeStep() {
    const newPos = { ...currentPos };
    newPos.x += stepData.xStep;
    newPos.y += stepData.yStep;
    if (withOpacity) {
      newPos.opacity -= stepData.opacityStep;
    }
    if (withWidth) {
      newPos.width += stepData.widthStep;
    }

    setCurrentPos(newPos);

    if (stepsDone <= adjustedDuration) {
      setTimeout(() => setStepsDone((curr) => curr + 1), ADJUSTMENT);
    } else if (onAnimationEnd) {
      onAnimationEnd();
    }
  }

  const flierContent = (
    <div
      style={{
        position: 'absolute',
        top: currentPos.y,
        left: currentPos.x,
        width: withWidth ? currentPos.width : 'fit-content',
        height: 'fit-content',
        opacity: currentPos.opacity,
        transition: `all linear ${ADJUSTMENT / 2000}s`,
      }}
      className={wrapperClass}
    >
      {children}
    </div>
  );

  return portal
    ? createPortal(
      <div className="flier-container">{flierContent}</div>,
      document.body,
    )
    : flierContent;
}

const styleStateProp = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number,
});

Flier.propTypes = {
  children: PropTypes.node,

  from: styleStateProp.isRequired,
  to: styleStateProp.isRequired,

  animate: PropTypes.bool,

  durationMs: PropTypes.number,
  wrapperClass: PropTypes.string,
  onAnimationEnd: PropTypes.func,
  withOpacity: PropTypes.bool,
  withWidth: PropTypes.bool,

  portal: PropTypes.bool,
};

Flier.defaultProps = {
  durationMs: 1000,
  portal: true,
};

export default Flier;
