import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function useOutsideClicker(ref, setIsPopperVisible) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsPopperVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setIsPopperVisible]);
}

function OutsideClicker(props) {
  const wrapperRef = useRef(null);
  useOutsideClicker(wrapperRef, props.setIsPopperVisible);

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideClicker.propTypes = {
  children: PropTypes.element.isRequired,
  setIsPopperVisible: PropTypes.func.isRequired,
};

export default OutsideClicker;
