import { useCallback, useEffect } from 'react';
import * as actionCreators from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useBreadcrumbs } from '../../reusable/hooks/useBreadcrumbs';
import { useHistory, useParams } from 'react-router-dom';
import { setCurrentSpecialOffer } from '../../store/menus/menus';
import { OfferCard } from './OfferCard';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function OfferPage() {
  useTitleUpdate('Акции');
  const dispatch = useDispatch();
  const { offerId } = useParams();

  const currentSpecialOffer = useSelector(
    (state) => state.menus.currentSpecialOffer,
  );
  const isNotTargetSpecialOffer =
    !currentSpecialOffer || currentSpecialOffer.id.toString() !== offerId;

  const targetSpecialOfferFromList = useSelector((state) =>
    state.menus.specialOffers?.find((offer) => offer.id.toString() === offerId),
  );

  useBreadcrumbs(
    'section-wrapper-ms',
    `/акции/${currentSpecialOffer?.title}`,
    '/акции/',
    [currentSpecialOffer],
  );

  const history = useHistory();
  const goToSpecialOffers = useCallback(() => {
    history.push('/акции');
  }, [history]);

  useEffect(() => {
    if (isNotTargetSpecialOffer && !!targetSpecialOfferFromList) {
      dispatch(setCurrentSpecialOffer(targetSpecialOfferFromList));
    } else if (isNotTargetSpecialOffer) {
      dispatch(
        actionCreators.getCurrentSpecialOffer(offerId, goToSpecialOffers),
      );
    }
  }, [
    offerId,
    dispatch,
    goToSpecialOffers,
    targetSpecialOfferFromList,
    isNotTargetSpecialOffer,
  ]);

  if (isNotTargetSpecialOffer) {
    return null;
  }

  return (
    <section className="section-wrapper-ms">
      <OfferCard card={currentSpecialOffer} view="full" />
    </section>
  );
}

export default OfferPage;
