import PropTypes from 'prop-types';
import './GeoButton.scss';

const GeoButton = ({ setGeoUser, onError }) => {
  const getGeo = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        setGeoUser({ lat, lon });
      },
      (error) => onError('Нет доступа к вашей геолокации'),
    );
  };

  return (
    <button type="button" className="geo-button" onClick={getGeo}>
      <span className="icon-gps-fixed-indicator" />
    </button>
  );
};

GeoButton.propTypes = {
  setGeoUser: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default GeoButton;
