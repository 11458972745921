import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearBreadcrumbs, setBreadcrumbs } from '../../store/view/view';

export function useBreadcrumbs(container, name, path = name, deps = []) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        container,
        name,
        path,
      }),
    );

    return () => {
      dispatch(clearBreadcrumbs());
    };
  }, [container, name, path, dispatch]);
}
