import { configureStore, combineReducers } from '@reduxjs/toolkit';
import viewReducer from './view/view'
import menusReducer from './menus/menus'
import apiConnectionReducer from './apiConnection/apiConnection'
import companyInfoReducer from './companyInfo/companyInfo'
import searchReducer from './searchResults/searchResults'
import currentUserReducer from './currentUser/currentUser'
import userCartReducer from './userCart/userCart'
import restaurantsReducer from './restaurants/restaurants'
import ordersReducer from './orders/orders'

const rootReducer = combineReducers({
  view: viewReducer,
  menus: menusReducer,
  apiConnection: apiConnectionReducer,
  companyInfo: companyInfoReducer,
  currentUser: currentUserReducer,
  userCart: userCartReducer,
  searchResults: searchReducer,
  restaurants: restaurantsReducer,
  orders: ordersReducer,
});

export const store = configureStore({
  reducer: rootReducer
})