import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { validateQuestionnaireAnswer } from '../../util/validators';
import { Divider } from '../../reusable/Divider/Divider';
import { IconEmoji } from '../../reusable/IconEmoji/IconEmoji';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';

export function QuestionConstructor({
  question,
  optionalAnswer,
  number,
  setOptionalAnswer,
  needFirstDevider,
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const textAreaRef = useRef();
  function addValue(currentValue, key, value) {
    return { ...currentValue, [key]: value };
  }
  const withComment =
    question.with_option || question.question_type === 'open_ended';

  const removeErrorHandler = () => {
    textAreaRef.current?.classList?.remove('text-field-input_error');
  };

  const onChangeValue = (ev) => {
    removeErrorHandler();
    setOptionalAnswer(
      addValue(
        optionalAnswer,
        question.question,
        validateQuestionnaireAnswer(ev.target.value, 1, 300, withComment),
      ),
    );
  };

  let questionsList;
  switch (question.question_type) {
    case 'multiple':
      questionsList = question.answers.slice(0, question.answers.length - 1);
      break;
    case 'rating':
      questionsList = ['😞', '😊'];
      break;
    default:
      questionsList = null;
  }

  function getIcon(value) {
    switch (value) {
      case '😞':
        return <IconEmoji type="bad" />;
      case '😊':
        return <IconEmoji type="fine" />;
      default:
        return value;
    }
  }

  return (
    <li
      className="questionnaire-item"
      id={needFirstDevider && number === 1 ? '' : question.question}
    >
      <Divider
        className={`questionnaire-divider ${number === 1 && needFirstDevider ? 'questionnaire-divider_hidden' : ''}`}
      />
      <h2 className="questionnaire-title questionnaire-title_list">
        <span>{number}. </span>
        {question.question}
      </h2>

      {question.question_type === 'open_ended' && <br />}

      <FormControl component="fieldset" className="MuiFormControl-root_radio">
        <RadioGroup
          className={`questionnaire-radio-group ${question.question_type}`}
          aria-label="Выберите ресторан"
          name={question.question}
          onChange={onChangeValue}
        >
          {questionsList &&
            questionsList.map((item, index) => (
              <FormControlLabel
                key={index}
                className={`questionnaire-radio-desk questionnaire-radio-desk-multiple ${question.question_type}-question`}
                value={item}
                control={<Radio onChange={() => setIsDisabled(true)} />}
                label={getIcon(item)}
              />
            ))}{' '}
          {question.with_option && (
            <FormControlLabel
              className="questionnaire-radio-desk questionnaire-radio-desk-multiple option-question"
              value="Другое (укажите)"
              control={<Radio onChange={() => setIsDisabled(false)} />}
              label="Другое (укажите)"
            />
          )}
        </RadioGroup>
        {withComment && (
          <label className="questionnaire-text-field questionnaire-with-option-field">
            <textarea
              className="text-field-input"
              placeholder="Комментарий"
              aria-label="Напишите вариант ответа в произвольной форме"
              disabled={isDisabled && question.with_option}
              onChange={onChangeValue}
              id={'textarea' + question.question}
              ref={textAreaRef}
              onFocus={removeErrorHandler}
            />
          </label>
        )}
      </FormControl>
    </li>
  );
}

QuestionConstructor.propTypes = {
  question: PropTypes.object.isRequired,
  setOptionalAnswer: PropTypes.func.isRequired,
  optionalAnswer: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  needFirstDevider: PropTypes.bool.isRequired,
};
