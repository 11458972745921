import './Divider.scss';
import PropTypes from 'prop-types';

export function Divider({ className, id }) {
  return (
    <div className="medium-divider-wrapper" id={id}>
      <div className={`medium-divider ${className}`} />
    </div>
  );
}

Divider.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};
