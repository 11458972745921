import { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import './ModalWindow.scss';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import ModalContent from './ModalContent';
import { PATHS, MESSAGE_TYPES, MODAL_TYPES } from '../util/constants';

function ModalWindow() {
  const theme = useTheme();

  const modalContent = useSelector((state) => state.view.modalContent);

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  function closeModal() {
    if (!modalContent) {
      return;
    }

    if (modalContent?.redirectPath) {
      history.push(modalContent.redirectPath);
    }

    dispatch(actionCreators.clearModal());
    dispatch(actionCreators.clearError());

    if (
      modalContent?.isShowSuccessProfileEdit &&
      modalContent?.modalType === MODAL_TYPES.MESSAGE &&
      pathname === PATHS.profile
    ) {
      dispatch(
        actionCreators.setMessage({
          messageStatus: MESSAGE_TYPES.SUCCESS_PROFILE_EDIT,
          redirectPath: PATHS.index,
        }),
      );
    }
  }

  useEffect(() => {
    if (!modalContent?.timeOutToClose) {
      return;
    }
    setTimeout(closeModal, modalContent.timeOutToClose);

    return () => {
      clearTimeout(closeModal);
    };
  }, [modalContent]);

  const style = {
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    backdropFilter: 'blur(3px)',
  };

  return (
    <Modal
      open={!!modalContent}
      disableBackdropClick
      disableEscapeKeyDown={modalContent?.dontNeedCloseByEsc}
      style={{
        zIndex: theme.zIndex.appBar + 1,
        overflowY: 'auto',
        display: 'flex',
      }}
      onClose={closeModal}
      BackdropProps={{
        style,
      }}
    >
      <div
        className={`modal-window-body ${modalContent?.classNameOfModalContainer}`}
      >
        {!modalContent?.dontNeedCloseBtn && (
          <button className="close-modal-button" onClick={closeModal}>
            <span className="icon-close-modal" />
          </button>
        )}

        <ModalContent onClose={closeModal} />
      </div>
    </Modal>
  );
}

export default ModalWindow;
