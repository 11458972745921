import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userCart: null,
    friendCart: null,
    isNeedReloadCartDishes: false,
};

const userCartSlice = createSlice({
    name: 'userCart',
    initialState,
    reducers: {
        setUserCart: (state, { payload }) => {
            state.userCart = payload;
            state.isNeedReloadCartDishes = false;
        },
        setFriendCart: (state, { payload }) => {
            state.friendCart = payload;
        },
        setIsNeedReloadCartDishes: (state, { payload }) => {
            state.isNeedReloadCartDishes = payload;
        },
    },
});

export const {
    setUserCart,
    setFriendCart,
    setIsNeedReloadCartDishes
} = userCartSlice.actions;

export default userCartSlice.reducer;
