import './MobileApp.scss';
import appStore from '../../../assets/img/appStore.svg';
import googlePlay from '../../../assets/img/googlePlay.svg';
import mobileApp from '../../../assets/img/mobile-app.png';
import { useSelector } from 'react-redux';

function MobileApp() {
  const companyInfo = useSelector((state) => state.companyInfo.info);

  return (
    <div className="mobile-app__shadow">
      <section className="mobile-app">
        <div className="mobile-app__content">
          <div className="mobile-app__title">
            Мобильное <br /> приложение
          </div>
          <div className="mobile-app__links">
            <a
              className="mobile-app__link"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://itunes.apple.com/ru/app/id${companyInfo?.app_store_id}?mt=8`}
            >
              <img alt="App store" src={appStore} className="link-img" />
            </a>
            <a
              className="mobile-app__link"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://play.google.com/store/apps/details?id=${companyInfo?.google_play_id}`}
            >
              <img alt="Google play" src={googlePlay} className="link-img" />
            </a>
          </div>
        </div>
        <img
          alt="Мобильное приложение"
          className="mobile-app__image"
          src={mobileApp}
        />
      </section>
    </div>
  );
}

export default MobileApp;
