export function getTotal(infoField, userCart) {
  return userCart.reduce(
    (prev, dish) => prev + getTotalDishField(dish, infoField),
    0,
  );
}

export function getTotalDishField(dish, field) {
  const toppings = dish.toppings.reduce(
    (prev, topping) => prev + topping.quantity * Number(topping.dish[field]),
    0,
  );
  const parts = dish.parts.reduce(
    (prev, part) => prev + Number(part.dish[field]),
    0,
  );
  return (Number(dish.dish[field]) + toppings + parts) * dish.quantity;
}
