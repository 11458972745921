import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import './Header.scss';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import ToolbarLeft from './ToolbarLeft';
import logo from '../../assets/img/logoHead.svg';
import { Link, useLocation } from 'react-router-dom';
import ToolbarRight from './ToolbarRight';
import clsx from 'clsx';
import { MOBILE_ANIMATION_TARGET, MODAL_TYPES } from '../../util/constants';
import { ReactComponent as LocationIcon } from '../../assets/icons/header/locationIcon.svg';
import { getReadableStreet } from '../../util/misc';
import * as actionCreators from '../../store/actions';
import { useRefreshAddress } from '../../reusable/hooks/useRefreshAddress';

function Header({ openSidebar, closeSidebar, sidebarIsOpen }) {
  const dispatch = useDispatch();
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);
  const isSidebarAlwaysOpened = useSelector(
    (state) => state.view.isSidebarAlwaysOpened,
  );
  const currentUser = useSelector((state) => state.currentUser);
  const restaurants = useSelector((state) => state.companyInfo.contacts);
  const restaurantsPresent = !!restaurants.length;
  const companyInfoPresent = !!companyInfo;

  const currentLocation = useLocation();

  const [searching, setSearching] = useState(false);

  useRefreshAddress();

  useEffect(() => {
    if (!restaurantsPresent && companyInfoPresent) {
      dispatch(actionCreators.getCompanyContacts());
    }
  }, [restaurantsPresent, companyInfoPresent, dispatch]);

  const toggleSidebar = (event) => {
    event.stopPropagation();
    sidebarIsOpen ? closeSidebar() : openSidebar();
  };

  if (!companyInfoPresent) {
    return null;
  }

  return (
    <AppBar elevation={1} onClick={isSidebarAlwaysOpened ? null : closeSidebar}>
      <button onClick={toggleSidebar} className="toggle-sidebar-button">
        <span
          className={clsx(
            'icon-sidebar-toggle',
            ['close', 'open'][+sidebarIsOpen],
          )}
        />
      </button>
      <Link className="header__sidebar-title" to="/">
        {' '}
        Меню{' '}
      </Link>
      <Toolbar className="center-wrapper">
        <div className="header-content simple-flex">
          {screenIsLarge && (
            <ToolbarLeft
              phone={companyInfo.single_phone}
              minPrice={companyInfo.delivery_min_price}
              openingTime={companyInfo.delivery_timetable}
            />
          )}
          {(!searching || screenIsLarge) && (
            <Link
              to="/"
              className="header-logo"
              onClick={() => {
                if (currentLocation.pathname === '/') {
                  window.location.reload();
                }
              }}
            >
              <img src={logo} alt="logo" />
            </Link>
          )}
          <ToolbarRight setSearching={setSearching} searching={searching} />
          {!screenIsLarge && <div id={MOBILE_ANIMATION_TARGET} />}
        </div>
        <div className="header-address" title="Адрес">
          {currentUser.isAuthorized && (
            <button
              className="header-address__choice__btn empty-button"
              type="button"
              onClick={(ev) => {
                ev.preventDefault();
                dispatch(
                  actionCreators.setModalContent({
                    modalType: MODAL_TYPES.PRESENCE_INFO,
                    dontNeedCloseBtn: true,
                    classNameOfModalContainer: 'presence-info-modal',
                  }),
                );
              }}
            >
              <LocationIcon className="no-shrink" />
              <span className="header-address__desc">
                {currentUser.userInfo?.presence_state === 'delivery'
                  ? getReadableStreet(currentUser.userInfo?.address)
                  : restaurants?.find(
                      (item) =>
                        item.id === currentUser.userInfo?.takeaway_restaurant,
                    )?.name || 'Адрес доставки'}
              </span>
            </button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  openSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

export default Header;
