import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DishDescription from './DishDescription/DishDescription';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as actionCreators from '../../store/actions';
import defaultSrc from '../../assets/img/no-dish.png';
import { makeStyles } from '@material-ui/core/styles';
import './DishGrid.scss';
import { changeStyle, buttonClassName } from '../../util/dishButtonAnimation';
import DishIcons from './DishIcons/DishIcons';
import { MODAL_TYPES } from '../../util/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
  },
  cardMedia: {
    height: 150,
  },
}));

function DishGrid({ dishes, suggestionsSearch }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();

  const [currId, setCurrId] = useState(null);
  const [animateStyle, setAnimateStyle] = useState('card-button__onclick');

  const loggedIn = useSelector((state) => state.currentUser.isAuthorized);
  const currentMenuInfo = useSelector(
    (state) => state.menus.currentCategoryInfo,
  );

  function toDishPage(dish) {
    const currentMenu = suggestionsSearch
      ? dish.category.menu
      : currentMenuInfo;

    history.push(
      `/${currentMenu.id}-${currentMenu.slug}/${dish.id}-${dish.slug}`,
    );
  }

  const sortedDishes = dishes.toSorted((x, y) => {
    const xIsUnavailable = x.is_on_stop || !x.is_available_by_time;
    const yIsUnavailable = y.is_on_stop || !y.is_available_by_time;
    return xIsUnavailable - yIsUnavailable;
  });

  function onAddToCartClickCategoryPage(dish) {
    if (loggedIn) {
      if (dish.pos_type === 'constructor') {
        toDishPage(dish);
      } else {
        dispatch(actionCreators.addToUserCart(dish));
        changeStyle(setAnimateStyle);
      }
    } else {
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.LOG_IN,
          btnType: 'addToBasket',
        }),
      );
    }
  }

  return (
    <Grid className={classes.container} container spacing={4}>
      {sortedDishes.map((dish) => (
        <Grid
          className={clsx({ 'on-stop': dish.is_on_stop })}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={dish.id}
        >
          <Card
            square
            style={{
              height: '100%',
              position: 'relative',
              overflow: 'visible',
              zIndex: '4',
              cursor: 'pointer',
            }}
            onClick={() => {
              toDishPage(dish);
            }}
          >
            <DishIcons dishIcons={[dish.spice, dish.gluten_level]} />

            <div
              style={{
                backgroundImage: `url(${dish.images[0]}), url(${defaultSrc})`,
              }}
              className={`${classes.cardMedia} with-default-bgi`}
            />
            <CardContent className="card-content-container">
              <DishDescription dish={dish} />
              <div className="product-card__content-bottom">
                <div className="product-card__price">
                  <div className="product-card__price__info">
                    {dish.is_on_stop && 'Временно отсутствует'}
                  </div>
                  <div
                    className={clsx({
                      invisible: !dish.price && !dish.constructor_min_price,
                    })}
                  >
                    {dish.price
                      ? `${dish.price} `
                      : `от ${dish.constructor_min_price} `}
                    <span className="rub-sign"> ₽</span>
                  </div>
                </div>
                <button
                  className="product-card__button button-secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrId(dish.id);
                    onAddToCartClickCategoryPage(dish);
                  }}
                >
                  <div
                    className={buttonClassName(currId, dish.id, animateStyle)}
                    id={dish.id}
                  />
                  <span className="product-card__button-plus">+</span>
                  <span className="icon-product-card__button-">
                    <span className="icon-cart" />
                  </span>
                </button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

DishGrid.propTypes = {
  dishes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number,
      calories: PropTypes.number,
      grams: PropTypes.number,
      milliliters: PropTypes.number,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      in_restaurant_only: PropTypes.bool.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ).isRequired,
  suggestionsSearch: PropTypes.bool,
};

export default DishGrid;
