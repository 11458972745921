import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    info: null,
    contacts: [],
    requisites: [],
};

const companyInfoSlice = createSlice({
    name: 'companyInfo',
    initialState,
    reducers: {
        setCompanyInfo: (state, { payload }) => {
            state.info = payload;
        },
        setCompanyContacts: (state, { payload }) => {
            state.contacts = payload;
        },
        setCompanyRequisites: (state, { payload }) => {
            state.requisites = payload;
        },
    },
});

export const {
    setCompanyInfo,
    setCompanyContacts,
    setCompanyRequisites
} = companyInfoSlice.actions;

export default companyInfoSlice.reducer;
