import leftArrowIcon from '../../assets/img/left-arrow.png';
import rightArrowIcon from '../../assets/img/right-arrow.png';

function CarouselControlIcon(altText) {
  const icon = altText === 'Вперед' ? rightArrowIcon : leftArrowIcon;

  return <img width="50" height="50" src={icon} alt={altText} />;
}

export default CarouselControlIcon;
