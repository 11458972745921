import { memo } from 'react';
import PropTypes from 'prop-types';

import { OUTLINE_DISH_ICONS } from '../../util/constants';

import './DishPageIcons.scss';

export const DishPageIcons = memo(function DishPageIcons({ icons }) {
  const iconsList = icons
    .map((item) => OUTLINE_DISH_ICONS?.[item])
    .filter((item) => !!item);

  return (
    <div className="dish-page__icons-wrpr">
      {iconsList?.map(({ icon, description }, index) => (
        <img
          key={index}
          src={icon}
          alt={description}
          title={description}
          className="dish-page__icon"
        />
      ))}
    </div>
  );
});

DishPageIcons.propTypes = {
  icons: PropTypes.array,
};
