import { Link, withRouter } from 'react-router-dom';
import './Footer.scss';
import logo from '../../assets/img/logoFooter.svg';
import appStore from '../../assets/img/appStore.svg';
import googlePlay from '../../assets/img/googlePlay.svg';
import { useSelector } from 'react-redux';
import { getCurrentYear } from '../../util/misc';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

function Footer({ closeSidebar }) {
  const companyInfo = useSelector((state) => state.companyInfo.info);

  const isSidebarAlwaysOpened = useSelector(
    (state) => state.view.isSidebarAlwaysOpened,
  );

  const theme = useTheme();

  if (!companyInfo) {
    return null;
  }

  const {
    app_store_id,
    google_play_id,
    vk_public_id,
    instagram_username,
    telegram_contact_username,
  } = companyInfo;

  return (
    <footer
      onClick={isSidebarAlwaysOpened ? null : closeSidebar}
      className="simple-flex"
      style={{ zIndex: theme.zIndex.drawer - 2 }}
    >
      <div className="center-wrapper footer-wrapper">
        <div className="footer-content">
          <Link to="/" className="footer-logo">
            <img src={logo} alt="logo" />
          </Link>

          <div className="footer-social-container simple-flex">
            <a
              className="footer-icon-link"
              href={`https://vk.com/${vk_public_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-vk" />
            </a>

            {/*<a*/}
            {/*  className='footer-icon-link'*/}
            {/*  href={`https://www.instagram.com/${instagram_username}`}*/}
            {/*  target='_blank'*/}
            {/*  rel='noopener noreferrer'*/}
            {/*>*/}
            {/*  <span className='icon-instagram' />*/}
            {/*</a>*/}

            <a
              className="footer-icon-link"
              href={`https://t.me/${telegram_contact_username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-telegram" />
            </a>
          </div>

          <div className="footer-mobile-apps">
            <a
              className="footer-img-link"
              href={`https://itunes.apple.com/ru/app/id${app_store_id}?mt=8`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} alt="appStore" />
            </a>

            <a
              className="footer-img-link"
              href={`https://play.google.com/store/apps/details?id=${google_play_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlay} alt="appStore" />
            </a>
          </div>

          <div className="footer-provided-by-container">
            <span className="footer-text">Предоставлено: </span>
            <a
              className="footer-text-link green"
              href="https://online-cafe.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              online-cafe.me
            </a>
          </div>

          <div className="footer-copyright-container footer-text">
            Copyright &copy; Кафе &quot;На&nbsp;Парах&quot;&ensp;
            <span className="footer-text">2010-{getCurrentYear()}</span>
          </div>

          <div className="footer-agreement">
            <Link className="footer-text-link" to="/соглашение">
              Пользовательское соглашение
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
};

export default withRouter(Footer);
