import { authPaths, nonAuthPaths, PRESENCES_IN_RUSSIAN } from './constants';
import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import formatISO from 'date-fns/formatISO';
import getMinutes from 'date-fns/getMinutes';
import getHours from 'date-fns/getHours';

export function getScreenIsLarge(screenSize) {
  return screenSize > 767;
}

export function getSidebarAlwaysOpened(screenSize) {
  return screenSize >= 1600;
}

export function declOfNum(number, words) {
  const ofSecondType = number % 100 > 4 && number % 100 < 20;

  let idx;
  if (ofSecondType) {
    idx = 2;
  } else {
    const idxInDecade = number % 10;
    idx = [2, 0, 1, 1, 1, 2][Math.min(5, idxInDecade)];
  }

  return `${number} ${words[idx]}`;
}

export function capitalizeFirstLetter(str) {
  if (!str) {
    return str;
  }
  return `${str[0].toUpperCase()}${str.substr(1)}`;
}

export function getCurrentYear() {
  const now = new Date();
  return now.getFullYear();
}

export function getPathRegex(path) {
  const splPath = path.split('/');
  const modifiedPath = splPath.map((pathPart) => {
    if (pathPart.startsWith(':')) {
      const splPart = pathPart.split(/[)(]/);
      let altPart = '[^/]+';
      if (pathPart.endsWith('?')) {
        altPart = '?[^/]*';
      }
      return splPart.length > 1 ? splPart[1] : altPart;
    }
    return pathPart;
  });
  return RegExp(`^${modifiedPath.join('/')}$`);
}

export function pathIsAvailable(path, isAuthorized) {
  const toSearch = isAuthorized
    ? [...authPaths, ...nonAuthPaths]
    : nonAuthPaths;
  return toSearch.some((pathRegex) => pathRegex.test(path));
}

export function check(path) {
  return nonAuthPaths.some((pathRegex) => pathRegex.test(path));
}

export function checkPathname(path) {
  const toSearch = [...authPaths, ...nonAuthPaths];
  return toSearch.some((pathRegex) => pathRegex.test(path));
}

export function convertDate(dateString) {
  const currentDate = new Date(dateString);
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const currentDay = currentDate.getDate().toString().padStart(2, '0');

  return `${currentYear}-${currentMonth}-${currentDay}`;
}

export function getIsWeekStartsOnMonOrTue(date) {
  const startDay = getDay(startOfMonth(date));
  return startDay === 1 || startDay === 2;
}

const ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const ID_LENGTH = 16;

export function getClientSideId() {
  let rtn = '';

  for (let i = 0; i < ID_LENGTH; i++) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }

  return rtn;
}

export const getLastPageAddress = (_) => {
  const lastURLArray = decodeURI(document.referrer).split('/');
  if (!lastURLArray.length) {
    return '';
  }
  return lastURLArray[lastURLArray.length - 1];
};

export function dateTimeToISOString(date, time) {
  if (date && time) {
    return formatISO(new Date());
  }
  return -1;
}

export async function fileListToBase64(fileList) {
  function getImgSizes(img) {
    return new Promise((resolve) => {
      img.onload = () => resolve([img.width, img.height]);
    });
  }

  function getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.size < 2500000) {
          resolve(reader.result);
        } else {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const canvasCopy = document.createElement('canvas');
          const copyContext = canvasCopy.getContext('2d');
          const img = new Image();
          img.src = reader.result;

          getImgSizes(img).then(([imgWidth, imgHeight]) => {
            const ratio = 700 / imgWidth;

            canvasCopy.width = imgWidth;
            canvasCopy.height = imgHeight;
            copyContext.drawImage(img, 0, 0);
            canvas.width = imgWidth * ratio;
            canvas.height = imgHeight * ratio;
            ctx.drawImage(
              canvasCopy,
              0,
              0,
              canvasCopy.width,
              canvasCopy.height,
              0,
              0,
              canvas.width,
              canvas.height,
            );
            resolve(canvas.toDataURL());
          });
        }
      };
    });
  }

  const files = [];
  for (const file of fileList) {
    await getBase64(file).then((data) => files.push(data));
  }
  return Promise.all(files);
}

function getTotalAmountOfToppings(field, currentToppings, dish) {
  return currentToppings.reduce(
    (prev, topping) =>
      prev +
      dish.toppings?.find((current) => current.id === topping.id)?.[field] *
        topping.count,
    0,
  );
}

function getTotalAmountOfGroups(field, groupValues, dish) {
  return Object.values(groupValues).reduce(
    (prev, group) =>
      !!group.length
        ? prev +
          dish.groups
            .find((current) => current.id === +group.split(',')[1])
            ?.parts?.find((current) => current.id === +group.split(',')[2])?.[
            field
          ]
        : prev + 0,
    0,
  );
}

export function getTotalAmountOf(field, currentToppings, groupValues, dish) {
  return (
    getTotalAmountOfToppings(field, currentToppings, dish) +
    getTotalAmountOfGroups(field, groupValues, dish)
  );
}

export function getCurrentTime() {
  const currentDate = Date.now();

  return `${getHours(currentDate)}:${getMinutes(currentDate)}`;
}

export function getHumanTimeToUnlock(sec) {
  if (!sec || !Number.isFinite(sec)) {
    return 'некоторое время.';
  }
  const hours = Math.floor(sec / 60 / 60);
  const min = Math.floor(sec / 60 - 60 * hours);
  const remSec = Math.trunc(sec % 60);

  const hoursDesc = declOfNum(hours, ['час', 'часы', 'часов']);
  const minDesc = declOfNum(min, ['минуту', 'минуты', 'минут']);
  const secDesc = declOfNum(remSec, ['секунду', 'секунды', 'секунд']);

  return `${hours > 0 ? hoursDesc : ''} ${min > 1 ? minDesc : ''} ${secDesc}`;
}

export function isShowTips(dish) {
  return dish.in_restaurant_only || dish.is_on_stop;
}

export function scrollToFirstSelector(...args) {
  for (const selector of args) {
    const block = document.querySelector(selector);
    if (block) {
      block.scrollIntoView({ block: 'center', behavior: 'smooth' });
      break;
    }
  }
}

export function addressFromCookiePresent(address) {
  const currentData = address;
  const data = {};
  data.street = currentData?.street || '';
  data.house = currentData?.house || '';
  data.corp = currentData?.corp || '';
  data.flat = currentData?.flat || '';
  data.entrance = currentData?.entrance || '';
  data.floor = currentData?.floor || '';
  return Object.values(data).some((val) => val);
}

export function getReadableStreet(address) {
  if (!address) {
    return null;
  }

  const settlement = address.settlement ? address.settlement + ', ' : '';
  const city = address.city !== 'Санкт-Петербург' ? address.city + ', ' : '';
  const readableCorp = address.corp.length ? 'к' + address.corp : '';
  const readableFlat = address.flat.length ? ', кв ' + address.flat : '';
  return `${settlement ? settlement : city}${address.street}, д. ${address.house} ${readableCorp} ${readableFlat}`.replace(
    /\s,\s$/,
    '',
  );
}

export function getPositiveNumberFromLimit(value, firstLimit, secondLimit) {
  const limit = firstLimit >= secondLimit ? secondLimit : firstLimit;
  const number = +value;
  if (Number.isFinite(number) && number > 0 && number <= limit) {
    return number;
  }
  return 0;
}

export function getCloneObjWithoutKeys(obj, ...keys) {
  const result = {};
  Object.assign(result, obj);

  for (const key of keys) {
    delete result[key];
  }

  return result;
}

export function findObjInArrayByValue(value, array, key) {
  const targetObj = array.find((item) => item[key] === value);
  return targetObj || array[0];
}

export const findRequiredObject = (array, key) =>
  array.find((item) => item.question === key);

export const definePresenceType = ({
  setShowAddressSelector,
  setShowRestaurantSelector,
  setShowRestaurantToEatSelector,
  type,
}) => {
  const handlersOptions = [
    {
      handler: setShowRestaurantSelector,
      type: PRESENCES_IN_RUSSIAN.TAKEAWAY,
    },
    {
      handler: setShowRestaurantToEatSelector,
      type: PRESENCES_IN_RUSSIAN.STAY,
    },
    {
      handler: setShowAddressSelector,
      type: PRESENCES_IN_RUSSIAN.DELIVERY,
    },
  ];

  handlersOptions.forEach((i) => {
    if (i.type === type) {
      return i.handler(true);
    }
    i.handler(false);
  });
};

export function getTryLaterMessage(message) {
  return `${message} Пожалуйста, повторите попытку позже.`;
}
