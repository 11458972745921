import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageIsLoading: true,
    loadingIsGlobal: true,
    isLoadingFailed: false,
    isDataLoading: false,
};

export const apiConnectionSlice = createSlice({
    name: 'apiConnection',
    initialState,
    reducers: {
        setPageIsLoading(state, { payload: { pageIsLoading, loadingIsGlobal = false } }) {
            state.pageIsLoading = pageIsLoading;
            state.loadingIsGlobal = loadingIsGlobal;
        },
        clearError(state) {
            state.isLoadingFailed = false;
        },
        setIsLoadingFailed(state, { payload }) {
            state.isLoadingFailed = payload;
        },
        setIsDataLoading(state, { payload }) {
            state.isDataLoading = payload;
        },
    },
});

export const {
    setPageIsLoading,
    clearError,
    setIsLoadingFailed,
    setIsDataLoading,
} = apiConnectionSlice.actions;

export default apiConnectionSlice.reducer