import { createTheme } from '@material-ui/core/styles';

export default function getScreenSizeTheme(screenIsLarge) {
  const theme = createTheme({
    breakpoints: {
      values: {
        lg: 1200,
        md: 992,
        sm: 768,
        xs: 0,
        xl: 1920,
      },
    },
    overrides: {
      MuiRadio: {
        colorSecondary: {
          '&:hover': {
            backgroundColor: 'rgba(61, 91, 90, 0.06)',
          },
        },
      },
    },
    palette: {
      secondary: {
        main: '#a7c205',
        dark: '#709594',
      },
      profileTabs: {
        current: '#a7c205',
        notActive: '#83b0b0',
        text: '#3d5b5a',
      },
    },
  });

  theme.sidebarWidth = { open: screenIsLarge ? 245 : 260, closed: 70 };
  theme.zIndex.appBar = theme.zIndex.modal + 1;

  return theme;
}
