import { useMemo, useState, useEffect } from 'react';
import {
  FullscreenControl,
  Map,
  Placemark,
  Polygon,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';
import './YandexMap.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import singleRestaurantMarkerIcon from '../../assets/img/map-place-mark-icon.png';
import restaurantsMarkerIcon from '../../assets/img/pointer.png';
import {
  getPolygonOptions,
  getPolygonProperties,
  getPolygons,
  getSortedAreasByColor,
} from '../../util/map';

const DEFAULT_ZOOM = 10;
const DEFAULT_COORDINATES = [59.9311, 30.3609];
const API_KEY = 'bd0dcb5b-20ce-4f54-97c4-3d3b5e1a2b8f';

const getRestaurantsMarker = (restaurants) => {
  return restaurants.map((restaurant) => {
    return {
      geometry: [...restaurant.point.coordinates].reverse(),
      properties: {
        hintContent: restaurant.address,
        balloonContentHeader: restaurant.address,
        balloonContentBody: `+${restaurant.phones.join(', +')}`,
        balloonContentFooter:
          '<a href=рестораны/' + restaurant.id + '>Подробнее</a>',
      },
    };
  });
};

const getRestaurantMarkerOptions = (countRestaurant) => {
  return {
    iconLayout: 'default#image',
    iconImageHref:
      countRestaurant === 1
        ? singleRestaurantMarkerIcon
        : restaurantsMarkerIcon,
    iconImageSize: [30, 30],
  };
};

const getClientMarker = (coordinate) => {
  if (!coordinate.lat || !coordinate.lon) {
    return null;
  }
  return {
    geometry: [coordinate.lat, coordinate.lon],
    properties: {
      hintContent: 'Вы',
    },
  };
};

const YandexMap = ({ restaurants, clientCoordinate }) => {
  const companyInfo = useSelector((state) => state.companyInfo.info);

  const [mapState, setMapState] = useState({
    zoom: DEFAULT_ZOOM,
    center: companyInfo
      ? [
          companyInfo?.ymap_center.coordinates[1],
          companyInfo?.ymap_center.coordinates[0],
        ]
      : DEFAULT_COORDINATES,
  });
  const userMarker = getClientMarker(clientCoordinate);
  const restaurantsMarker = getRestaurantsMarker(restaurants);

  useEffect(() => {
    if (clientCoordinate.lat != null && clientCoordinate.lon != null) {
      setMapState((prevVal) => ({
        ...prevVal,
        center: [clientCoordinate.lat, clientCoordinate.lon],
      }));
    }
  }, [clientCoordinate.lat, clientCoordinate.lon]);

  const deliveryAreas = useMemo(
    () =>
      restaurants
        .filter((r) => r.delivery_areas.length > 0)
        .map((r) => r.delivery_areas),
    [restaurants],
  );

  const deliveryAreasOrdered = getSortedAreasByColor(deliveryAreas);

  const closeBalloon = (ref) => {
    if (ref) {
      ref.events.add('click', (e) => {
        ref.balloon.close();
      });
    }
  };

  return (
    <YMaps
      query={{
        lang: 'ru_RU',
        apikey: API_KEY,
      }}
    >
      <Map
        width="100%"
        height="100%"
        instanceRef={closeBalloon}
        state={mapState}
      >
        <ZoomControl options={{ float: 'right' }} />
        <FullscreenControl />
        {Object.values(deliveryAreasOrdered).map((priceArea) =>
          getPolygons(priceArea.coordinates).map((geometry, index) => (
            <Polygon
              key={index + priceArea.color}
              modules={['geoObject.addon.hint']}
              geometry={geometry}
              properties={getPolygonProperties(priceArea.price)}
              options={getPolygonOptions(priceArea.color)}
            />
          )),
        )}
        {restaurantsMarker.map((marker, index) => (
          <Placemark
            key={index}
            modules={['geoObject.addon.balloon']}
            geometry={marker.geometry}
            properties={marker.properties}
            options={getRestaurantMarkerOptions(restaurantsMarker.length)}
          />
        ))}
        {userMarker && (
          <Placemark
            modules={['geoObject.addon.balloon']}
            geometry={userMarker.geometry}
            properties={userMarker.properties}
          />
        )}
      </Map>
    </YMaps>
  );
};

YandexMap.propTypes = {
  restaurants: PropTypes.array.isRequired,
  clientCoordinate: PropTypes.object,
};

YandexMap.defaultProps = {
  clientCoordinate: {},
};

export default YandexMap;
