export function extractPhoneNumbers(rawPhone) {
  return rawPhone.replace(/[^+\d]/g, '');
}

export function getPhoneParts(rawPhone) {
  if (!rawPhone) {
    return null;
  }

  const codePartRaw = rawPhone.substr(0, 4);
  const codePart = `+${codePartRaw[0]} (${codePartRaw.substr(1)}) `;

  const numPartRaw = rawPhone.substr(4);
  const numPart = `${numPartRaw.substr(0, 3)}-${numPartRaw.substr(3, 2)}-${numPartRaw.substr(5, 2)}`;

  return [codePart, numPart];
}

export function getPhoneInputLength(inputValue) {
  const noUnderscores = inputValue.replace(/_/g, '');
  const numRegex = /\d/g;
  let lastNumIdx = 0;

  while (numRegex.exec(noUnderscores) != null) {
    lastNumIdx = numRegex.lastIndex;
  }

  return Math.max(lastNumIdx, 4);
}
