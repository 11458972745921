export function getPolygonOptions(color) {
  return {
    fillColor: color,
    strokeWidth: 1,
    opacity: 0.5,
    interactivityModel: 'default#transparent',
  };
}

export function getPolygonProperties(price = null) {
  if (price) {
    return { hintContent: `Возможна платная доставка: ${price} руб.` };
  }

  return { hintContent: 'В данной области возможна доставка.' };
}

export function getPolygons(areaPolygon) {
  return areaPolygon.map(function reverseArray(item) {
    if (Array.isArray(item) && Array.isArray(item[0])) {
      return item.map(reverseArray);
    }

    return [...item].reverse();
  });
}

export function getSortedAreasByColor(deliveryAreas) {
  const areasOrdered = {};

  for (const areas of deliveryAreas) {
    for (const area of areas) {
      if (areasOrdered.hasOwnProperty(area.color) && area.area.coordinates) {
        areasOrdered[area.color].coordinates.push(area.area.coordinates);
      } else if (area.area.coordinates) {
        areasOrdered[area.color] = {
          color: area.color,
          price: area.price,
          coordinates: [area.area.coordinates],
        };
      }
    }
  }

  return areasOrdered;
}

export function getMessageByAddressesCheck(data) {
  const isPositive = !!data.result;
  const isMustPay = !!data.price;

  if (isPositive && isMustPay)
    return `Доставка возможна. Цена доставки: ${data.price} ₽`;
  if (isPositive) return 'Возможна бесплатная доставка';
  return 'Доставка по вашему адресу невозможна';
}
