import MainMenu from '../pages/MainMenu/MainMenu';
import SearchPage from '../pages/SearchPage/SearchPage';
import UserAgreement from '../pages/UserAgreement/UserAgreement';
import PrivacyPolicyСouriers from '../pages/PrivacyPolicy/PrivacyPolicyСouriers';
import MenuPage from '../pages/MenuPage/MenuPage';
import Profile from '../pages/Profile/Profile';
import Contacts from '../pages/Contacts/Contacts';
import Orders from '../pages/Orders/Orders';
import { getPathRegex } from './misc';
import Cart from '../pages/Cart/Cart';
import Restaurant from '../pages/Restaurant/Restaurant';
import DeliveryTerms from '../pages/DeliveryTerms/DeliveryTerms';
import DeliveryCheck from '../pages/DeliveryCheck/DeliveryCheck';
import DishPage from '../pages/DishPage/DishPage';
import HowToGetPoints from '../pages/HowToGetPoints/HowToGetPoints';
import PlaceOrder from '../pages/PlaceOrder/PlaceOrder';
import OffersList from '../pages/SpecialOffers/OffersList';
import OfferPage from '../pages/SpecialOffers/OfferPage';
import FriendCart from '../pages/FriendCart/FriendCart';
import Questionnaire from '../pages/Questionnaire/Questionnaire';
import iconSpice from '../assets/icons/dish/spicy.svg';
import iconGluten from '../assets/icons/dish/gluten.svg';
import iconGlutenFree from '../assets/icons/dish/gluten_free.svg';

import iconSpiceOutline from '../assets/icons/dish/spicy_outline.svg';
import iconVegeterianOutline from '../assets/icons/dish/vegeterian_outline.svg';
import iconGlutenFreeOutline from '../assets/icons/dish/gluten_free_outline.svg';
import iconLactoseFreeOutline from '../assets/icons/dish/lactose_free_outline.svg';
import iconSugarFreeOutline from '../assets/icons/dish/sugar_free_outline.svg';

export const PATHS = {
  index: '/',
  cart: '/корзина',
  contacts: '/контакты',
  deliveryTerms: '/условия-доставки',
  howToGetPoints: '/как-получить-баллы',
  offersList: '/акции',
  orders: '/заказы',
  placeOrder: '/оформление-заказа',
  profile: '/профиль',
  userAgreement: '/соглашение',
  privacyPolicy: '/courier-policy',
};

export const authNotRequired = [
  {
    path: '/',
    exact: true,
    component: MainMenu,
  },
  {
    path: '/поиск/:query',
    exact: true,
    component: SearchPage,
  },
  {
    path: '/соглашение',
    exact: true,
    component: UserAgreement,
  },
  {
    path: PATHS.privacyPolicy,
    exact: true,
    component: PrivacyPolicyСouriers,
  },
  {
    path: '/:page(\\d+-[a-zA-Zа-яА-ЯёЁ-]+)',
    exact: true,
    component: MenuPage,
  },
  {
    path: '/рестораны/:id',
    exact: true,
    component: Restaurant,
  },
  {
    path: '/условия-доставки',
    exact: true,
    component: DeliveryTerms,
  },
  {
    path: '/проверка-доставки',
    exact: true,
    component: DeliveryCheck,
  },
  {
    path: '/контакты',
    exact: true,
    component: Contacts,
  },
  {
    path: '/:page(\\d+-[a-zA-Zа-яА-ЯёЁ-]+)/:dish',
    exact: true,
    component: DishPage,
  },
  {
    path: '/акции',
    exact: true,
    component: OffersList,
  },
  {
    path: '/акции/:offerId(\\d+)',
    exact: true,
    component: OfferPage,
  },
  {
    path: '/s/:link',
    exact: true,
    component: FriendCart,
  },
  {
    path: '/отзыв/:orderId?',
    exact: false,
    component: Questionnaire,
  },
];

export const authRequired = [
  {
    path: '/профиль',
    exact: true,
    component: Profile,
  },
  {
    path: '/корзина',
    exact: false,
    component: Cart,
  },
  {
    path: '/заказы',
    exact: true,
    component: Orders,
  },
  {
    path: '/как-получить-баллы',
    exact: true,
    component: HowToGetPoints,
  },
  {
    path: '/оформление-заказа',
    exact: true,
    component: PlaceOrder,
  },
];

export const authPaths = authRequired.map((page) => getPathRegex(page.path));
export const nonAuthPaths = authNotRequired.map((page) =>
  getPathRegex(page.path),
);

export const DELIVERY_AREAS = [
  'Выборгский район',
  'Калининский район',
  'Петроградский район',
  'Приморский район',
  'Василеостровский район',
  'Адмиралтейский район',
  'Центральный район',
  'Кировский район',
  'Красносельский район',
  'Московский и Фрунзенский районы (небольшая часть)',
  'Кудрово',
  'Мурино',
];

export const DEFAULT_ADDRESS = {
  city: 'Санкт-Петербург',
  street: '',
  house: '',
  corp: '',
  flat: '',
  entrance: '',
  floor: '',
};

export const DEFAULT_CLIENT_COORDINATE = {
  lat: null,
  lon: null,
};

export const DEFAULT_DELIVERY_STATUS = {
  message: '',
  isPositive: false,
  restaurant: undefined,
  price: null,
};

export const CART_ICON_ID = 'the-cart-icon';
export const MOBILE_ANIMATION_TARGET = 'mobile-animation-target';

export const POSSIBLE_ERRORS_BY_LOG_IN = [
  'Incorrect code',
  'Ban sms sending by ip',
  'Ban by client timeout',
  'Phone code sending error',
  'Invalid phone',
];

export const PRESENCES = {
  delivery: 'delivery',
  stay: 'stay',
  takeaway: 'takeaway',
};

export const ROLES = {
  client: 'client',
  manager: 'manager',
  table: 'table',
  operator: 'operator',
};

export const SPECIAL_ROLES = {
  callCenterPhone: '71111111111',
};

export const PHONE_MASK = [
  '+',
  '7',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const DEFAULT_POLYGON_COLOR = '#32CD32';

export const DISH_ICONS = {
  spicy: {
    description: 'Острое блюдо',
    icon: iconSpice,
  },
  'can be not spicy': {
    description: 'Блюдо острое, но можно сделать не острым.',
    icon: iconSpice,
  },
  gluten: {
    description: 'Блюдо содержит глютен.',
    icon: iconGluten,
  },
  gluten_free: {
    description: 'Блюдо не содержит глютен.',
    icon: iconGlutenFree,
  },
};

export const OUTLINE_DISH_ICONS = {
  gluten_free: {
    description: 'Блюдо не содержит глютен.',
    icon: iconGlutenFreeOutline,
  },
  spicy: {
    description: 'Острое блюдо.',
    icon: iconSpiceOutline,
  },
  'lactose free': {
    description: 'Блюдо не содержит лактозу.',
    icon: iconLactoseFreeOutline,
  },
  vegiterian: {
    description: 'Вегетарианское блюдо',
    icon: iconVegeterianOutline,
  },
  'sugar free': {
    description: 'Блюдо без сахара.',
    icon: iconSugarFreeOutline,
  },
};

export const ASAP_OPTION = { key: '-', value: 'Как можно быстрее' };

export const LOGIN_MESSAGES = {
  WRONG_CODE: 'Вы указали неверный код из СМС. Попробуйте еще раз.',
  REQUIRED_FIELD: 'Это поле обязательно',
  CODE_CONFIRM_ERROR: 'Ошибка! Что-то пошло не так при подтверждении кода!',
  DOUBLE_SEND_BAN:
    'Превышено число попыток для входа. Вы можете повторить попытку через',
  FILLING_ERROR: 'Ошибка! Пример правильного номера',
  PHONE_TEMPLATE: '9211231212',
  UNKNOWN_ERROR: 'Ошибка! Что-то пошло не так, повторите попытку позднее!',
  CANT_FIND: 'Не удается найти',
  LOGIN: 'Вход',
  TEL_AND_CODE_REQUEST:
    'Чтобы оформить заказ на нашем сайте, пожалуйста, введите ваш номер телефона и проверочный код из SMS',
  NEED_TO_LOGIN:
    'Для доступа на страницу, необходимо ввести ваш номер телефона и проверочный код из SMS',
  BACK: 'Назад',
  ENTER: 'Войти',
  REPEAT_CODE: 'Отправить код ещё раз',
  AUTORIZATION_SUCCESS: 'Авторизация прошла успешно!',
  SEND_SMS: 'Отправить смс-пароль',
};

export const layoutBreakPoint = 768;

export const PRESENCES_IN_RUSSIAN = {
  DELIVERY: 'ДОСТАВКА',
  TAKEAWAY: 'САМОВЫВОЗ',
  STAY: 'ПОЕДИМ В РЕСТОРАНЕ',
};

export const MODAL_TYPES = {
  LOG_OUT: 'LOG_OUT',
  LOG_IN: 'LOG_IN',
  MESSAGE: 'MESSAGE',
  PRESENCE_INFO: 'PRESENCE_INFO',
  DELETE: 'DELETE',
};

export const MESSAGE_TYPES = {
  TRY_LATER_ERROR: 'TRY_LATER_ERROR',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  OK: 'OK',
  SUCCESS_PROFILE_EDIT: 'SUCCESS_PROFILE_EDIT',
};

export const ORDER_COOKIES = {
  SAVED_CLIENT_NAME: 'savedClientName',
  SAVED_CLIENT_PHONE: 'savedClientPhone',
  SAVED_PRESENCE: 'savedPresence',
  SAVED_RESTAURANT: 'savedRestaurant',
  SAVED_PAYMENT_TYPE: 'savedPaymentType',
  SAVED_PEOPLE_AMOUNT: 'savedPeopleAmount',
  SAVED_COMMENT: 'savedComment',
  SAVED_DELIVERY_TIME: 'savedDeliveryTime',
  SAVED_SHOULD_NOT_CALL: 'savedShouldNotCall',
  SAVED_PROMOCODE: 'savedPromocode',
  SAVED_CASH_CHANGE_NOT_NEEDED: 'savedCashChangeNotNeeded',
  SAVED_CASH_CHANGE: 'savedCashChange',
  SAVED_REMEMBER: 'savedRemember',
  SAVED_LOYALTY: 'savedLoyalty',
};
