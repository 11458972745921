import Modal from '@material-ui/core/Modal';
import '../../components/modals/MessageModal/MessageModal.scss';
import '../../reusable/ModalWindow.scss';

function FallbackPage() {
  function closeModal() {
    window.location.href = window.location.origin;
  }

  const style = {
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
  };

  return (
    <Modal
      open
      disableBackdropClick
      style={{ zIndex: 1000, overflowY: 'auto', display: 'flex' }}
      onClose={closeModal}
      BackdropProps={{
        style,
      }}
    >
      <div className="modal-window-body">
        <button className="close-modal-button" onClick={closeModal}>
          <span className="icon-close-modal" />
        </button>

        <div className="message-modal-container">
          <h2 className="log-in-modal-title message-modal-title">Ошибка!</h2>
          <p className="confirm-log-out-text">
            Извините, что-то пошло не так! Пожалуйста, повторите попытку позже.
          </p>

          <button
            type="button"
            onClick={closeModal}
            className="button-standard message-modal-btn"
          >
            ок
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default FallbackPage;
