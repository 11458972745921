import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CourierTipsPopup from './components/CourierTipsPopup/CourierTipsPopup';
import { Layout } from './components/Layout/Layout';
import Routes from './components/Routes/Routes';
import { usePrevious } from './reusable/hooks/usePrevious';
import * as actionCreators from './store/actions';
import { getScreenIsLarge, getSidebarAlwaysOpened } from './util/misc';
import getScreenSizeTheme from './util/styles';
import './App.scss';

export default function App() {
  const [isTipsPopupOpen, setIsTipsPopupOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [courierTipsLink, setCourierTipsLink] = useState('');

  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);
  const isSidebarAlwaysOpened = useSelector(
    (state) => state.view.isSidebarAlwaysOpened,
  );
  const userOrders = useSelector((state) => state.orders.orders);
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const previousUserInfo = usePrevious(userInfo);

  const dispatch = useDispatch();
  const history = useHistory();

  const activateTipsPopup = useCallback(({ orders, numbers }) => {
    const filteredNumbers = numbers.filter(({ number, courier_tips_url, is_finished }) =>
      orders.includes(number) && courier_tips_url && is_finished
    );

    if (filteredNumbers.length) {
      const { number, courier_tips_url } = filteredNumbers[0];
      setOrderNumber(number);
      setCourierTipsLink(courier_tips_url);
      setIsTipsPopupOpen(true);
    }
  }, []);

  const handleClosePopup = useCallback(
    (e) => {
      if (
        e?.target?.id !== 'close-popup' &&
        e?.keyCode !== 27 &&
        e?.target?.id !== 'tip'
      ) {
        return;
      }
      const orders = JSON.parse(localStorage.getItem('orders'));
      if (!orders || orders?.length < 2) {
        localStorage.removeItem('orders');
      } else {
        localStorage.setItem('orders', JSON.stringify(orders.slice(1)));
      }
      setIsTipsPopupOpen(false);
      if (orders.length > 1) {
        activateTipsPopup({
          orders: orders.slice(1),
          numbers: userOrders,
        });
      }
    },
    [activateTipsPopup, userOrders],
  );

  useEffect(() => {
    if (!isTipsPopupOpen) {
      return;
    }

    document.addEventListener('keydown', handleClosePopup);
    return () => {
      document.removeEventListener('keydown', handleClosePopup);
    };
  }, [isTipsPopupOpen, handleClosePopup]);

  useEffect(() => {
    function handleScreenIsLarge() {
      const isCurrentScreenLarge = getScreenIsLarge(window.innerWidth);
      if (isCurrentScreenLarge === screenIsLarge) {
        return;
      }

      dispatch(actionCreators.setScreenIsLarge(isCurrentScreenLarge));
    }

    window.addEventListener('resize', handleScreenIsLarge);
    return () => {
      window.removeEventListener('resize', handleScreenIsLarge);
    };
  }, [dispatch, screenIsLarge]);

  useEffect(() => {
    function handleIsSidebarAlwaysOpened() {
      const isSidebarAlwaysOpenedAfterResize = getSidebarAlwaysOpened(
        window.innerWidth,
      );
      if (isSidebarAlwaysOpenedAfterResize === isSidebarAlwaysOpened) {
        return;
      }

      dispatch(
        actionCreators.setSidebarAlwaysOpened(isSidebarAlwaysOpenedAfterResize),
      );
    }

    window.addEventListener('resize', handleIsSidebarAlwaysOpened);
    return () => {
      window.removeEventListener('resize', handleIsSidebarAlwaysOpened);
    };
  }, [dispatch, isSidebarAlwaysOpened]);

  useEffect(() => {
    function getInitialData() {
      const requests = [
        dispatch(actionCreators.getMenuCategories()),
        dispatch(actionCreators.getCompanyInfo()),
      ];
      if (isAuthorized) {
        requests.push(
          dispatch(actionCreators.getCurrentUserInfo(false, history)),
        );
        const orders = JSON.parse(localStorage.getItem('orders'));
        if (orders) {
          requests.push(
            dispatch(actionCreators.getInitialOrders()).then((userOrders) =>
              activateTipsPopup({ orders, numbers: userOrders }),
            ),
          );
        }
      }
      return Promise.all(requests);
    }

    dispatch(actionCreators.setPageIsLoading({ pageIsLoading: true, loadingIsGlobal: true }));
    getInitialData().then(() =>
      dispatch(actionCreators.setPageIsLoading({ pageIsLoading: false, loadingIsGlobal: false })),
    );
  }, []);

  useEffect(() => {
    if (previousUserInfo === null && !!userInfo) {
      dispatch(actionCreators.getUserCart());
    }
  }, [dispatch, userInfo, previousUserInfo]);

  useEffect(() => {
    Sentry.setUser({
      firstName: userInfo?.first_name,
      lastName: userInfo?.last_name,
      email: userInfo?.email,
      id: userInfo?.id,
      phone: userInfo?.phone,
    });
  }, [userInfo]);

  return (
    <ScopedCssBaseline
      classes={{ root: screenIsLarge ? 'large-screen' : 'small-screen' }}
    >
      <ThemeProvider theme={getScreenSizeTheme(screenIsLarge)}>
        <Layout>
          {isTipsPopupOpen && (
            <CourierTipsPopup
              orderNumber={orderNumber}
              popupHandler={handleClosePopup}
              link={courierTipsLink}
              tipsPopupOpen={isTipsPopupOpen}
            />
          )}
          <Routes />
        </Layout>
      </ThemeProvider>
    </ScopedCssBaseline>
  );
}
