import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchResult: [],
    isEmpty: false,
    query: '',
};

const searchResultsSlice = createSlice({
    name: 'searchResults',
    initialState,
    reducers: {
        setSearchResults: (state, { payload: { searchResult, isEmpty, query } }) => {
            state.searchResult = searchResult;
            state.isEmpty = isEmpty;
            state.query = query;
        },
    },
});

export const { setSearchResults } = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
