import { ReactComponent as CashbackIcon } from '../../../assets/icons/dish/cashback.svg';
import PropTypes from 'prop-types';
import '../../ItemTag/ItemTag.scss';

export function LoyaltyPointsTag({ points }) {
  return (
    <h3 className="tag tag--loyalty" title="Бонусные баллы за блюдо">
      <CashbackIcon className="tag__icon" />
      <span className="tag__text">{points}</span>
    </h3>
  );
}

LoyaltyPointsTag.propTypes = {
  points: PropTypes.number,
};
