import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import subYears from 'date-fns/subYears';
import PropTypes from 'prop-types';

export const ToolbarPicker = (props) => {
  const {
    date,
    isLandscape,
    openView,
    setOpenView,
    selectedDate,
    setSelectedDate,
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    setViewPicker,
    viewPicker,
    onChange,
  } = props;

  useEffect(() => {
    setOpenView(viewPicker);
  }, [viewPicker]);

  function handleChangeViewClick(viewPicker) {
    switch (viewPicker) {
      case 'month':
      case 'year':
        setViewPicker('date');
        setSelectedDate(date);
        break;
      default:
        setViewPicker('year');
        setMaxDate(subYears(selectedDate, -11));
        setMinDate(subYears(selectedDate, 12));
        setSelectedDate(subYears(selectedDate, -11));
    }
  }

  function handlePrevBtn() {
    switch (viewPicker) {
      case 'date':
        const shiftedDay = startOfMonth(subDays(selectedDate, 5));
        setMinDate(shiftedDay);
        setMaxDate(lastDayOfMonth(shiftedDay));
        setSelectedDate(shiftedDay);
        break;
      case 'year':
        setMinDate(subYears(selectedDate, 46));
        setMaxDate(subYears(selectedDate, 23));
        setSelectedDate(subYears(selectedDate, 23));
        break;
      case 'month':
        const shiftedYear = subYears(selectedDate, 1);
        setMinDate(shiftedYear);
        setMaxDate(selectedDate);
        setSelectedDate(shiftedYear);
        onChange(shiftedYear, false);
        break;
      default:
        setMaxDate(subYears(selectedDate, -11));
        setMinDate(subYears(selectedDate, 12));
        setSelectedDate(subYears(selectedDate, -11));
    }
  }

  function handleNextBtn() {
    switch (viewPicker) {
      case 'date':
        const shiftedDay = startOfMonth(subDays(selectedDate, -35));
        setMinDate(shiftedDay);
        setMaxDate(lastDayOfMonth(shiftedDay));
        setSelectedDate(shiftedDay);
        break;
      case 'year':
        const shiftedYear = subYears(selectedDate, -23);
        setMinDate(selectedDate);
        setMaxDate(shiftedYear);
        setSelectedDate(shiftedYear);
        break;
      case 'month':
        const shiftedMonth = subYears(selectedDate, -1);
        setMinDate(selectedDate);
        setMaxDate(shiftedMonth);
        setSelectedDate(shiftedMonth);
        onChange(shiftedMonth, false);
        break;
      default:
        const shiftedPeriod = startOfMonth(selectedDate);
        setMinDate(shiftedPeriod);
        setMaxDate(lastDayOfMonth(selectedDate));
        setSelectedDate(shiftedPeriod);
    }
  }

  function getHeader() {
    const locale = { locale: ruLocale };

    if (viewPicker === 'date') {
      return `${format(selectedDate, 'LLL yyyy', locale)} Г.`;
    }
    if (viewPicker === 'month') {
      return `${format(selectedDate, 'yyyy', locale)}`;
    }
    if (viewPicker === 'year') {
      return `${format(minDate, 'yyyy', locale)} - ${format(maxDate, 'yyyy', locale)}`;
    }
  }

  return (
    <PickerToolbar
      className={`toolbar-container ${openView}`}
      isLandscape={isLandscape}
    >
      <Button
        className="toolbar-main-btn"
        aria-label="сменить поле выбора даты"
        onClick={() => {
          handleChangeViewClick(viewPicker);
        }}
      >
        {getHeader()}
      </Button>

      {openView !== 'date' && (
        <>
          <Button
            className="toolbar-btn toolbar-prev-btn"
            aria-label="назад"
            disabled={
              new Date(selectedDate).getTime() <
              new Date('1940-01-01').getTime() || undefined
            }
            onClick={() => {
              handlePrevBtn();
            }}
          />

          <Button
            className="toolbar-btn toolbar-next-btn"
            aria-label="вперед"
            disabled={new Date() - selectedDate < 0 || undefined}
            onClick={() => {
              handleNextBtn();
            }}
          />
          {viewPicker === 'month' && (
            <div className="date-month-title">{getHeader()}</div>
          )}
        </>
      )}
    </PickerToolbar>
  );
};

ToolbarPicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isLandscape: PropTypes.bool.isRequired,
  openView: PropTypes.string.isRequired,
  setOpenView: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  setMinDate: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  setMaxDate: PropTypes.func.isRequired,
  setViewPicker: PropTypes.func.isRequired,
  viewPicker: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
