import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './CartBody.scss';
import ProductComposition from '../../reusable/DishGrid/DishDescription/ProductComposition/ProductComposition';
import * as actionCreators from '../../store/actions';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import { getTotal, getTotalDishField } from '../../util/cart';
import defaultSrc from '../../assets/img/no-dish.png';
import { isShowTips } from '../../util/misc';
import Tips from '../DishGrid/Tips/Tips';
import { MODAL_TYPES, PATHS, PRESENCES } from '../../util/constants';
import DishIcons from '../DishGrid/DishIcons/DishIcons';
import clsx from 'clsx';
import { selectNeedUserData } from '../../store/selectors/selectNeedUserData';

function CartBody({ userCart, link }) {
  const dispatch = useDispatch();

  const history = useHistory();
  const editingEnabled = history.location.pathname.includes('корзина');

  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const loggedIn = useSelector((state) => state.currentUser.isAuthorized);
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const menuCategories = useSelector((state) => state.menus.menuCategories);
  const needUserData = useSelector(selectNeedUserData);

  const isDelivery = userInfo?.presence_state === PRESENCES.delivery;
  const deliveryMinPrice = companyInfo?.delivery_min_price ?? 0;

  const dishesInCart = editingEnabled ? userCart : userCart.basket_positions;
  const totalPrice = getTotalPrice();
  const deliveryPrice = getDeliveryPrice();
  const visibleTotalPrice = getVisibleTotalPrice();

  function toDishPage(dish) {
    const menuCategoryId = dish.category.menu?.id || dish.category.menu; // 'cause dish structure in dev & prod is different
    const categorySlug = menuCategories?.find(
      ({ id }) => id === menuCategoryId,
    )?.slug;
    history.push(`/${menuCategoryId}-${categorySlug}/${dish.id}-${dish.slug}`);
  }

  function deleteDishFromCart(dish) {
    dispatch(
      actionCreators.setModalContent({
        modalType: MODAL_TYPES.DELETE,
        dishId: dish.id,
      }),
    );
  }

  function changeAmountMinus(dish, count) {
    dish.quantity <= -count
      ? dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.DELETE,
          dishId: dish.id,
        }),
      )
      : dispatch(actionCreators.changeDishAmount(dish, dishesInCart, count));
  }

  function getFriendCartOrAuthorize() {
    if (!loggedIn) {
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.LOG_IN,
          btnType: 'addToBasket',
        }),
      );
    } else {
      dispatch(actionCreators.addFriendCartToCurrent(link, history));
    }
  }

  function checkStopDishes(dishes) {
    return dishes.some(
      ({ dish }) => dish?.is_on_stop || !dish?.is_available_by_time,
    );
  }

  function getTotalPrice() {
    return getTotal('price', dishesInCart);
  }

  function getVisibleTotalPrice() {
    if (!checkIsDeliveryPriceVisible()) {
      return totalPrice;
    }
    if (!isDelivery) {
      return totalPrice;
    }
    return totalPrice + deliveryPrice;
  }

  function checkIsDeliveryPriceVisible() {
    if (
      (isDelivery && totalPrice < deliveryMinPrice) ||
      !deliveryPrice ||
      !isDelivery
    ) {
      return false;
    }
    return true;
  }

  function getDeliveryPrice() {
    if (!isDelivery) {
      return 0;
    }
    return userInfo.delivery_price ?? 0;
  }

  function handlePlaceOrder() {
    if (needUserData) {
      history.push(PATHS.profile);
      dispatch(
        actionCreators.setMessage({
          additionalMessage: 'Заполните, пожалуйста, пустые поля профиля',
        }),
      );
      return;
    }
    if (editingEnabled) {
      history.push(PATHS.placeOrder);
      return;
    }
    getFriendCartOrAuthorize();
  }

  return (
    <>
      <div className="cart-content__wrapper">
        <div className="cart__wrapper">
          <div className="cart__items-list">
            {dishesInCart.map((dish) => {
              const isSolid = dish.dish.grams != null;
              const isFluid = dish.dish.milliliters != null;

              return (
                <div className="cart-item" key={dish.id}>
                  <DishIcons
                    dishIcons={[dish.dish.spice, dish.dish.gluten_level]}
                    children={
                      isShowTips(dish.dish) && (
                        <Tips
                          onStop={dish.dish.is_on_stop}
                          inRest={dish.dish.in_restaurant_only}
                        />
                      )
                    }
                  />
                  <div
                    className={clsx(
                      'cart-item__img',
                      dish.dish?.is_on_stop && 'cart-item__img--stop',
                      !dish.dish?.is_available_by_time &&
                      'cart-item__img--stop',
                    )}
                    style={{
                      backgroundImage: `url(${dish.dish.images[0]}), url(${defaultSrc})`,
                    }}
                    onClick={() => {
                      toDishPage(dish.dish);
                    }}
                  >
                    {dish.dish?.is_on_stop && (
                      <span className="cart-item__img-text">
                        Временно отсутствует
                      </span>
                    )}
                    {!dish.dish?.is_on_stop &&
                      !dish.dish?.is_available_by_time && (
                        <span className="cart-item__img-text">
                          Недоступно по расписанию
                        </span>
                      )}
                  </div>
                  <div
                    className="cart-item__title"
                    onClick={() => {
                      toDishPage(dish.dish);
                    }}
                  >
                    {dish.dish.name}
                    {!!dish.parts.length && (
                      <ul className="cart__item-checklist">
                        {dish.parts.map((part) => (
                          <li className="checklist__item" key={part.dish.id}>
                            {part.dish.name}
                          </li>
                        ))}
                      </ul>
                    )}
                    {!!dish.toppings.length && (
                      <ul className="cart__toppings">
                        {dish.toppings.map((topping) => (
                          <li className="toppings__item" key={topping.dish.id}>
                            <span className="toppings__item-name">
                              {topping.dish.name}
                            </span>
                            <div className="toppings__item-value">
                              <span className="toppings__item-amount">
                                {topping.quantity + ' '} шт.
                              </span>
                              <span className="toppings__item-price">
                                {topping.dish.price * topping.quantity + ' '}
                                <span className="rub-sign">₽</span>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {editingEnabled && (
                    <button
                      className="cart__item-close-button"
                      onClick={() => {
                        deleteDishFromCart(dish);
                      }}
                    >
                      <span className="cart__item-close">
                        <span className="icon-close" />
                      </span>
                    </button>
                  )}
                  <div className="cart__item-composition-weight">
                    {(isFluid || isSolid) && (
                      <div className="cart__item-weight">
                        {isSolid
                          ? getTotalDishField(dish, 'grams') + ' г'
                          : getTotalDishField(dish, 'milliliters') + ' мл'}
                      </div>
                    )}
                    <div className="cart__item-composition">
                      <ProductComposition
                        calories={getTotalDishField(dish, 'calories')}
                        fats={getTotalDishField(dish, 'fats')}
                        carbs={getTotalDishField(dish, 'carbs')}
                        proteins={getTotalDishField(dish, 'proteins')}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      editingEnabled
                        ? 'cart__item-amount-price'
                        : 'friendCart__item-amount-price'
                    }
                  >
                    {editingEnabled && (
                      <div className="cart__item-switcher">
                        <Fab
                          color="primary"
                          aria-label="delete"
                          style={{ boxShadow: 'none' }}
                          onClick={() => {
                            changeAmountMinus(dish, -1);
                          }}
                        >
                          <span className="Fab-content__minus" />
                        </Fab>
                        <span className="amount-switcher__value">
                          {dish.quantity}
                        </span>
                        <Fab
                          color="primary"
                          aria-label="add"
                          style={{ boxShadow: 'none' }}
                          onClick={() => {
                            dispatch(
                              actionCreators.changeDishAmount(
                                dish,
                                dishesInCart,
                                1,
                              ),
                            );
                          }}
                        >
                          <span className="Fab-content__plus" />
                        </Fab>
                      </div>
                    )}
                    <div className="cart__item-price">
                      {getTotalDishField(dish, 'price')}
                      <span className="rub-sign"> ₽</span>
                      {!editingEnabled && (
                        <span className="friendCart__item-quantity">
                          {dish.quantity} шт.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="cart__item-divider" />
                </div>
              );
            })}
          </div>
          <div className="cart__calories">
            <span className="cart__calories-title">Итого ккал:</span>
            <span className="cart__calories-value">
              {getTotal('calories', dishesInCart)}
            </span>
          </div>
          <div className="dishes-price">
            <span className="price-button-wrapper__title">Сумма заказа:</span>
            <span className="price-button-wrapper__value">
              {getTotal('price', dishesInCart)} &#8381;
            </span>
          </div>
          {checkIsDeliveryPriceVisible() && (
            <div className="delivery-price">
              <span className="price-button-wrapper__title">Доставка:</span>
              <span className="price-button-wrapper__value">
                {deliveryPrice} &#8381;
              </span>
            </div>
          )}
          <div className="price-button-wrapper">
            <div className="price-button-wrapper__price">
              <span className="price-button-wrapper__title">
                Сумма к оплате:
              </span>
              <span className="price-button-wrapper__value">
                {visibleTotalPrice} &#8381;
              </span>
              &nbsp; &nbsp; &nbsp;
            </div>
            <button
              className={clsx(
                'button-standard price-button-wrapper__button',
                checkStopDishes(dishesInCart) &&
                'price-button-wrapper__button--stop',
              )}
              onClick={handlePlaceOrder}
            >
              {editingEnabled ? 'Оформить заказ' : 'добавить в корзину'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

CartBody.propTypes = {
  link: PropTypes.string,
  userCart: PropTypes.array.isRequired,
};

export default CartBody;
