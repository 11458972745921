import './DishDescription.scss';
import PropTypes from 'prop-types';
import ProductComposition from './ProductComposition/ProductComposition';
import { getTotalAmountOf } from '../../../util/misc';
import { ItemTag } from '../../ItemTag/ItemTag';
import { LoyaltyPointsTag } from './LoyaltyPointsTag';

function DishDescription({
  dish,
  description,
  groupValues,
  currentToppings,
  dishCalculate,
}) {
  const isSolid = dish.grams != null;
  const isFluid = dish.milliliters != null;

  function getCompositionField(fieldName) {
    return (
      +dish[fieldName] +
      (dishCalculate
        ? getTotalAmountOf(fieldName, currentToppings, groupValues, dish)
        : 0)
    );
  }

  const weightCalculate = dishCalculate
    ? {
        grams: getTotalAmountOf('grams', currentToppings, groupValues, dish),
        milliliters: getTotalAmountOf(
          'milliliters',
          currentToppings,
          groupValues,
          dish,
        ),
      }
    : 0;

  const chosenToppings =
    dish.grams +
    (weightCalculate.grams || 0) +
    (weightCalculate.milliliters ? weightCalculate.milliliters : 0);

  function totalWeight() {
    if (isFluid) {
      return (
        `${dish.milliliters + (weightCalculate.milliliters || 0)} мл ` +
        (weightCalculate.grams ? '/ ' + weightCalculate.grams + ' г' : '')
      );
    }
    if (isSolid || !!chosenToppings) {
      return (
        `${dish.grams + (weightCalculate.grams || 0)} г ` +
        (weightCalculate.milliliters
          ? '/ ' + weightCalculate.milliliters + ' мл'
          : '')
      );
    }
    return null;
  }

  const dishWeight = totalWeight();

  return (
    <div className="product-card__content-top">
      <div className="product-card__title">{dish.name}</div>
      <div className="product-card__tag-container">
        {dish?.loyalty_points && (
          <LoyaltyPointsTag points={dish.loyalty_points} />
        )}
        {!!dish?.tags?.length &&
          dish.tags.map((item, index) => (
            <ItemTag title={item.name} color={item.color} key={index} />
          ))}
      </div>
      <div className="product-card__info">
        <ProductComposition
          calories={+getCompositionField('calories')}
          fats={+getCompositionField('fats')}
          carbs={+getCompositionField('carbs')}
          proteins={+getCompositionField('proteins')}
        />
        {!!dishWeight && (
          <div className="product-card__weight">{dishWeight}</div>
        )}
      </div>
      {description && dish.description && (
        <div className="product-card__desc">{dish.description}</div>
      )}
    </div>
  );
}

DishDescription.propTypes = {
  dish: PropTypes.object.isRequired,
  description: PropTypes.bool,
  groupValues: PropTypes.object,
  currentToppings: PropTypes.array,
  dishCalculate: PropTypes.bool,
};

export default DishDescription;
