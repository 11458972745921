import './PrivacyPolicy.scss';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function PrivacyPolicyСouriers() {
  useTitleUpdate('Курьеры, политика конфиденциальности');

  return (
    <section className="privacyPolicy-section">
      <div className="privacyPolicy-title__wrapper">
        <h1 className="heading privacyPolicy-title">
          Политика <br /> конфиденциальности
          <br />
          <span className="heading privacyPolicy-version">
            (версия 10/11/2023)
          </span>
        </h1>
        <div className="privacyPolicy__info">
          Дата публикации: «10» ноября 2023 г.
          <br />
          Текущая версия доступна в приложении «На Парах Курьеры».
        </div>
      </div>

      <div className="privacyPolicy-content__wrapper">
        <div className="privacyPolicy-content">
          <h3 className="privacyPolicy-heading">
            1. Общие положения, термины и понятия
          </h3>

          <div className="privacy-policy__p">
            1.1. Настоящая Политика конфиденциальности (далее — «Политика»)
            мобильного приложения «На Парах Курьеры» (далее — «Приложение»)
            действует в отношении всей информации, включая персональные данные в
            понимании применимого законодательства, включая, но не
            ограничиваясь, персональные данные (ПДн) Пользователя, как это
            понимается в соответствии с Федеральным законом РФ № 152-ФЗ «О
            персональных данных» (далее — «Персональная информация»), которую
            Оператор (ООО «На Парах. Коломяжский») может получить с устройства
            Пользователя во время использования им Приложения. Использование
            Приложения означает безоговорочное согласие Пользователя с настоящей
            Политикой и указанными в ней условиями обработки информации,
            получаемой с устройства Пользователя. В случае несогласия с
            Политикой пользователь должен воздержаться от использования
            Приложения. Оператор имеет право вносить изменения в настоящую
            Политику путем размещения новой редакции Политики в Приложении.
            Обязанность самостоятельного ознакомления с актуальной редакцией
            Политики лежит на Пользователе. Связаться с Оператором по вопросам,
            связанным с политикой конфиденциальности можно по адресу:&nbsp;
            <a className="green-link" href="mailto:Info@naparah.ru">
              Info@naparah.ru
            </a>
            .
          </div>

          <div className="privacy-policy__p">
            1.2.&nbsp;
            <span className="privacy-policy__text-bold">Оператор </span>—
            юридическое лицо ООО «На Парах. Коломяжский» (ИНН: 7814251503, ОГРН:
            1157847223071), осуществляющее свою деятельность в соответствии с
            законодательством Российской Федерации, в том числе, посредством
            Интернет-ресурса – Приложения, указанного в настоящей Политике.
          </div>

          <div className="privacy-policy__p">
            1.3.&nbsp;
            <span className="privacy-policy__text-bold">
              Партнер Оператора{' '}
            </span>
            — лицо, осуществляющее совместное с Оператором использование
            Приложения, в том числе в связи с изготовлением Товаров, доставкой
            Товаров, передачей Товаров Пользователю.
          </div>

          <div className="privacy-policy__p">
            1.4.&nbsp;
            <span className="privacy-policy__text-bold">
              Телефон Службы поддержки Пользователей{' '}
            </span>
            - 8 (812) 640-05-06 (г. Санкт-Петербург).
          </div>

          <div className="privacy-policy__p">
            1.5.&nbsp;
            <span className="privacy-policy__text-bold">
              E-mail адрес Службы поддержки Пользователей:{' '}
            </span>
            <a className="green-link" href="mailto:Info@naparah.ru">
              Info@naparah.ru
            </a>
            .
          </div>

          <div className="privacy-policy__p">
            1.6.&nbsp;
            <span className="privacy-policy__text-bold">Приложение </span>—
            программное обеспечение Оператора. Под Приложением подразумеваются
            мобильное Приложение, устанавливаемое на мобильных устройствах связи
            Пользователя (телефоне, планшете и т.п.), а также Приложение,
            устанавливаемое в социальных сетях.
          </div>

          <div className="privacy-policy__p">
            1.7.&nbsp;
            <span className="privacy-policy__text-bold">Пользователь </span>—
            физическое лицо, добровольно прошедшее регистрацию в Приложении и
            авторизованное для пользования всеми доступными ему функциями
            Приложения, являющееся мобильным сотрудником (курьером) Оператора
            и/или его Партнера. Оператор не берет на себя обязательства о
            работоспособности Приложения в любое время, а лишь предоставляет
            возможность их применения в момент их функционирования.
          </div>

          <div className="privacy-policy__p">
            1.8.&nbsp;
            <span className="privacy-policy__text-bold">Покупатель </span>—
            физическое лицо, осуществившее Заказ в Приложении, получивший и
            оплативший Товар, переданный Оператором и/или партнером Оператора по
            данному Заказу лично Покупателю или мобильному сотруднику (курьеру)
            для доставки Покупателю.
          </div>

          <div className="privacy-policy__p">
            1.9.&nbsp;<span className="privacy-policy__text-bold">Заказ </span>—
            последовательность действий Покупателя для выбора и резервирования
            Товара в Приложении.
          </div>

          <div className="privacy-policy__p">
            1.10.&nbsp;
            <span className="privacy-policy__text-bold">Учётная запись </span>
            — персональный раздел Пользователя в Приложении, доступный только
            после прохождения аутентификации (ввода правильных логина и пароля),
            предназначен для хранения сведений, сообщенных о себе Пользователем,
            и управления Заказами, в том числе, но не исключая следующие
            сведения:
            <br />
            — список Заказов на день;
            <br />— место, время и маршрут доставки Заказа.
          </div>
          <div className="privacy-policy__p">
            Кроме того, в Учётной записи предоставлена возможность созваниваться
            с Покупателем или отправлять ему SMS-сообщении; в Учётной записи
            необходимо проставлять отметку о прибытии на место доставки Заказа и
            окончании выполнения указанного Заказа.
          </div>

          <div className="privacy-policy__p">
            1.11.&nbsp;
            <span className="privacy-policy__text-bold">Личный Кабинет </span>—
            персонализированный интерфейс Приложения с ограниченным доступом,
            обладающий набором программных инструментов для персонализированного
            использования Пользователем Приложения, в том числе для
            осуществления доставки Заказов Покупателям Товаров.
          </div>

          <div className="privacy-policy__p">
            1.12.&nbsp;
            <span className="privacy-policy__text-bold">Логин и Пароль </span>—
            уникальные реквизиты авторизации Пользователя в Приложении,
            необходимые для пользования Приложением.
          </div>

          <div className="privacy-policy__p">
            1.13.&nbsp;
            <span className="privacy-policy__text-bold">
              Персональная информация{' '}
            </span>
            — информация, включающая в себя, в том числе, но не исключая:
          </div>
          <div className="privacy-policy__p">
            1.13.1. информацию, предоставленную Пользователем при создании
            Учётной записи (например, Ф.И.О., возраст, фото, номер телефона,
            адрес электронной почты и возраст);
          </div>

          <div className="privacy-policy__p">
            1.13.2. электронные данные (HTTP-заголовки, IP-адрес, файлы cookie,
            веб-маяки/пиксельные теги, данные об идентификаторе браузера,
            информация об аппаратном и программном обеспечении, данные сети
            wi-fi);
          </div>

          <div className="privacy-policy__p">
            1.13.3. дата и время осуществления доступа Пользователя к
            Приложению;
          </div>

          <div className="privacy-policy__p">
            1.13.4. информация об активности Пользователя во время использования
            Приложения;
          </div>

          <div className="privacy-policy__p">
            1.13.5. информация о геолокации, Приложению всегда открыт доступ к
            геоданным, даже если оно неактивно. Цель: информирование
            Пользователя (курьера) о текущем расположении на карте,
            предполагаемом маршруте до цели назначения (в т.ч. место доставки
            Заказа), информирование Оператора: во сколько Пользователь прибыл на
            место, как долго работал, сколько был в пути, отклонялся ли от
            маршрута; подразумевается, что при использовании Приложения
            Пользователь дополнительно информируется о целях использования
            информации о местоположении устройства;
          </div>

          <div className="privacy-policy__p">
            1.13.6. информация о версии операционной системы и модели устройства
            в целях анализа возможных ошибок в работе Приложения и
            совершенствования работы Приложения.
          </div>

          <div className="privacy-policy__p">
            1.13.7. информация об IP-адресе в целях повышения безопасности
            Пользователя при использовании Приложения при реализации блокировки
            входа кроме разрешенных IP-адресов Пользователя.
          </div>

          <div className="privacy-policy__p">
            1.13.8. иная информация о Пользователе, необходимая для обработки в
            соответствии с условиями, регулирующими использование Приложения.
          </div>
          <div className="privacy-policy__p">
            Кроме того, Оператор использует в Приложении файлы cookie и
            веб-маяки (включая пиксельные теги) для сбора Персональной
            информации и связывания такой личной информации с устройством
            Пользователя.
          </div>

          <h3 className="privacyPolicy-heading">
            2. Порядок создания Учётной записи Пользователя
          </h3>

          <div className="privacy-policy__p">
            2.1. В процесс создания Учётной записи Пользователя в Приложении
            Пользователь соглашается пройти процедуру регистрации, заполнив
            форму регистрации и выразив согласие с условиями настоящей Политики
            путем проставления отметки «V» в пункте «Я принимаю условия Политики
            конфиденциальности» и присоединяется к настоящей Политике путем
            совершения следующего конклюдентного действия: нажатие кнопки
            «Зарегистрироваться» при регистрации в Приложении, в том числе в
            социальных сетях.
          </div>

          <div className="privacy-policy__p">
            2.2. По завершении процесса регистрации Пользователь получает логин
            и временный пароль для доступа в Личный кабинет и в течение 3 (Трёх)
            дней Пользователю рекомендуется самостоятельно сменить пароль на
            постоянный. Пользователь несёт ответственность за сохранность и
            неразглашение своей пары логин- пароль, а также за все действия, что
            будут произведены в Приложении под его логином-паролем. Пользователь
            соглашается с тем, что он обязан немедленно уведомить Оператора о
            любом случае неавторизованного (не разрешенного Пользователем)
            доступа со своим логином-паролем и/или о любом нарушении
            безопасности Учётной записи.
          </div>

          <div className="privacy-policy__p">
            2.3. Согласие Пользователя с условиями настоящей Политики
            приравнивается к договору, составленному в письменной форме.
            Присоединяясь к настоящей Политике, Пользователь выражает полное и
            безоговорочное согласие со всеми его условиями, в том числе, в части
            предоставления согласия Оператору на обработку персональных данных
            Пользователя на условиях, указанных в разделе 1.13. Политики, и в
            части предоставления согласия Оператору на получение от него e-mail,
            sms и иных видов рассылок информационного и рекламного содержания, а
            также выражает согласие на дачу Оператором поручения третьим лицам,
            в том числе, но не исключая Партнерам Оператора, связанным с
            размещением и отображением рекламы в Приложении изготовлением или
            доставкой Товаров Оператора и/или партнеров Оператора, компаниям,
            предоставляющим услуги перевозки (такси) на обработку персональных
            данных Пользователя, с целью исполнения Заказа Пользователя,
            передачи Товара Пользователю, осуществления информационной и
            рекламной рассылок.
          </div>

          <div className="privacy-policy__p">
            2.4. Политика может быть изменена Оператором в одностороннем порядке
            без предварительного уведомления Пользователя. Новая редакция
            Политики вступает в силу с момента ее опубликования в Приложении,
            если иное не предусмотрено новой редакцией Политики. Пользователь
            вправе отказаться от принятия изменений Политики, осуществленных
            Оператором, удалив свою Учётную запись, что означает отказ
            Пользователя от использования Приложения.
          </div>

          <h3 className="privacyPolicy-heading">
            3. Порядок защиты, хранения и обработки Персональных данных
            (Персональной информации) Пользователей
          </h3>

          <div className="privacy-policy__p">
            3.1. В большинстве случаев Персональная информация обрабатывается
            автоматически без доступа к ней кого-либо из сотрудников Оператора
            и/или его Партнеров. В случае если такой доступ понадобится, то он
            может быть предоставлен только тем сотрудникам Оператора и/или его
            Партнеров, которые нуждаются в этом для выполнения своих задач. Для
            защиты и обеспечения конфиденциальности данных все сотрудники
            Оператора и/или его Партнеров соблюдают внутренние правила и
            процедуры в отношении обработки Персональной информации, они также
            следуют всем техническим и организационным мерам безопасности,
            действующим для защиты Персональной информации Пользователя.
          </div>

          <div className="privacy-policy__p">
            3.2. Оператор внедрил достаточные технические и организационные меры
            для защиты Персональной информации от несанкционированного,
            случайного или незаконного уничтожения, потери, изменения,
            недобросовестного использования, раскрытия или доступа, а также иных
            незаконных форм обработки. Данные меры безопасности были реализованы
            с учетом современного уровня техники, стоимости их реализации,
            рисков, связанных с обработкой и характером Персональной информации.
          </div>

          <div className="privacy-policy__p">
            3.3. Если иное не установлено условиями использования Приложения, то
            во всех случаях Персональная информация будет обрабатываться только
            в целях статистических и маркетинговых исследований, а также в целях
            контроля и учёта времени и порядка доставки Заказов Покупателям.
          </div>

          <div className="privacy-policy__p">
            3.4. Персональная информация Пользователей хранится в Российской
            Федерации: запись, систематизация, накопление, хранение, уточнение
            (обновление, изменение) и извлечение персональных данных
            Пользователей осуществляется с использованием баз данных,
            находящихся на территории Российской Федерации.
          </div>

          <div className="privacy-policy__p">
            3.5. Персональная информация Пользователей хранится столько времени,
            сколько это необходимо для достижения целей, для которых она была
            собрана, или для соблюдения требований законодательства и
            нормативных актов.
          </div>
          <div className="privacy-policy__p">
            3.6. В Приложении используются следующие типы файлов cookie:
          </div>
          <div className="privacy-policy__p">
            3.6.1. строго необходимые файлы cookie / технические файлы cookie:
            эти файлы cookie необходимы для работы Приложения; кроме всего
            прочего, они позволяют Оператору идентифицировать аппаратное и
            программное обеспечение Пользователя, включая тип браузера;
          </div>

          <div className="privacy-policy__p">
            3.6.2. статистические / аналитические файлы cookie: эти файлы cookie
            позволяют распознавать Пользователей, подсчитывать их количество и
            собирать информацию, такую как произведенные операции в Приложении;
          </div>

          <div className="privacy-policy__p">
            3.6.3. технические файлы cookie: эти файлы cookie собирают
            информацию о том, как Пользователи взаимодействуют с Приложением,
            что позволяет выявлять ошибки и тестировать новые функции для
            повышения производительности Приложения;
          </div>

          <div className="privacy-policy__p">
            3.6.4. функциональные файлы cookie: эти файлы cookie позволяют
            предоставлять определенные функции, чтобы облегчить использование
            Приложения, например, сохраняя предпочтения Пользователя (такие как
            язык и местоположение);
          </div>
          <div className="privacy-policy__p">
            3.6.5. Оператор использует информацию, содержащуюся в файлах cookie
            только в указанных выше целях, после чего собранные данные будут
            храниться на устройстве Пользователя в течение периода, который
            может зависеть от соответствующего типа файлов cookie, но не
            превышая срока, необходимого для достижения их цели, после чего они
            будут автоматически удалены.
          </div>

          <h3 className="privacyPolicy-heading">4. Заключительные положения</h3>

          <div className="privacy-policy__p">
            4.1. Настоящая Политика вступает в действие относительно
            регулирования отношений между Оператором и/или Партнёрами Оператора
            и Пользователем с момента присоединения Пользователя в порядке,
            указанном в разделе 2 Политики.
          </div>

          <div className="privacy-policy__p">
            4.2. Настоящая Политика регламентируется в т.ч. нормами действующего
            законодательства РФ.
          </div>

          <div className="privacy-policy__p">
            4.3. Все возможные споры, возникшие между Пользователем и Оператором
            в течение действия настоящей Политики и связанные с её исполнением,
            разрешаются согласно нормам действующего законодательства РФ с
            обязательным соблюдением претензионного порядка разрешения спора.
          </div>

          <div className="privacy-policy__p">
            4.4. С предложениями и вопросами, связанными с исполнением Политики,
            необходимо обращаться в Службу поддержки Оператора по телефонной
            связи и/или на E-mail адрес согласно п.п. 1.4. и 1.5. Политики.
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicyСouriers;
