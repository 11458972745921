import { useEffect, useState, useRef } from 'react';
import './PresenceInfoModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tabs } from '@material-ui/core';
import { useTimetable } from '../../../reusable/hooks/useTimetable';
import { PresenceInfoTabs } from '../../customMuiComponents/PresenceInfoTabs';
import { DeliveryAddressSelector } from '../../../reusable/DeliveryAdressSelector/DeliveryAddressSelector';
import { mapOptions } from '../../../util/orders';
import {
  DEFAULT_ADDRESS,
  DEFAULT_DELIVERY_STATUS,
  PRESENCES,
} from '../../../util/constants';
import * as actionCreators from '../../../store/actions';
import FormControl from '@material-ui/core/FormControl';
import { validatePresenceAddress } from '../../../util/validators';
import { definePresenceType, getCloneObjWithoutKeys } from '../../../util/misc';
import TheDropdown from '../../../reusable/TheDropdown/TheDropdown';
import clsx from 'clsx';

const haveErrors = (errors) => Object.values(errors).some((error) => !!error);

function PresenceInfoModal() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const restaurants = useSelector((state) => state.companyInfo.contacts);
  const restaurantsPresent = !!restaurants.length;
  const mappedRestaurants = mapOptions(restaurants, 'id', 'address', 'name');
  const btnRef = useRef();
  const initialPresenceAddress = userInfo?.address || DEFAULT_ADDRESS;
  const initialPresenceRestaurantId = String(
    userInfo?.takeaway_restaurant || mappedRestaurants[0]?.key,
  );
  const isShowSuccessProfileEdit = useSelector(
    (state) => state.view.modalContent?.isShowSuccessProfileEdit,
  );
  const placeOrder = useSelector(
    (state) => state.view.modalContent?.placeOrder ?? false,
  );

  const dontNeedEscBtn = useSelector(
    (state) => state.view.modalContent?.dontNeedCloseByEsc,
  );
  const presences = useSelector((state) => state.orders.presences);

  const [presenceAddress, setPresenceAddress] = useState(
    initialPresenceAddress,
  );
  const [presenceRestaurantId, setPresenceRestaurantId] = useState(
    initialPresenceRestaurantId,
  );
  const presenceRestaurant = mappedRestaurants.find(
    (el) => String(el.key) === String(presenceRestaurantId),
  );
  const [showAddressSelector, setShowAddressSelector] = useState(true);
  const [showRestaurantToEat, setShowRestaurantToEatSelector] = useState(false);
  const [showRestaurantSelector, setShowRestaurantSelector] =
    useState(!showAddressSelector);

  const initialIsDelivery = userInfo?.presence_state === PRESENCES.delivery;
  const {
    setIsDelivery,
    mappedDeliveryTimes,
    mappedRestorantPickupTimes,
    initialDeliveryTimeOption,
    deliveryTime,
    setDeliveryTime,
  } = useTimetable(
    presenceRestaurantId,
    initialIsDelivery,
    showAddressSelector,
  );

  const [deliveryStatus, setDeliveryStatus] = useState(DEFAULT_DELIVERY_STATUS);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (!isLoadingFailed) {
      dispatch(actionCreators.getDeliveryInfo(!!restaurants.length));
    }
  }, [dispatch, isLoadingFailed, restaurants.length]);

  const isStayExist = presences.find(
    (presence) => presence?.key === PRESENCES.stay,
  );

  const title = placeOrder
    ? 'Выберите адрес доставки'
    : 'Выберите способ доставки';

  const dropdownCls = clsx(
    'presence-info-form__time-select',
    showAddressSelector ? 'adress-selector' : 'restaurant-selector',
  );

  const checkFormFields = (formErrors = {}, { house } = '') => {
    const errors = {
      ...formErrors,
      house: house === null ? 'Введите номер дома' : '',
    };
    switch (showAddressSelector) {
      case false:
        if (
          mappedRestaurants
            ?.map((item) => item.key)
            .includes(+presenceRestaurantId)
        ) {
          errors.presence = '';
        } else {
          errors.presence = 'Выберите ресторан самовывоза';
        }

        if (!!deliveryTime) {
          errors.deliveryTime = '';
        } else {
          errors.deliveryTime = 'Выберите время из списка';
        }

        setFormErrors((prev) => ({ ...prev, ...errors }));
        return errors;
      case true:
        if (validatePresenceAddress(presenceAddress)) {
          errors.presence = '';
        } else {
          errors.presence = 'Выберите адрес доставки';
        }

        if (
          (!!deliveryTime && deliveryStatus.isPositive) ||
          !deliveryStatus.isPositive
        ) {
          errors.deliveryTime = '';
        } else {
          errors.deliveryTime = 'Выберите время из списка';
        }

        setFormErrors((prev) => ({ ...prev, ...errors }));
        return errors;
      default:
        return;
    }
  };

  useEffect(() => {
    if (!restaurantsPresent && !isLoadingFailed) {
      dispatch(actionCreators.getCompanyContacts());
    }
  }, [restaurantsPresent, isLoadingFailed, dispatch]);

  const onSubmit = () => {
    const values = {
      presence_state: showAddressSelector
        ? PRESENCES.delivery
        : showRestaurantSelector
          ? PRESENCES.takeaway
          : PRESENCES.stay,
      delivery_time: deliveryTime,
    };
    setIsSubmitting(true);

    if (!showAddressSelector) {
      values.takeaway_restaurant = +presenceRestaurantId;
    }
    if (showAddressSelector) {
      values.address = getCloneObjWithoutKeys(presenceAddress, 'id');
    }
    if (showAddressSelector && !deliveryStatus.isPositive) {
      values.delivery_time = null;
    }
    dispatch(
      actionCreators.patchPresenceInfo(
        values,
        setIsSubmitting,
        location.pathname,
        isShowSuccessProfileEdit,
      ),
    );
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsFormTouched(true);
    const house = showAddressSelector
      ? getCloneObjWithoutKeys(presenceAddress, 'id')
      : '';
    if (!haveErrors(checkFormFields(formErrors, house))) {
      onSubmit();
    } else {
      setIsFormValid(false);
      setIsFormTouched(true);
    }
  };

  const showTimePicker = ({ pickup, delivery }) => {
    return (
      (pickup || delivery) && (
        <div className="pos-relative">
          {!showAddressSelector && (
            <h3 className="presence-info-form__subheading">Время:</h3>
          )}
          <TheDropdown
            options={
              showAddressSelector
                ? mappedDeliveryTimes
                : mappedRestorantPickupTimes
            }
            title={showAddressSelector ? 'Время доставки:' : ''}
            setValue={(option) => option?.key && setDeliveryTime(option?.key)}
            initialOption={initialDeliveryTimeOption}
            className={dropdownCls}
            setFormErrors={setFormErrors}
          />
          {!isFormValid && isFormTouched && formErrors?.deliveryTime && (
            <span className="presence-info-form__error">
              {formErrors.deliveryTime}
            </span>
          )}
        </div>
      )
    );
  };

  if (!userInfo) {
    return null;
  }

  return (
    <form className="presence-info-form">
      <h2 className="presence-info-form__heading">{title}</h2>
      <div className="pos-relative">
        {!isFormValid && isFormTouched && (
          <span className="presence-info-form__error">
            {formErrors.presence}
          </span>
        )}
        {!placeOrder && (
          <Tabs
            className="mb-30"
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            value={
              showAddressSelector ||
              showRestaurantSelector ||
              showRestaurantToEat
            }
            onChange={(ev, isD) => {
              setFormErrors({});
              definePresenceType({
                setShowAddressSelector,
                setShowRestaurantSelector,
                setShowRestaurantToEatSelector,
                type: ev.target.innerText,
              });
              if (!isD) {
                document
                  .querySelector('.presence-info-modal')
                  .classList.add('presence-info-modal--delivery');
              } else {
                document
                  .querySelector('.presence-info-modal')
                  .classList.remove('presence-info-modal--delivery');
              }
              setIsDelivery(isD);
              setIsFormTouched(true);
            }}
          >
            <PresenceInfoTabs label="Доставка" value={showAddressSelector} />
            <PresenceInfoTabs
              label="Самовывоз"
              value={showRestaurantSelector}
            />
            {isStayExist && (
              <PresenceInfoTabs
                label="Поедим в ресторане"
                value={showRestaurantToEat}
              />
            )}
          </Tabs>
        )}
        <div role="tabpanel" className="presence-info-form__tabpanel">
          {showAddressSelector && (
            <DeliveryAddressSelector
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              lastAddresses={userInfo?.last_addresses || []}
              setSelectedAddress={setPresenceAddress}
              deliveryStatus={deliveryStatus}
              setDeliveryStatus={setDeliveryStatus}
              requiredFieldsTouched={isFormTouched}
              isAnyAddressPossible={true}
              className="show-with-opacity"
              btnRef={btnRef.current}
            />
          )}
          {showRestaurantSelector && (
            <div className="show-with-opacity">
              <h3 className="presence-info-form__subheading">
                Доступные рестораны:
              </h3>

              <FormControl
                component="fieldset"
                className="MuiFormControl-root_radio"
              >
                <TheDropdown
                  options={mappedRestaurants}
                  setValue={(option) => {
                    setPresenceRestaurantId(option?.key);
                    setIsFormTouched(true);
                  }}
                  initialOption={presenceRestaurant}
                  className="presence-info-form__restaurants"
                  setFormErrors={setFormErrors}
                  btnRef={btnRef.current}
                />
              </FormControl>
            </div>
          )}
          {showRestaurantToEat && (
            <div className="show-with-opacity">
              <h3 className="presence-info-form__subheading">
                Доступные рестораны:
              </h3>
              <FormControl
                component="fieldset"
                className="MuiFormControl-root_radio"
              >
                <TheDropdown
                  options={mappedRestaurants}
                  setValue={(option) => {
                    setPresenceRestaurantId(option?.key);
                    setIsFormTouched(true);
                  }}
                  initialOption={presenceRestaurant}
                  className="presence-info-form__restaurants"
                  setFormErrors={setFormErrors}
                />
              </FormControl>
            </div>
          )}
        </div>
      </div>
      {showTimePicker({
        pickup: !!mappedRestorantPickupTimes.length && !showAddressSelector,
        delivery: !!mappedDeliveryTimes.length && showAddressSelector,
      })}
      <div className="presence-info-form__btn__group">
        {!dontNeedEscBtn && (
          <button
            className="presence-info-form__btn button-secondary"
            type="button"
            onClick={() => dispatch(actionCreators.clearModal())}
          >
            отменить
          </button>
        )}

        <button
          className="presence-info-form__btn button-standard"
          type="button"
          disabled={isSubmitting}
          onClick={submitHandler}
          ref={btnRef}
        >
          сохранить
        </button>
      </div>
    </form>
  );
}

export default PresenceInfoModal;
