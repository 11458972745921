import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Cart.scss';
import EmptyPage from '../../reusable/EmptyPage/EmptyPage';
import Share from './Share/Share';
import * as actionCreators from '../../store/actions';
import CartBody from '../../reusable/CartBody/CartBody';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function Cart() {
  useTitleUpdate('Корзина');

  const dispatch = useDispatch();

  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const userCart = useSelector((state) => state.userCart.userCart);

  const [link, setLink] = useState(null);

  const dependency = !!userCart && JSON.stringify(userCart);
  const isNeedReloadCartDishes = useSelector(
    (state) => state.userCart.isNeedReloadCartDishes,
  );

  useEffect(() => {
    if (userCart?.length) {
      //  TODO:  надо понять на сколько сильно спам изменения корзины забивает БД (это запрос на обновление линка).
      dispatch(actionCreators.basketShare(userCart, setLink));
    }
  }, [dependency, dispatch]);

  useEffect(() => {
    if (isNeedReloadCartDishes) dispatch(actionCreators.getUserCart());
  }, [isNeedReloadCartDishes, dispatch]);

  if (!userCart) {
    return isAuthorized ? null : (
      <h1 className="heading cart-heading">Корзина</h1>
    );
  }

  return (
    <>
      <section className="cart-section">
        <div className="page-header">
          <h1 className="heading cart-heading">Корзина</h1>
          {!!userCart.length && <Share link={link} />}
        </div>
        {userCart.length ? (
          <CartBody userCart={userCart} link={link} />
        ) : (
          <EmptyPage
            topText="В вашей корзине пусто,"
            bottomText="добавьте блюдо"
          />
        )}
      </section>
    </>
  );
}

export default Cart;
