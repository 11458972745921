import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import * as actionCreators from '../../store/actions';
import { PRESENCES } from '../../util/constants';
import { findObjInArrayByValue } from '../../util/misc';
import { mapOptions } from '../../util/orders';

export function useTimetable(
  presenceRestaurantId,
  initIsDelivery,
  showAddressSelector,
) {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const initialDeliveryTime = useSelector(
    (state) => state.currentUser.userInfo?.delivery_time,
  );
  const deliveryTimes = useSelector((state) => state.orders.deliveryTimes);
  const restorantPickupTimes = useSelector(
    (state) => state.orders.restorantPickupTimes,
  );

  const [isDelivery, setIsDelivery] = useState(initIsDelivery);
  const [deliveryTime, setDeliveryTime] = useState(initialDeliveryTime);

  const mappedDeliveryTimes = useMemo(
    () => mapOptions(deliveryTimes, 'stamp', 'name'),
    [deliveryTimes],
  );

  const mappedRestorantPickupTimes = useMemo(
    () => mapOptions(restorantPickupTimes, 'stamp', 'name'),
    [restorantPickupTimes],
  );

  const initialDeliveryTimeOption = useMemo(
    () => findObjInArrayByValue(deliveryTime, mappedDeliveryTimes, 'key'),
    [deliveryTime, mappedDeliveryTimes],
  );

  useEffect(() => {
    if (showAddressSelector) return;
    // TODO: пнуть @dmironenko:riotim.olegb.ru
    if (
      presenceRestaurantId !== 'undefined' &&
      presenceRestaurantId !== undefined
    ) {
      dispatch(actionCreators.getRestaurantDeliveryTimes(presenceRestaurantId));
    }
  }, [isDelivery, presenceRestaurantId]);

  useEffect(() => {
    const newVal = userInfo?.presence_state === PRESENCES.delivery;
    if (isDelivery === newVal) return;
    setIsDelivery(newVal);
  }, [userInfo?.presence_state]);

  return {
    isDelivery,
    setIsDelivery,
    mappedDeliveryTimes,
    mappedRestorantPickupTimes,
    initialDeliveryTimeOption,
    deliveryTime,
    setDeliveryTime,
  };
}
