import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase, makeStyles } from '@material-ui/core';
import { useAutocomplete, createFilterOptions } from '@material-ui/lab';
import { getSuggestionsStreetDebounced } from '../../util/api';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  listbox: {
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: theme.zIndex.modal,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: 48,
      padding: '5px 16px',
      textAlign: 'left',
      textDecoration: 'none',
      maxWidth: '100%',
      position: 'relative',
      cursor: 'pointer',
      outline: 0,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: ' border-box',

      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04);',
      },
    },
    '& strong': {
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    '& small': {
      color: theme.palette.secondary.dark,
      fontSize: 14,
    },
  },
  option: {
    display: 'inline-block',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
  },
}));

export const formatHouse = (address, isOption = false) => {
  if (!address) return;

  const city =
    address?.city !== 'Санкт-Петербург' && !isOption ? `${address.city}, ` : '';
  const settlement = address?.settlement ? `${address.settlement}, ` : '';
  const house = address?.house ? ` ${address.house}` : '';
  const corp = address?.block || address?.corp;

  const block = corp ? `, корп. ${corp}` : '';

  return `${settlement ? settlement : city}${address.street}${house}${block}`;
};

const formatCity = (address) => {
  return `${address.country}, ${address.city}`;
};

const removeCorpFromSearch = (search) => {
  const re = /корп\./gi;
  return search.replace(re, '');
};

const showError = (errors) =>
  errors?.geo || errors.street || errors.house || null;

const StreetAutocomplete = ({
  onChangeStreet,
  address,
  containerClass,
  errors,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(formatHouse(address));
  const [fieldTouched, setFieldTouched] = useState(false);
  const [suggestionsStreet, setSuggestionsStreet] = useState([]);

  useEffect(() => {
    getSuggestionsStreetDebounced.cancel();
    if (searchValue?.length > 3) {
      getSuggestionsStreetDebounced(
        removeCorpFromSearch(searchValue),
        setSuggestionsStreet,
        dispatch,
      );
      return;
    }
    setSuggestionsStreet([]);
  }, [searchValue, dispatch]);

  useEffect(() => {
    setSearchValue(formatHouse(address));
  }, [address]);

  const isErrorsGeo = !!errors.geo;

  useEffect(() => {
    if (isErrorsGeo) setFieldTouched(true);
  }, [isErrorsGeo]);

  const onChangeAutocomplete = (_, values) => {
    if (!values) return;

    const { street, house, block, settlement, city } = values;

    const obj = {
      street,
      house,
      corp: block || '',
      settlement,
      city,
    };
    setSearchValue(formatHouse(obj));
    onChangeStreet(obj);
    setFieldTouched(true);
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'street-autocomplete',
    freeSolo: true,
    filterOptions: createFilterOptions({ trim: true }),
    inputValue: searchValue,
    options: suggestionsStreet,
    onChange: onChangeAutocomplete,
    getOptionLabel: (option) => formatHouse(option),
  });

  const onBlurCapture = () => {
    setFieldTouched(true);

    const anyMatch = !!groupedOptions?.length;

    if (anyMatch) {
      const { street, house, block } = groupedOptions[0];
      onChangeStreet({ street, house, corp: block });
    } else {
      onChangeStreet(address);
    }
  };

  return (
    <>
      <div
        className={clsx(
          'text-field',
          containerClass,
          !searchValue && fieldTouched && 'text-field--error',
        )}
      >
        <div {...getRootProps()}>
          <InputBase
            placeholder="Улица, дом, корпус (при наличии)"
            className="text-field__input"
            onInput={({ target: { value } }) => {
              setSearchValue(value);
            }}
            onBlurCapture={onBlurCapture}
            {...getInputProps()}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              },
            }}
          />
        </div>
        {groupedOptions?.length > 0 && (
          <ul className={classes.listbox} {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li
                {...getOptionProps({ option, index })}
                key={`${option.street}-${option.house}-${option.block}-${option.city}`}
              >
                <strong className={classes.option}>
                  {formatHouse(option, true)}
                </strong>
                <small className={classes.option}>{formatCity(option)}</small>
              </li>
            ))}
          </ul>
        )}
        {!!showError(errors) && (
          <span className="text-field__error-message">{showError(errors)}</span>
        )}
      </div>
    </>
  );
};

StreetAutocomplete.propTypes = {
  onChangeStreet: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
  isTouched: PropTypes.bool,
  errors: PropTypes.shape({
    geo: PropTypes.string,
    street: PropTypes.string,
    house: PropTypes.string,
  }).isRequired,
};

export default StreetAutocomplete;
