import PropTypes from 'prop-types';
import { getPhoneParts } from '../../util/phone';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as BikeIcon } from '../../assets/icons/header/bike.svg';
import { Link } from 'react-router-dom';
import { days } from '../../util/dates';

function PhoneDisplay({ phone }) {
  const phoneParts = getPhoneParts(phone);

  return (
    <a href={`tel:+${phone}`} className="simple-flex justify-center">
      <span className="phone-icon-wrapper">
        <span className="icon-phone" />
      </span>
      <span className="header-phone-number-wrapper">
        <span className="header-title">Единый телефон:</span>
        <span className="phone-value">
          {phoneParts[0]}
          <span>{phoneParts[1]}</span>
        </span>
      </span>
    </a>
  );
}

PhoneDisplay.propTypes = {
  phone: PropTypes.string.isRequired,
};

function ToolbarLeft({ phone, minPrice, openingTime }) {
  const deliveryDays = openingTime[0].days;
  const deliveryTime = openingTime[0].span;

  return (
    <div className="simple-flex header-content-left">
      <PhoneDisplay phone={phone} />
      <Link
        className="simple-flex"
        to="/условия-доставки"
        title="Условия доставки"
      >
        <SvgIcon
          component={BikeIcon}
          classes={{ root: 'header-bike-icon' }}
          viewBox="0 0 25 25"
        />
        <span className="header-opening-time-wrapper">
          <span className="opening-time-days">
            {`${days[deliveryDays.from]}-${days[deliveryDays.to]}`}
            <span className="opening-time-hours">{`${deliveryTime[0]}-${deliveryTime[1]}`}</span>
          </span>
          <span className="header-title">Доставка от {minPrice} руб</span>
        </span>
      </Link>
    </div>
  );
}

ToolbarLeft.propTypes = {
  phone: PropTypes.string.isRequired,
  minPrice: PropTypes.number.isRequired,
  openingTime: PropTypes.array.isRequired,
};

export default ToolbarLeft;
