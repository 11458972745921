import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { getCookie, setCookie } from '../../util/cookies';
import { PRESENCES } from '../../util/constants';
import PropTypes from 'prop-types';

const PickDeliveryMethod = ({ setIsDelivery, setFieldValue }) => {
  const dispatch = useDispatch();

  const presences = useSelector((state) => state.orders.presences);
  const userInfo = useSelector((state) => state.currentUser.userInfo);

  const userPresence = userInfo?.presence_state;
  const savedPresence = getCookie('savedPresence');

  const [presenceSelected, setPresenceSelected] = useState(
    () => savedPresence || userInfo?.presence_state,
  );

  useEffect(() => {
    setPresenceSelected(savedPresence || userInfo?.presence_state);
  }, [userPresence, savedPresence, userInfo?.presence_state]);

  const handleChange = (e) => {
    const value = e.target.value;

    setIsDelivery(value === PRESENCES.delivery);
    setPresenceSelected(value);
    setFieldValue('presence', value);
    setCookie('savedPresence', value);
    dispatch(actionCreators.setCurrentUserPresenceMethod(value));
  };

  return (
    <RadioGroup
      aria-label="delivery-method"
      value={presenceSelected}
      className="place-order__delivery"
      onChange={(e) => handleChange(e)}
    >
      {presences?.map((presence) => (
        <FormControlLabel
          key={presence.key}
          value={presence.key}
          className="place-order__delivery-label"
          control={<Radio className="place-order__delivery-radio" />}
          label={presence.name}
        />
      ))}
    </RadioGroup>
  );
};

PickDeliveryMethod.propTypes = {
  setIsDelivery: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default PickDeliveryMethod;
