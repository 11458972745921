import './DeliveryAddressSelector.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YandexMap from '../../components/YandexMap/YandexMap';
import * as actionCreators from '../../store/actions';
import { getClientAddress, getDeliveryStatusDebounced } from '../../util/api';
import clsx from 'clsx';
import GeoButton from '../../components/GeoButton/GeoButton';
import StreetAutocomplete from '../../components/StreetAutocomplete/StreetAutocomplete';
import {
  DEFAULT_ADDRESS,
  DEFAULT_CLIENT_COORDINATE,
  DEFAULT_DELIVERY_STATUS,
} from '../../util/constants';
import { setCookie, getCookie } from '../../util/cookies';
import ClickOutsideButton from '../../components/clickOutsideButtons/ClickOusideButton';
import PropTypes from 'prop-types';
import { addressFromCookiePresent } from '../../util/misc';
import * as _ from 'lodash';
import { removeMinusBefore } from '../../util/validators';
import { selectRestaurant } from '../../store/selectors/selectRestaurant';
import { DeliveryStatus } from '../DeliveryStatus';

export function DeliveryAddressSelector({
  lastAddresses,
  setSelectedAddress,
  deliveryStatus,
  setDeliveryStatus,
  requiredFieldsTouched,
  isAnyAddressPossible,
  className,
  formErrors,
  setFormErrors,
  btnRef,
}) {
  const dispatch = useDispatch();
  const restaurants = useSelector((state) => state.companyInfo.contacts);
  const userInfo = useSelector((state) => state.currentUser.userInfo);

  const initialAddress = addressFromCookiePresent(getCookie('savedAddress'))
    ? getCookie('savedAddress')
    : userInfo?.address || DEFAULT_ADDRESS;

  const [clientCoordinate, setClientCoordinate] = useState(
    DEFAULT_CLIENT_COORDINATE,
  );
  const [address, setAddress] = useState(initialAddress);

  const [touchedFields, setTouchedFields] = useState({});
  const [isPrevAddressesVisible, setIsPrevAddressesVisible] = useState(false);

  const deliveryContacts = useSelector((state) =>
    selectRestaurant(state, deliveryStatus?.restaurant),
  );

  useEffect(() => {
    if (!_.isEqual(userInfo?.address, address) && userInfo?.address) {
      setAddress(userInfo?.address);
    }
  }, [userInfo?.address]);

  useEffect(() => {
    getDeliveryStatusDebounced.cancel();
    if (!address.street || !address.house) {
      dispatch(actionCreators.setDeliveryTimes([]));
      clearClientCoordinate();
      clearDeliveryStatus();
      return;
    }
    const data = {
      address: {
        street: address?.street,
        house: address?.house,
        corp: address?.corp,
        ...(address?.city && { city: address.city }),
        ...(address?.settlement && { settlement: address.settlement }),
      },
    };
    setDeliveryStatus(DEFAULT_DELIVERY_STATUS);
    getDeliveryStatusDebounced(
      data,
      setDeliveryStatus,
      setClientCoordinate,
      dispatch,
    );
  }, [
    address.street,
    address.house,
    address.corp,
    address.city,
    address.settlement,
  ]);

  useEffect(() => {
    if (deliveryStatus.isPositive) {
      setSelectedAddress(address);
    }
  }, [deliveryStatus, touchedFields]);

  useEffect(() => {
    setTouchedFields({
      street: requiredFieldsTouched,
      house: requiredFieldsTouched,
    });
  }, [requiredFieldsTouched]);

  useEffect(() => {
    if (isAnyAddressPossible) {
      setSelectedAddress(address);
    }
  }, [address]);

  const clearDeliveryStatus = () => {
    if (!deliveryStatus.message && !deliveryStatus.isPositive) {
      return;
    }
    setDeliveryStatus(DEFAULT_DELIVERY_STATUS);
  };

  const clearClientCoordinate = () => {
    if (clientCoordinate.lat === null && clientCoordinate.lon === null) {
      return;
    }
    setClientCoordinate(DEFAULT_CLIENT_COORDINATE);
  };

  const selectAddress = (address, setIsPrevAddressesVisible) => {
    setAddress(address);
    setCookie('savedAddress', address, 1);
    setClientCoordinate({ lat: address.lat, lon: address.lon });
    setTouchedFields({
      street: true,
      house: true,
    });
    setIsPrevAddressesVisible(false);
  };

  const setGeoUser = (coordinates) => {
    clearClientCoordinate();
    clearDeliveryStatus();
    dispatch(
      getClientAddress(
        coordinates,
        setAddress,
        setDeliveryStatus,
        setFormErrors,
        true,
      ),
    );
  };

  const onChangeAddress = (obj) => {
    setAddress((prev) => {
      const newAddress = { ...prev, ...obj };
      setCookie('savedAddress', newAddress, 1);
      return newAddress;
    });
  };

  const handleChange = ({ target: { value, name } }) => {
    const inputs = ['entrance', 'flat'];

    const obj = {
      [name]: inputs.includes(name) ? removeMinusBefore(value) : value,
    };
    onChangeAddress(obj);
  };

  const handleErrors = (values) => {
    const { street, house } = values;

    const streetError = !street ? 'Введите улицу' : '';
    const houseError = !house ? 'Введите номер дома' : '';

    setFormErrors((prev) => ({
      ...prev,
      street: streetError,
      house: houseError,
      ...(!!prev?.geo && { geo: null }),
    }));
  };

  const handleGeolocationError = (message) => {
    setDeliveryStatus({ message, isPositive: false });
  };

  const handleNavigation = () => dispatch(actionCreators.clearModal());

  return (
    <div className={`place-order__delivery-address ${className}`}>
      <div className="place-order__map-title">Адрес доставки</div>
      <div className="place-order__map-container">
        <div className="place-order__map">
          <YandexMap
            restaurants={restaurants}
            clientCoordinate={clientCoordinate}
          />
        </div>
        <div className="error-map-div">
          {deliveryStatus.message && (
            <DeliveryStatus
              deliveryStatus={deliveryStatus}
              deliveryContacts={deliveryContacts}
              onNavigation={handleNavigation}
            />
          )}
        </div>
      </div>
      <div className="place-order__address-wrapper">
        <div className="place-order__street mb-35">
          <GeoButton setGeoUser={setGeoUser} onError={handleGeolocationError} />
          <ClickOutsideButton
            fieldFunction={selectAddress}
            addressField={true}
            fieldData={lastAddresses}
            isVisible={isPrevAddressesVisible}
            setIsVisible={setIsPrevAddressesVisible}
          />

          <StreetAutocomplete
            onChangeStreet={(obj) => {
              !touchedFields.street &&
                setTouchedFields((prev) => ({ ...prev, street: true }));

              handleErrors(obj);
              onChangeAddress(obj);
              if (obj.house) {
                btnRef?.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            address={address}
            errors={formErrors}
            containerClass={clsx(
              (!!formErrors.street ||
                !!formErrors.house ||
                !!formErrors?.geo) &&
              'text-field--error',
            )}
          />
        </div>

        <input
          className="text-field__input text-field__input--xs"
          placeholder="Парадная"
          type="number"
          maxLength="6"
          name="entrance"
          value={address.entrance}
          onChange={handleChange}
        />

        <input
          className="text-field__input text-field__input--xs"
          placeholder="Этаж"
          type="number"
          maxLength="6"
          name="floor"
          value={address.floor}
          onChange={handleChange}
        />

        <input
          className="text-field__input text-field__input--xs"
          placeholder="Кв./Офис"
          type="number"
          maxLength="6"
          name="flat"
          value={address.flat}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

DeliveryAddressSelector.propTypes = {
  lastAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      street: PropTypes.string,
      house: PropTypes.string,
      corp: PropTypes.string,
      city: PropTypes.string,
      settlement: PropTypes.string,
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  ).isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
  deliveryStatus: PropTypes.shape({
    message: PropTypes.string,
    isPositive: PropTypes.bool,
    restaurant: PropTypes.number,
  }).isRequired,
  setDeliveryStatus: PropTypes.func.isRequired,
  requiredFieldsTouched: PropTypes.bool,
  isAnyAddressPossible: PropTypes.bool,
  className: PropTypes.string,
  formErrors: PropTypes.shape({
    street: PropTypes.string,
    house: PropTypes.string,
    geo: PropTypes.string,
  }).isRequired,
  setFormErrors: PropTypes.func.isRequired,
  btnRef: PropTypes.shape({
    scrollIntoView: PropTypes.func,
  }),
};
