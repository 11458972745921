import { useSelector } from 'react-redux';
import PresenceInfoModal from '../components/modals/PresenceInfoModal/PresenceInfoModal';
import LogOutModal from '../components/modals/LogOutModal/LogOutModal';
import LogInModal from '../components/modals/LogInModal/LogInModal';
import DeleteModal from '../components/modals/DeleteModal/DeleteModal';
import MessageModal from '../components/modals/MessageModal/MessageModal';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from '../util/constants';

function ModalContent({ onClose }) {
  const modalContent = useSelector((state) => state.view.modalContent);

  switch (modalContent?.modalType) {
    case MODAL_TYPES.PRESENCE_INFO:
      return <PresenceInfoModal />;
    case MODAL_TYPES.LOG_IN:
      return <LogInModal />;
    case MODAL_TYPES.LOG_OUT:
      return <LogOutModal />;
    case MODAL_TYPES.DELETE:
      return <DeleteModal />;
    case MODAL_TYPES.MESSAGE:
      return <MessageModal onClose={onClose} />;
    default:
      return <div />;
  }
}

ModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalContent;
