import './TheCheckbox.scss';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const TheCheckbox = ({
  label,
  name,
  handleBlur,
  autoComplete,
  containerClass,
  modifier,
}) => {
  const classes = ['checkbox'];
  modifier && classes.push(`checkbox--${modifier}`);
  containerClass && classes.push(containerClass);

  return (
    <label className={classes.join(' ')}>
      <Field
        className="checkbox__btn"
        type="checkbox"
        name={name}
        onBlur={handleBlur}
        autoComplete={autoComplete}
      />
      <span className="checkbox__custom" />
      <span>{label}</span>
    </label>
  );
};

TheCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  autoComplete: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  modifier: PropTypes.string,
};

export default TheCheckbox;
