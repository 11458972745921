import './EmptyPage.scss';
import PropTypes from 'prop-types';

function EmptyPage({ topText, bottomText }) {
  return (
    <div className="empty-request">
      <div className="icon-empty-request">
        <span className="icon-empty2" />
      </div>
      <div className="empty-request__text">
        {topText}
        <br />
        {bottomText}
      </div>
    </div>
  );
}

EmptyPage.propTypes = {
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string,
};

export default EmptyPage;
