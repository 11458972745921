import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './FriendCart.scss';
import CartBody from '../../reusable/CartBody/CartBody';
import * as actionCreators from '../../store/actions';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function FriendCart() {
  useTitleUpdate('Корзина друга');

  const { link } = useParams();

  const dispatch = useDispatch();

  const friendCart = useSelector((state) => state.userCart.friendCart);
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const friendCartPresent = !!friendCart;

  useEffect(() => {
    if (!friendCartPresent && !isLoadingFailed) {
      dispatch(actionCreators.getFriendCart(link));
    }
  }, [friendCartPresent, isLoadingFailed, dispatch, link]);

  if (!friendCart) {
    return null;
  }

  return (
    <>
      <section className="cart-section">
        <div className="page-header">
          <h1 className="heading cart-heading">Корзина друга</h1>
        </div>
        <CartBody userCart={friendCart} link={link} />
      </section>
    </>
  );
}

export default FriendCart;
