export function validateName(value, minLength, maxLength) {
  if (!value) {
    return 'Это поле обязательно';
  }

  const trimLen = value.trim().length;
  if (trimLen > maxLength || trimLen < minLength) {
    return 'Введите корректное имя';
  }
  return null;
}

export function validateEmail(value, maxLength) {
  if (!value) {
    return 'Это поле обязательно';
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())) {
    return 'Введите действующий E-mail.';
  }
  if (value.trim().length > maxLength) {
    return 'Введите корректный E-mail.';
  }
  return null;
}

export function validateLoyalty(value, maxValue, currentValue) {
  if (!value) {
    return null;
  }

  if (value > maxValue) {
    return `Количество баллов для списания не должно превышать ${maxValue}`;
  }

  if (value > currentValue) {
    return 'У вас недостаточно баллов для списания';
  }

  return null;
}

export function validatePositiveNumberField(str) {
  return +str.replace(/[^0-9]/gi, '');
}

export function validateQuestionnaireAnswer(
  value,
  minLength,
  maxLength,
  withComment,
) {
  if (!value && withComment) {
    return 'Другое (укажите)';
  }

  if (!value) {
    return -1;
  }
  if (value === '😞' || value === '😐' || value === '😊') {
    return value;
  }
  const trimLen = value.trim().length;
  if (trimLen > maxLength || trimLen < minLength) {
    return -1;
  }
  return value;
}

export function validatePhone(value) {
  if (!value) {
    return -1;
  }
  const phone = value.replace(/\D/g, '');
  if (phone.length !== 11) {
    return -1;
  }
  return phone;
}

export function validateRestaurantId(value) {
  if (/^\d+$/.test(value)) {
    return Number(value);
  }
  return -1;
}

function validateStringByLength(str, minLength, maxLength) {
  if (!str) {
    return 'empty value';
  }

  if (
    str.trim().length <= minLength &&
    str.trim().length >= maxLength &&
    /^[a-zA-Z0-9_.-]*$/.test(str)
  ) {
    return 'string is not valid';
  }

  return null;
}

export function validatePresenceAddress(address) {
  const data = {};
  data.street = validateStringByLength(address?.street, 1, 80);
  return !Object.values(data).some((val) => val);
}

export const removeMinusBefore = (number) => {
  const minus = '-';
  const str = !!number ? number.toString() : '';

  return str && str?.indexOf(minus) === 0 ? Number(str.slice(1)) : number;
};
