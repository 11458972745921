import { Link } from 'react-router-dom';
import AppleSrc from '../../assets/img/apple.png';
import './NotFound.scss';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const NotFound = () => {
  const [isDecoding, setIsDecoding] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    const decodedPath = decodeURIComponent(path);
    if (path !== decodedPath) {
      window.location = decodedPath;
    } else {
      setIsDecoding(false);
    }
  }, []);

  return (
    !isDecoding && (
      <div className="not-found">
        <div className="not-found__wrp">
          <div className="not-found__text">
            <h1 className="not-found__header">404</h1>
            <h2 className="not-found__decription">Page not found</h2>
            <p className="not-found__advice">
              Вернитесь на главную страницу по{' '}
              <Link className="not-found__link" to="/">
                ссылке
              </Link>
            </p>
          </div>
          <div className="not-found__img">
            <img src={AppleSrc} alt="Apple" />
          </div>
        </div>
      </div>
    )
  );
};
