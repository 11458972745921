import './CourierTipsPopup.scss';
import PropTypes from 'prop-types';

function CourierTipsPopup({ orderNumber = '', link, popupHandler }) {
  const followToTipsSiteHandler = (e) => {
    popupHandler(e);
    window.open(link);
  };

  return (
    <div className="popup">
      <div className="popup__container">
        <button
          className="popup__button popup__button_close"
          id="close-popup"
          onClick={popupHandler}
        />
        <div className="popup__message-container">
          <h2 className="popup__header">
            {`Ваш заказ ${orderNumber} выполнен!`}
          </h2>
          <p className="popup__message">{`Вы можете оставить чаевые курьеру по кнопке ниже`}</p>
          <button
            className="popup__button"
            id="tip"
            onClick={followToTipsSiteHandler}
          >
            ОСТАВИТЬ ЧАЕВЫЕ
          </button>
        </div>
      </div>
    </div>
  );
}

export default CourierTipsPopup;

CourierTipsPopup.propTypes = {
  orderNumber: PropTypes.string,
  link: PropTypes.string.isRequired,
  popupHandler: PropTypes.func.isRequired,
};
