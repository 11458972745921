import { useState } from 'react';
import './Share.scss';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function Share({ link }) {
  const [share, setShare] = useState(false);
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const toggle = () => setShare(!share);

  const cartLink = `${companyInfo.visible_root_domain_link}/s/${link}`;

  function getLink() {
    navigator.clipboard.writeText(cartLink);
    window
      .getSelection()
      .selectAllChildren(document.querySelector('.share__copy-text'));
  }

  Share.handleClickOutside = () => setShare(false);

  return (
    <div className="share" title="Поделиться корзиной">
      <button className="share__button" onClick={toggle}>
        <span className="icon-share" />
      </button>
      {share && (
        <div className="share__content-wrapper" id="3">
          <div className="share__content">
            <div className="share__copy">
              <div className="share__copy-text">{cartLink}</div>
              <button
                className="share__copy-button"
                type="button"
                onClick={() => {
                  getLink();
                }}
              >
                <span className="icon-copy-file" />
              </button>
            </div>
            <div className="share__info">
              Чтобы поделиться своей корзиной, скопируйте ссылку и отправьте
              другу!
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Share.handleClickOutside,
};

Share.propTypes = {
  link: PropTypes.string,
};

export default onClickOutside(Share, clickOutsideConfig);
