export const pickTextColorBasedOnBgColor = (data) => {
  const color = data.substring(1, 7);

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const uiColors = [r / 255, g / 255, b / 255];

  const c = uiColors.map((item) =>
    item <= 0.03928 ? item / 12.92 : Math.pow((item + 0.055) / 1.055, 2.4),
  );

  const res = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

  return res > 0.479 ? '#446564' : '#FFFFFF';
};
