import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import './_MenuPage.scss';
import * as actionCreators from '../../store/actions';
import { capitalizeFirstLetter, declOfNum } from '../../util/misc';
import clsx from 'clsx';
import DishGrid from '../../reusable/DishGrid/DishGrid';
import { layoutBreakPoint } from '../../util/constants';

const useStyles = makeStyles((theme) => ({
  navRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 0,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  categoryLink: {
    marginRight: '15px',
    backgroundColor: '#cedfde',
    color: '#709594',
    padding: '10px 20px',
    height: 'fit-content',
    borderRadius: 18,
    marginBottom: '10px',

    '& > span': {
      padding: 0,
    },
  },
  menuItemName: {
    marginBottom: '10px',
    fontSize: '14px',
    minHeight: '28px',
    color: '#3d5b5a',
  },
}));

function MenuPage() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { page } = useParams();
  const categoryId = +page.split('-')[0];

  const currentCategoryInfo = useSelector(
    (state) => state.menus.currentCategoryInfo,
  );
  const needReloadDishInfo = useSelector(
    (state) => state.menus.needReloadDishInfo,
  );

  const [activeId, setActiveId] = useState(
    `#${currentCategoryInfo?.categories[0].name}`,
  );

  const isSameCategoryId = categoryId === currentCategoryInfo?.id;

  useEffect(() => {
    if (!currentCategoryInfo || !isSameCategoryId || needReloadDishInfo) {
      dispatch(actionCreators.getCurrentCategoryInfo(categoryId));
      const fullCategoryName = page.split('-').slice(1).join(' ');
      document.title = `На Парах | ${capitalizeFirstLetter(fullCategoryName)}`;
    }
  }, [
    categoryId,
    isSameCategoryId,
    needReloadDishInfo,
    currentCategoryInfo,
    dispatch,
    page,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', toggleCategory);

    return () => {
      window.removeEventListener('scroll', toggleCategory);
    };
  }, []);

  function toggleCategory() {
    const anchors = document.querySelectorAll('.categories-container');
    let flag = 1;
    for (let anchor of anchors) {
      const top = anchor.offsetTop - 250;
      const bottom = top + anchor.offsetHeight;
      const scroll =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;
      const id = anchor.getAttribute('id');
      if (
        (scroll > top && scroll < bottom) ||
        (flag === anchors.length && scroll + 270 > top)
      ) {
        setActiveId(`#${id}`);
      }
      flag++;
    }
  }

  if (!currentCategoryInfo) {
    return null;
  }

  const { categories: subCategories, name } = currentCategoryInfo;
  const multipleSubCategories = subCategories.length > 1;

  function numOfDishes() {
    let numberOfDishes = 0;
    for (let sub of subCategories) {
      numberOfDishes += sub.dishes.length;
    }
    return numberOfDishes;
  }

  function getClassName(id) {
    return clsx(classes.categoryLink, { active: id === activeId });
  }

  return (
    isSameCategoryId && (
      <>
        <div className={multipleSubCategories ? 'absolute-heading' : undefined}>
          <div className="Page-header">
            <h1 className="heading menuPage-heading">
              {capitalizeFirstLetter(name)}
            </h1>
            <div className="number-of-dishes">
              {declOfNum(numOfDishes(), ['блюдо', 'блюда', 'блюд'])}
            </div>
          </div>
          {multipleSubCategories && (
            <div className="chip-container">
              {subCategories.map(({ name }) => (
                <Chip
                  label={name}
                  key={name}
                  id={`${name}`}
                  className={getClassName(`#${name}`)}
                  disableRipple
                  onClick={() => {
                    document
                      .getElementById(`&${name}`)
                      ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                  }}
                />
              ))}
            </div>
          )}
        </div>
        <div className={`${classes.navRoot} menu-page-nav-root`}>
          {subCategories.map(({ name, dishes }) => {
            return (
              <div className="categories-container" id={name} key={name}>
                <div
                  className={
                    multipleSubCategories
                      ? 'category-title'
                      : 'category-title__none'
                  }
                >
                  {window.innerWidth >= layoutBreakPoint && name}
                  <div className="anchor" id={`&${name}`} />
                  {window.innerWidth < layoutBreakPoint && name}
                </div>
                <DishGrid dishes={dishes} />
              </div>
            );
          })}
        </div>
      </>
    )
  );
}

export default MenuPage;
