import { createSlice } from '@reduxjs/toolkit';
import { isLoggedIn, removeToken, saveCredentials } from '../../util/auth';

const initialState = {
    isAuthorized: isLoggedIn(),
    userInfo: null,
    needReloadUserData: false,
};

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        logIn: (state, { payload }) => {
            saveCredentials(payload);
            state.isAuthorized = true;
        },
        logOut: (state) => {
            removeToken();
            state.isAuthorized = false;
            state.userInfo = null;
            state.needReloadUserData = false;
        },
        setCurrentUserInfo: (state, { payload }) => {
            state.userInfo = payload;
        },
        setNeedReloadUserData: (state, { payload }) => {
            state.needReloadUserData = payload;
        },
        setCurrentUserPresence: (state, { payload }) => {
            state.userInfo.presence_state = payload;
        },
    },
});

export const {
    logIn,
    logOut,
    setCurrentUserInfo,
    setNeedReloadUserData,
    setCurrentUserPresence,
} = currentUserSlice.actions;

export default currentUserSlice.reducer
