import { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './DishIcons.scss';
import { DISH_ICONS } from '../../../util/constants';

function DishIcons({ isBig, children, dishIcons }) {
  const iconsList = useMemo(
    () =>
      dishIcons
        .filter((item) =>
          Object.prototype.hasOwnProperty.call(DISH_ICONS, item),
        )
        .map((item) => DISH_ICONS[item]),
    [dishIcons],
  );

  return (
    <div
      className={clsx('dishes-icons-bar', { 'dishes-icons-bar_big': isBig })}
    >
      {iconsList.map((item, index) => (
        <img
          key={index}
          src={item.icon}
          alt={item.description}
          title={item.description}
          className="dishes-icons-bar__img"
        />
      ))}
      {children}
    </div>
  );
}

DishIcons.propTypes = {
  isBig: PropTypes.bool,
  children: PropTypes.node,
  dishIcons: PropTypes.array,
};

export default DishIcons;
