import { useRef, useState, useEffect } from 'react';
import './LogInModal.scss';
import MaskedInput from 'react-text-mask';
import { extractPhoneNumbers, getPhoneInputLength } from '../../../util/phone';
import TextField from '@material-ui/core/TextField';
import { Link, useHistory } from 'react-router-dom';
import axios from '../../../util/axiosConfig';
import LoadingSpinner from '../../../reusable/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import { getHumanTimeToUnlock } from '../../../util/misc';
import {
  MESSAGE_TYPES,
  PHONE_MASK,
  POSSIBLE_ERRORS_BY_LOG_IN,
} from '../../../util/constants';
import { LOGIN_MESSAGES } from '../../../util/constants';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const states = {
  INITIAL: 'INITIAL',
  WAITING: 'WAITING',
  RECEIVED_SMS: 'RECEIVED_SMS',
  ERROR: 'ERROR',
  SUCCESS_LOG_IN: 'SUCCESS_LOG_IN',
  TIMEOUT: 'TIMEOUT',
};

function getErrorMessage({ fieldValue, expectedLength, shift = 0, codeError }) {
  if (codeError) {
    return LOGIN_MESSAGES.WRONG_CODE;
  }
  const preciseLength = fieldValue.length + shift;

  if (!preciseLength) {
    return LOGIN_MESSAGES.REQUIRED_FIELD;
  }

  return null;
}

const PhoneInput = ({
  value,
  onChange,
  onFocus,
  disabled,
  inputRef,
  onSelect,
}) => (
  <MaskedInput
    className="masked-input centered-input"
    mask={PHONE_MASK}
    ref={(ref) => inputRef(ref ? ref.inputElement : null)}
    keepCharPositions={false}
    value={value}
    onChange={onChange}
    showMask
    onFocus={onFocus}
    disabled={disabled}
    onSelect={onSelect}
  />
);

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

function LogInModal() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [phone, setPhone] = useState('+7');
  const [startedTyping, setStartedTyping] = useState({
    phone: false,
    code: false,
  });
  const [modalState, setModalState] = useState(states.INITIAL);
  const [code, setCode] = useState('');
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [phoneCaretPosition, setPhoneCaretPosition] = useState(4);
  const [errorResponse, setErrorResponse] = useState(null);
  const [timerTimeLeft, setTimerTimeLeft] = useState(0);
  const phoneInputRef = useRef();
  const needToLogIn = useSelector(
    (state) => state.view.modalContent?.needToLogIn,
  );
  const btnType = useSelector((state) => state.view.modalContent?.btnType);

  useEffect(() => {
    if (modalState === states.INITIAL) phoneInputRef.current.focus();
  }, [modalState]);

  function getErrorFragment(error) {
    switch (error) {
      case 'Incorrect code':
        return (
          <span className="simple-error modal-text mb-60">
            {LOGIN_MESSAGES.CODE_CONFIRM_ERROR}
          </span>
        );
      case 'Ban sms sending by ip':
      case 'Ban by client timeout':
        return (
          <>
            <span className="simple-error modal-text">{`${LOGIN_MESSAGES.DOUBLE_SEND_BAN} ${getHumanTimeToUnlock(+errorResponse?.timeout)}`}</span>
          </>
        );
      case 'Phone code sending error':
      case 'Invalid phone':
        return (
          <>
            <span className="simple-error modal-text">
              {LOGIN_MESSAGES.FILLING_ERROR}
            </span>
            <span className="simple-error modal-text mb-60">
              {LOGIN_MESSAGES.PHONE_TEMPLATE}
            </span>
          </>
        );
      default:
        return (
          <span className="simple-error modal-text mb-60">
            {LOGIN_MESSAGES.UNKNOWN_ERROR}
          </span>
        );
    }
  }

  function fixCaretPosition() {
    const { selectionEnd, selectionStart } = phoneInputRef.current;
    const newSelectionEnd = Math.min(selectionEnd, phoneCaretPosition);
    const newSelectionStart = Math.max(
      4,
      Math.min(selectionStart, newSelectionEnd),
    );
    phoneInputRef.current.setSelectionRange(newSelectionStart, newSelectionEnd);
  }

  function requestSMSCode(e, again = false) {
    if (!again) {
      e.preventDefault();
    }
    setModalState(states.WAITING);
    axios
      .post('phone_auth/send_code/', {
        phone,
        again,
      })
      .then(onRequestSMSCodeSuccess)
      .catch(onLogInError);
  }

  function onRequestSMSCodeSuccess({ data: { timeout } }) {
    setModalState(states.RECEIVED_SMS);
    timerHandler({ timeout });
    setErrorResponse(null);
  }

  function logIn(e) {
    e.preventDefault();
    setModalState(states.WAITING);
    axios
      .post('phone_auth/token/', { code, phone, policy: true })
      .then(onLogInSuccess)
      .then(() => {
        dispatch(actionCreators.getCompanyContacts());
      })
      .catch((err) => {
        onLogInError(err);
        setCodeErrorMessage(getErrorMessage({ codeError: true }));
      });
  }

  function onLogInSuccess({ data: { access_token } }) {
    setErrorResponse(null);
    dispatch(actionCreators.logIn(access_token));
    setModalState(states.SUCCESS_LOG_IN);
    setTimeout(() => {
      dispatch(actionCreators.getCurrentUserInfo(true, history));
    }, 1500);
  }

  function keyPressHandler(e) {
    e.key === 'Enter' && !errorMessage && logIn(e);
  }

  function timerHandler({ timeout }) {
    setTimerTimeLeft(timeout);

    const timer = setInterval(() => {
      setTimerTimeLeft((prev) => {
        if (prev < 2) {
          clearInterval(timer);
          setModalState(states.RECEIVED_SMS);
        }
        return --prev;
      });
    }, 1000);
    return setModalState(states.TIMEOUT);
  }

  function onLogInError(error) {
    if (POSSIBLE_ERRORS_BY_LOG_IN.includes(error.response?.data?.error)) {
      setErrorResponse(error.response.data);
      setModalState(states.ERROR);
      return;
    }

    if (error.response?.data?.timeout > 0) {
      timerHandler({ timeout: error.response?.data?.timeout });
      return;
    }

    if (error.response?.data?.description?.includes(LOGIN_MESSAGES.CANT_FIND)) {
      setModalState(states.RECEIVED_SMS);
      return;
    }

    dispatch(actionCreators.clearModal());
    dispatch(actionCreators.setIsLoadingFailed(true));
    dispatch(
      actionCreators.setMessage({
        messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
      }),
    );
  }

  let errorMessage;
  switch (modalState) {
    case states.WAITING:
      return <LoadingSpinner wholePage={false} />;

    case states.INITIAL:
    case states.ERROR:
      errorMessage = getErrorMessage({
        fieldValue: phone,
        expectedLength: 10,
        shift: -2,
      });
      return (
        <form
          noValidate
          autoComplete="false"
          onSubmit={(e) => requestSMSCode(e)}
        >
          <h2 className="log-in-modal-title">{LOGIN_MESSAGES.LOGIN}</h2>
          <div className="input-container phone-input-active">
            <TextField
              value={phone}
              inputRef={phoneInputRef}
              onChange={({ target: { value } }) => {
                !startedTyping.phone &&
                  setStartedTyping((currState) => ({
                    ...currState,
                    phone: true,
                  }));
                setPhoneCaretPosition(getPhoneInputLength(value));
                setPhone(extractPhoneNumbers(value));
                if (modalState !== states.INITIAL) {
                  setModalState(states.INITIAL);
                }
              }}
              InputProps={{
                inputComponent: PhoneInput,
                fullWidth: true,
              }}
              fullWidth
              onSelect={fixCaretPosition}
              error={startedTyping.phone && !!errorMessage}
              helperText={startedTyping.phone ? errorMessage : null}
            />
          </div>
          {modalState === states.ERROR &&
            getErrorFragment(errorResponse?.error)}
          {modalState === states.INITIAL && btnType === 'addToBasket' && (
            <div className="login-form__text">
              {LOGIN_MESSAGES.TEL_AND_CODE_REQUEST}
            </div>
          )}
          {modalState === states.INITIAL && needToLogIn && (
            <div className="login-form__text">
              {LOGIN_MESSAGES.NEED_TO_LOGIN}
            </div>
          )}
          <div className="accept-terms">
            При входе вы{' '}
            <Link
              className="green-link"
              to="/соглашение"
              onClick={() => dispatch(actionCreators.clearModal())}
            >
              соглашаетесь с условиями
            </Link>
          </div>
          <button
            className="button-standard log-in-modal-btn"
            disabled={
              !!errorMessage || modalState === states.ERROR || phone.length < 12
            }
            type="submit"
          >
            {LOGIN_MESSAGES.SEND_SMS}
          </button>
        </form>
      );

    case states.TIMEOUT:
    case states.RECEIVED_SMS:
    case states.SUCCESS_LOG_IN:
      errorMessage = getErrorMessage({ fieldValue: code, expectedLength: 4 });

      let bottomContent;
      if (modalState === states.SUCCESS_LOG_IN) {
        bottomContent = (
          <span className="successful-auth">
            {LOGIN_MESSAGES.AUTORIZATION_SUCCESS}
          </span>
        );
      } else {
        bottomContent = (
          <>
            <div className="log-in-resend-wrapper">
              {modalState === states.RECEIVED_SMS && (
                <button
                  className="green-link empty-button empty-button_place_login"
                  onClick={(e) => {
                    setCode('');
                    setStartedTyping((currState) => ({
                      ...currState,
                      code: false,
                    }));
                    requestSMSCode(e, true);
                    setCodeErrorMessage('');
                  }}
                >
                  {LOGIN_MESSAGES.REPEAT_CODE}
                </button>
              )}
              {modalState === states.TIMEOUT && (
                <p className="log-in-timer">{`Повторно отправить код можно будет через ${format(timerTimeLeft * 1000, 'mm:ss')}`}</p>
              )}
            </div>
            <button
              className="button-standard log-in-modal-btn"
              disabled={!!errorMessage || code.length < 4}
              type="submit"
            >
              {LOGIN_MESSAGES.ENTER}
            </button>
          </>
        );
      }

      return (
        <form
          noValidate
          autoComplete="false"
          onSubmit={logIn}
          onKeyPress={keyPressHandler}
          className="pos-relative"
        >
          <button
            className="green-link empty-button back-button simple-flex"
            onClick={() => {
              setModalState(states.INITIAL);
              setStartedTyping((currState) => ({ ...currState, code: false }));
            }}
          >
            <span className="icon-back" />
            {LOGIN_MESSAGES.BACK}
          </button>
          <h2 className="log-in-modal-title keep-margin">
            {LOGIN_MESSAGES.LOGIN}
          </h2>
          <div className="input-container modal-text">
            <TextField
              value={phone}
              InputProps={{
                inputComponent: PhoneInput,
                fullWidth: true,
              }}
              fullWidth
              disabled
            />
          </div>
          <div className="input-container modal-text">
            <TextField
              value={code}
              autoFocus
              onChange={({ target: { value } }) => {
                setCodeErrorMessage('');
                !startedTyping.code &&
                  setStartedTyping((currState) => ({
                    ...currState,
                    code: true,
                  }));
                setCode(value.replace(/\D/g, '').substr(0, 4));
                if (modalState !== states.RECEIVED_SMS && timerTimeLeft === 0) {
                  setModalState(states.RECEIVED_SMS);
                }
              }}
              InputProps={{
                fullWidth: true,
                classes: {
                  input: 'centered-input',
                },
              }}
              disabled={modalState === states.SUCCESS_LOG_IN}
              fullWidth
              placeholder="СМС-пароль"
              error={(startedTyping.code && !!errorMessage) || codeErrorMessage}
              helperText={
                codeErrorMessage
                  ? codeErrorMessage
                  : startedTyping.code
                    ? errorMessage
                    : null
              }
            />
          </div>
          {bottomContent}
        </form>
      );
    default:
      return null;
  }
}

export default LogInModal;
