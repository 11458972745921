import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { NotFound } from '../../pages/NotFound/NotFound';
import * as actionCreators from '../../store/actions';
import { useEffect } from 'react';
import {
  MODAL_TYPES,
  PATHS,
  authNotRequired,
  authRequired,
} from '../../util/constants';
import { checkPathname, pathIsAvailable } from '../../util/misc';
import { useScrollToTopOnRedirect } from '../../reusable/hooks/useScrollToTopOnRedirect';

export default function Routes() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const pageIsLoading = useSelector(
    (state) => state.apiConnection.pageIsLoading,
  );

  const isAvailablePath = pathIsAvailable(pathname, isAuthorized);
  const isCorrectPath = checkPathname(pathname);
  const routes = [...authNotRequired, ...authRequired];

  useScrollToTopOnRedirect();

  useEffect(() => {
    if (isCorrectPath && !isAvailablePath) {
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.LOG_IN,
          needToLogIn: true,
          redirectPath: PATHS.index,
        }),
      );
    }
  }, [isCorrectPath, isAvailablePath, dispatch]);

  if (pageIsLoading) {
    return null;
  }

  return (
    <Switch>
      {routes.map((page, index) => (
        <Route {...page} key={index} isAvailable={isAvailablePath} />
      ))}
      <Route path="/*" component={NotFound} />
    </Switch>
  );
}
