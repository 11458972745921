import PropTypes from 'prop-types';
import './ClickOutsideButton.scss';
import OutsideClicker from '../../reusable/outsideClicker';

function ClickOutsideButton({
  addressField,
  fieldData,
  fieldFunction,
  setFieldValue,
  isVisible,
  setIsVisible,
}) {
  function togglePopper() {
    setIsVisible(!isVisible);
  }

  const fieldName = addressField ? 'addresses' : 'comments';
  const tooltipText = addressField ? 'адресов' : 'комментариев';

  const getReadableStreet = (address) => {
    const flat = address.flat ? `, кв. ${address.flat}` : '';
    const corp = address.corp ? `, корп. ${address.corp}` : '';
    const shortAddress = `${address.street} ${address.house}${flat}${corp}`;

    return shortAddress;
  };

  function getReadableDisplay(field) {
    return addressField
      ? getReadableStreet(field)
      : field.length < 25
        ? field
        : field.substring(0, 25) + '...';
  }

  return (
    <OutsideClicker setIsPopperVisible={setIsVisible}>
      <div>
        <button
          className={`place-order__previous-${fieldName}`}
          type="button"
          onClick={togglePopper}
        >
          <span className={`place-order__last-${fieldName}-icon`} />
        </button>
        {isVisible && (
          <div className="tooltip">
            <div className="tooltip__inner">
              <div className="tooltip__text-wrapper">
                <div className="tooltip__text">
                  {`список последних ${tooltipText}`}
                </div>
                {fieldData.length ? (
                  fieldData.map((field, idx) => (
                    <div
                      className="tooltip__text pointer"
                      key={idx}
                      onClick={() =>
                        fieldFunction(field, setIsVisible, setFieldValue)
                      }
                    >
                      <span className="green-link">
                        {getReadableDisplay(field)}
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="tooltip__text">
                    {`У вас нет сохраненных ${tooltipText}`}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClicker>
  );
}

ClickOutsideButton.propTypes = {
  addressField: PropTypes.bool.isRequired,
  fieldData: PropTypes.array.isRequired,
  fieldFunction: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
};

export default ClickOutsideButton;
