import './Breadcrumbs.scss';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../util/misc';
import { useSelector } from 'react-redux';

export function Breadcrumbs() {
  const breadcrumbs = useSelector((state) => state.view.breadcrumbs);
  if (!breadcrumbs) return null;

  const pathList = breadcrumbs.path.split('/');
  const nameList = breadcrumbs.name.split('/');
  const currentName = nameList.pop();

  return (
    <div className={breadcrumbs.container}>
      <div className="breadcrumbs">
        {nameList.map(
          (name, index) =>
            name.length > 0 && (
              <span key={index + name}>
                <Link className="green-link" to={`/${pathList[index]}`}>
                  {capitalizeFirstLetter(name)}
                </Link>

                <span className="breadcrumbs-sep">-</span>
              </span>
            ),
        )}
        <span>{capitalizeFirstLetter(currentName)}</span>
      </div>
    </div>
  );
}
