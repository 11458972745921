import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function LoadingSpinner({ size, thickness, wholePage, needAbsolutePosition }) {
  return (
    <div
      className={clsx(
        'simple-flex',
        'spinner-wrapper',
        { 'full-height': wholePage },
        { 'spinner-wrapper-absolute': needAbsolutePosition },
      )}
    >
      <CircularProgress size={size} thickness={thickness} />
    </div>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  wholePage: PropTypes.bool,
  needAbsolutePosition: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  size: 80,
  thickness: 4.5,
  wholePage: true,
  needAbsolutePosition: false,
};

export default LoadingSpinner;
