import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { declOfNum } from '../../util/misc';
import { Link, useHistory } from 'react-router-dom';
import SearchBar from './SearchBar';
import clsx from 'clsx';
import * as actionCreators from '../../store/actions';
import { CART_ICON_ID, MODAL_TYPES } from '../../util/constants';
import { usePopperControl } from '../../reusable/hooks/usePopperControl';

const popperItems = [
  { title: 'Профиль', to: '/профиль' },
  { title: 'Заказы', to: '/заказы' },
  { title: 'Выход' },
];

function ToolbarRight({ searching, setSearching }) {
  const { openPopper, popperTop, popperAnchor, closePopper } =
    usePopperControl();

  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);
  const userCart = useSelector((state) => state.userCart.userCart);
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const userName = useSelector(
    (state) => state.currentUser.userInfo?.first_name,
  );

  const history = useHistory();

  const dispatch = useDispatch();

  function onUserBtnClick(e) {
    if (isAuthorized) {
      openPopper(e.currentTarget);
    } else {
      dispatch(
        actionCreators.setModalContent({ modalType: MODAL_TYPES.LOG_IN }),
      );
    }
  }

  function onCartClick() {
    if (isAuthorized) {
      history.push('/корзина');
    } else {
      dispatch(
        actionCreators.setModalContent({ modalType: MODAL_TYPES.LOG_IN }),
      );
    }
  }

  function onPopperItemClick(isSignOut) {
    if (isSignOut) {
      dispatch(
        actionCreators.setModalContent({ modalType: MODAL_TYPES.LOG_OUT }),
      );
    }
    closePopper();
  }

  function getCartCount() {
    return userCart && userCart.reduce((prev, dish) => prev + dish.quantity, 0);
  }

  const headerCounter = getCartCount();

  return (
    <div
      className={clsx('simple-flex', 'header-content-right', {
        full: searching,
      })}
    >
      <SearchBar setSearching={setSearching} searching={searching} />
      {screenIsLarge && (
        <>
          <div
            className="simple-flex user-container"
            onClick={onUserBtnClick}
            title="Профиль"
          >
            <span>
              <span className="icon-user" />
            </span>
            {isAuthorized ? (
              <span className="simple-flex">
                <span className="user-popper-header">
                  {userName || 'Гость'}
                </span>
                <span className="icon-arrow__dropdown" />
              </span>
            ) : (
              <span className="user-popper-header"> Вход </span>
            )}
          </div>
          <Menu
            open={!!popperAnchor}
            anchorEl={popperAnchor}
            disableScrollLock={true}
            onClose={closePopper}
            PaperProps={{ square: true, style: { minWidth: 200 } }}
            PopoverClasses={{ root: 'user-menu-popover' }}
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: popperTop,
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {popperItems.map(({ to, title }, idx) => {
              const itemProps = {
                onClick: () => onPopperItemClick(!to),
                disableRipple: true,
              };
              if (to) {
                itemProps.component = Link;
                itemProps.to = to;
              }
              return (
                <MenuItem key={idx} {...itemProps}>
                  {title}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
      {(screenIsLarge || !searching) && (
        <div
          className="header-cart__container"
          onClick={onCartClick}
          id={CART_ICON_ID}
          title="Корзина"
        >
          <span style={{ cursor: 'pointer' }}>
            <span className="icon-cart" />
          </span>
          {!!userCart?.length && isAuthorized && (
            <>
              <span className="header__cart-amount">
                {declOfNum(headerCounter, ['блюдо', 'блюда', 'блюд'])}
              </span>
              <div className="mobile__cart-amount">{headerCounter}</div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

ToolbarRight.propTypes = {
  setSearching: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
};

export default ToolbarRight;
