const ADDRESS_REPLACEMENTS = [
  ['пр-кт', 'проспект'],
  ['пер', 'переулок'],
  ['ул', 'улица'],
  ['б-р', 'бульвар'],
  ['наб', 'набережная'],
  ['пл', 'площадь'],
  ['ал', 'аллея'],
];

export const addressConverter = (address) => {
  let streetSplit = address.street.split(' ');
  let isReplaced = false;

  for (const [short, long] of ADDRESS_REPLACEMENTS) {
    if (streetSplit[0] === short) {
      streetSplit = streetSplit.slice(1);
      isReplaced = true;
    } else if (streetSplit[streetSplit.length - 1] === short) {
      streetSplit = streetSplit.slice(0, -1);
      isReplaced = true;
    }

    if (isReplaced) {
      streetSplit = [long, ...streetSplit];
      break;
    }
  }

  if (isReplaced) {
    return {
      ...address,
      street: streetSplit.join(' '),
    };
  }

  return address;
};
