import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { MESSAGE_TYPES } from '../../util/constants';
import { setSearchResults } from '../searchResults/searchResults';
import { setMessage } from './view';
import { setIsDataLoading, setIsLoadingFailed } from '../apiConnection/apiConnection';

export const getSearchResults = (query) => {
  return (dispatch) => {
    dispatch(setIsDataLoading(true));
    return axios
      .get('dishes/', { params: { q: query }, headers: tokenHeader() })
      .then((response) => {
        dispatch(
          setSearchResults({ searchResult: response.data, isEmpty: response.data.length === 0, query: query }),
        );
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };
};
