import './ItemTag.scss';
import { pickTextColorBasedOnBgColor } from '../../util/colorPicker';
import PropTypes from 'prop-types';

export const ItemTag = ({ title, color }) => {
  const textColor = pickTextColorBasedOnBgColor(color);

  const style = {
    backgroundColor: color,
    color: textColor,
  };

  return (
    <h3 className="tag" style={style}>
      {title}
    </h3>
  );
};

ItemTag.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};
