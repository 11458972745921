import './HowToGetPoints.scss';
import { useBreadcrumbs } from '../../reusable/hooks/useBreadcrumbs';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function HowToGetPoints() {
  useTitleUpdate('Как получить баллы');
  useBreadcrumbs('section-wrapper-medium', '/профиль/как получить баллы');

  return (
    <section className="section-wrapper-medium">
      <h1 className="heading get-points-heading">как получить баллы?</h1>

      <ul className="get-points-list paper-medium">
        <li className="get-points-item">
          <span className="get-points-number">1</span>

          <p className="get-points-desc">
            Баллы могут быть начислены и использованы только при совершении
            заказа на сайте заказ.напарах.рф или в мобильном приложении «На
            Парах». При заказе в ресторане или по телефону начисление и
            использование баллов не происходит.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">2</span>

          <p className="get-points-desc">
            При регистрации на сайте заказ.напарах.рф или в мобильном приложении
            «На Парах» начисляется 1000 баллов, которые можно потратить уже на
            первый заказ.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">3</span>

          <p className="get-points-desc">1 Балл = 1 Рубль.</p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">4</span>

          <p className="get-points-desc">
            С каждого совершенного заказа начисляется 5% от стоимости заказа в
            баллах.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">5</span>

          <p className="get-points-desc">
            Зачисление баллов производится в течение 24 часов с момента
            совершения заказа. Если баллы не были зачислены, пожалуйста,
            напишите в поддержку @naparahrest (Telegram-чат) или на почту
            smm@naparah.ru для решения ситуации.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">6</span>

          <p className="get-points-desc">
            Баллами можно оплатить до 15% стоимости заказа.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">7</span>

          <p className="get-points-desc">
            Баллы начисляются за каждый совершенный заказ.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">8</span>

          <p className="get-points-desc">
            В случае отмены заказа баллы возвращаются на счет пользователя.
          </p>
        </li>

        <li className="get-points-item">
          <span className="get-points-number">9</span>

          <p className="get-points-desc">
            {' '}
            Количество начисленных баллов отображается в профиле пользователя.
          </p>
        </li>
      </ul>
    </section>
  );
}

export default HowToGetPoints;
