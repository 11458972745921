import { createSelector } from 'reselect';

export const selectRestaurant = createSelector(
  [
    (state) => state.restaurants.restaurantsInfo,
    (_, restaurantId) => restaurantId,
  ],
  (restaurantsInfo, restaurantId) =>
    restaurantId &&
    restaurantsInfo.find((restaurant) => restaurant.id === restaurantId),
);
