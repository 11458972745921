import { useEffect } from 'react';
import './Restaurant.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import YandexMap from '../../components/YandexMap/YandexMap';
import { useBreadcrumbs } from '../../reusable/hooks/useBreadcrumbs';
import Carousel from 'react-material-ui-carousel';
import { Box, CardMedia } from '@material-ui/core';
import { getLastPageAddress } from '../../util/misc';
import CarouselControlIcon from '../../reusable/CarouselControlIcon/CarouselControlIcon';

const CAROUSEL_SETTINGS = {
  animation: 'slide',
  autoPlay: false,
  swipe: true,
  timeout: 600,
  indicators: false,
  navButtonsAlwaysVisible: true,
  navButtonsProps: {
    style: {
      backgroundColor: 'unset',
    },
  },
};

const getLastPageName = (path) => {
  switch (path) {
    case 'условия-доставки':
      return 'условия доставки';
    case 'проверка-доставки':
      return 'проверка доставки';
    case 'контакты':
      return 'контакты';
    default:
      return 'контакты';
  }
};

const Restaurant = () => {
  const { id } = useParams();
  const restaurantsInfo = useSelector(
    (state) => state.restaurants.restaurantsInfo,
  );
  const restaurantInfo = useSelector(
    (state) => state.restaurants.currentRestaurantInfo,
  );
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const dispatch = useDispatch();
  const lastPageAddress = getLastPageAddress();
  const lastPageName = getLastPageName(lastPageAddress);
  const name = restaurantInfo?.name || '';
  useBreadcrumbs(
    'inner-container inner-container--xs',
    `/${lastPageName}/${name}`,
    `/${lastPageAddress}`,
  );
  useEffect(() => {
    if (!restaurantInfo) {
      dispatch(actionCreators.getRestaurant(id, restaurantsInfo));
    }
  }, []);

  const getMetroStationClass = (metroStation) => {
    if (!metroStation || !metroStation.line_hex_color) {
      return 'icon-metro-default';
    }
    switch (metroStation.line_hex_color) {
      case 'D6083B':
        return 'icon-metro-red';
      case '702785':
        return 'icon-metro-purple';
      case '0078C9':
        return 'icon-metro-blue';
      case '009A49':
        return 'icon-metro-green';
      case 'EA7125':
        return 'icon-metro-orange';
      default:
        return 'icon-metro-default';
    }
  };

  if (!restaurantInfo) {
    return null;
  }

  return (
    <div className="inner-container inner-container--xs">
      <div className="restaurant">
        <div className="restaurant__content">
          <div className="restaurant__contacts">
            <div className="address restaurant__address">
              <div className="address__icon">
                <span
                  className={getMetroStationClass(
                    restaurantInfo.metro_stations[0],
                  )}
                />
              </div>
              <div className="address__title">
                {restaurantInfo.metro_stations[0].name}
              </div>
              <div className="address__text">{restaurantInfo.address}</div>
            </div>
            <a
              href={`tel:+${companyInfo.single_phone}`}
              className="restaurant__phone address"
            >
              <div className="address__icon">
                <span className="icon-phone" />
              </div>
              <div className="address__title">{companyInfo.single_phone}</div>
              {restaurantInfo.phone_ext && (
                <div className="address__text">
                  Добавочный номер: {restaurantInfo.phone_ext}
                </div>
              )}
            </a>
          </div>
          <div className="restaurant__map ">
            <YandexMap restaurants={[restaurantInfo]} />
            {restaurantInfo.warning && (
              <Box
                display="flex"
                justifyContent="flex-end"
                fontStyle="italic"
                fontSize=".8571rem !important"
                color="#709594"
              >
                <span className="note__desc">{restaurantInfo.warning}</span>
                <span className="note__icon">*</span>
              </Box>
            )}
          </div>
          <div className="restaurant__text">Фотографии ресторана</div>
        </div>
        {restaurantInfo.images.length === 1 && (
          <div className="restaurant__slider">
            <div
              className="restaurant__slider-img"
              style={{ backgroundImage: `url(${restaurantInfo.images[0]})` }}
            />
          </div>
        )}

        {restaurantInfo.images.length > 1 && (
          <Carousel
            className="restaurant__slider"
            NextIcon={CarouselControlIcon('Вперед')}
            PrevIcon={CarouselControlIcon('Назад')}
            {...CAROUSEL_SETTINGS}
          >
            {restaurantInfo.images.map((image, index) => (
              <CardMedia
                className="restaurant__slider-img"
                key={index}
                image={image}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default Restaurant;
