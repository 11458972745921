import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import defaultSrc from '../../assets/img/no-dish.png';

export function OfferCard({ card, view }) {
  return (
    <li className={`offer-card offer-card-${view}`}>
      {view === 'simple' ? (
        <Link
          className="offer-card-img with-default-bgi"
          to={`/акции/${card.id}`}
          style={{ backgroundImage: `url(${card.image}), url(${defaultSrc})` }}
        />
      ) : (
        <span
          className="offer-card-img with-default-bgi"
          style={{ backgroundImage: `url(${card.image}), url(${defaultSrc})` }}
        />
      )}
      <div className="offer-card-content">
        {view === 'simple' ? (
          <Link to={`/акции/${card.id}`}>
            <h2 className="heading offer-card-title">{card.title}</h2>
          </Link>
        ) : (
          <h2 className="heading offer-card-title">{card.title}</h2>
        )}
        <p className={`offer-card-desc card-desc-${view}`}>
          {card.description}
        </p>

        {view === 'simple' && (
          <Link className="green-link offer-card-link" to={`/акции/${card.id}`}>
            Подробнее
          </Link>
        )}
      </div>
    </li>
  );
}

OfferCard.propTypes = {
  card: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
};
