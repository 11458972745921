import { useEffect } from 'react';
import './SpecialOffers.scss';
import * as actionCreators from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import EmptyPage from '../../reusable/EmptyPage/EmptyPage';
import { OfferCard } from './OfferCard';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function OffersList() {
  useTitleUpdate('Акции');
  const specialOffersList = useSelector((state) => state.menus.specialOffers);
  const offersListPresent = !!specialOffersList;
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!offersListPresent && !isLoadingFailed) {
      dispatch(actionCreators.getSpecialOffers());
    }
  }, [offersListPresent, isLoadingFailed, dispatch]);

  return (
    <section className="special-offers-wrapper">
      <h1 className="heading special-offers-heading">акции</h1>
      {specialOffersList?.length > 0 ? (
        <ul className="special-offers-list">
          {specialOffersList.map((specialOffer) => (
            <OfferCard
              card={specialOffer}
              view="simple"
              key={specialOffer.id}
            />
          ))}
        </ul>
      ) : (
        <EmptyPage topText="Акций сейчас нет" />
      )}
    </section>
  );
}

export default OffersList;
