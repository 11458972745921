import axios from '../../util/axiosConfig';
import { tokenHeader } from '../../util/auth';
import { MESSAGE_TYPES, PATHS } from '../../util/constants';
import { setCompanyInfo, setCompanyRequisites, setCompanyContacts } from '../companyInfo/companyInfo';
import { setRestaurantsInfo } from '../restaurants/restaurants';
import { setIsDataLoading, setIsLoadingFailed } from '../apiConnection/apiConnection';
import { setMessage } from './view';

export const getCompanyContacts = () => {
  return (dispatch) => {
    dispatch(setIsDataLoading(true));
    return axios
      .get('restaurants/', { headers: tokenHeader() })
      .then((response) => {
        if (response.data.length) {
          dispatch(setCompanyContacts(response.data));
          dispatch(setRestaurantsInfo(response.data));
        } else {
          dispatch(
            setMessage({
              messageStatus: MESSAGE_TYPES.WARNING,
              message: 'Извините, список адресов пуст',
              timeOutToClose: 5000,
              redirectPath: PATHS.index,
            }),
          );
        }
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };
};

export const getCompanyInfo = () => {
  return (dispatch) =>
    axios
      .get('company/current/', { headers: tokenHeader() })
      .then((response) => {
        dispatch(setCompanyInfo(response.data));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
};

export const getCompanyRequisites = () => {
  return (dispatch) => {
    axios
      .get('/company/current/')
      .then((response) => {
        dispatch(setCompanyRequisites(response.data.payment_details));
      })
      .catch(() => {
        dispatch(setIsLoadingFailed(true));
        dispatch(
          setMessage({ messageStatus: MESSAGE_TYPES.ERROR }),
        );
      });
  };
};
