import './Sidebar.scss';
import { useSelector } from 'react-redux';

function SparseDivider() {
  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);

  return (
    <div
      className={`SparseDivider-${screenIsLarge ? 'largeScreen' : 'smallScreen'}`}
    >
      <span />
    </div>
  );
}

export default SparseDivider;
