import axios from './axiosConfig';
import debounce from 'lodash/debounce';
import { DEFAULT_ADDRESS, MESSAGE_TYPES } from './constants';
import { setCookie } from './cookies';
import * as actionCreators from '../store/actions';
import { tokenHeader } from './auth';
import { getMessageByAddressesCheck } from './map';
import { addressConverter } from './address';

function getSuggestions(query, setSuggestions, dispatch) {
  dispatch(actionCreators.setIsDataLoading(true));
  axios
    .get('dishes/', { params: { q: query }, headers: tokenHeader() })
    .then((response) => onGetSuggestionsSuccess(response, setSuggestions))
    .catch(onGetSuggestionsError)
    .finally(() => dispatch(actionCreators.setIsDataLoading(false)));
}

function onGetSuggestionsSuccess({ data }, setSuggestions) {
  setSuggestions(data);
}

function onGetSuggestionsError(error) {
  // TODO: Handle errors
  console.log(error, error.response);
}

export const getSuggestionsDebounced = debounce(
  (query, setSuggestions, dispatch) =>
    getSuggestions(query, setSuggestions, dispatch),
  1000,
);

function getSuggestionsStreet(query, setSuggestionsStreet) {
  return (dispatch) =>
    axios
      .get('/geo_suggestions/suggest_full_addresses/', {
        params: { q: query },
      })
      .then((response) => {
        onGetSuggestionsStreetSuccess(response, setSuggestionsStreet);
      })
      .catch(() => {
        dispatch(actionCreators.setIsLoadingFailed(true));
        dispatch(
          actionCreators.setMessage({
            messageStatus: MESSAGE_TYPES.TRY_LATER_ERROR,
          }),
        );
      });
}

function onGetSuggestionsStreetSuccess({ data }, setSuggestionsStreet) {
  setSuggestionsStreet(data?.map((address) => addressConverter(address)) || []);
}

export const getSuggestionsStreetDebounced = debounce(
  (query, setSuggestions, dispatch) =>
    dispatch(getSuggestionsStreet(query, setSuggestions)),
  300,
);

export function getDeliveryStatus(
  data,
  setDeliveryStatus,
  setClientCoordinate,
  dispatch,
) {
  axios
    .get('client/can_deliver_by_address/', { params: data.address })
    .then((response) => {
      onGetDeliveryStatusSuccess(
        response,
        setDeliveryStatus,
        setClientCoordinate,
      );
      dispatch(
        actionCreators.setDeliveryTimes(response.data.delivery_times || []),
      );
    })
    .catch((error) => onGetDeliveryStatusError(error, setDeliveryStatus));
}

function onGetDeliveryStatusSuccess(
  { data },
  setDeliveryStatus,
  setClientCoordinate,
) {
  setClientCoordinate({
    lat: data.point.coordinates[1],
    lon: data.point.coordinates[0],
  });
  const isPositive = !!data.result;
  setDeliveryStatus({
    message: getMessageByAddressesCheck(data),
    isPositive,
    restaurant: data.restaurant_id,
    price: data.price,
    delivery_times: data.delivery_times,
  });
}

function onGetDeliveryStatusError({ error }, setDeliveryStatus) {
  setDeliveryStatus({
    message: 'Не удалось проверить возможность доставки по вашему адресу',
    isPositive: false,
  });
}

export const getDeliveryStatusDebounced = debounce(
  (data, setDeliveryStatus, setClientCoordinate, dispatch) =>
    getDeliveryStatus(data, setDeliveryStatus, setClientCoordinate, dispatch),
  300,
);

export function getClientAddress(
  data,
  setAddress,
  setDeliveryStatus,
  setError,
  needToUpdateCookie = false,
) {
  return (dispatch) =>
    axios
      .get('geo_suggestions/get_address_by_point/', {
        params: { lat: data.lat, lon: data.lon },
      })
      .then((response) => {
        setError((prev) => ({ ...prev, geo: null }));
        onGetClientAddressSuccess(
          response,
          setAddress,
          setDeliveryStatus,
          needToUpdateCookie,
        );
      })
      .catch((error) => {
        const message =
          error?.response?.data?.detail ||
          'Извините, не удалось определить ваше местоположение';

        setError((prev) => ({
          ...prev,
          geo: message,
          house: null,
          street: null,
        }));
      });
}

function onGetClientAddressSuccess(
  { data },
  setAddress,
  setDeliveryStatus,
  needToUpdateCookie,
) {
  const address = {
    ...DEFAULT_ADDRESS,
    city: data.city || data.settlement,
    settlement: data.settlement,
    street: data.street,
    house: data.house,
    corp: data.corp,
    suggested: data.suggested_address,
  };
  needToUpdateCookie && setCookie('savedAddress', address, 1);
  setAddress(addressConverter(address));
  if (!address.street && !address.house) {
    setDeliveryStatus({
      message: 'Извините, не удалось определить ваше местоположение',
      isPositive: false,
    });
  } else if (!address.street) {
    setDeliveryStatus({ message: 'Введите адрес', isPositive: false });
  } else if (!address.house) {
    setDeliveryStatus({ message: 'Введите номер дома', isPositive: false });
  }
}
