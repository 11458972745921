import icon_info from '../../../assets/icons/info-button.svg';
import './Tips.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function Tips({ onStop, inRest, bigCard }) {
  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);

  const isOnStop = onStop
    ? 'В данный момент блюдо недоступно по указанному адресу'
    : null;
  const isOnlyInRest = inRest
    ? 'В данный момент блюдо доступно только в ресторанах и для самовывоза'
    : null;
  const tipsArr = [isOnStop, isOnlyInRest];
  const title = tipsArr.filter((tip) => !!tip).join('\n\n');

  return (
    <div
      className={clsx('product-card__tooltip-icon', {
        'product-card__tooltip-icon_big': bigCard,
      })}
    >
      <Tooltip
        placement={
          bigCard ? 'left-start' : screenIsLarge ? 'top-start' : 'left-start'
        }
        title={title}
        classes={{
          tooltip: 'tooltip-info__text',
        }}
        enterTouchDelay={0}
        leaveTouchDelay={1500}
      >
        <img src={icon_info} alt="tipIcon" className="tooltip-icons__img" />
      </Tooltip>
    </div>
  );
}

Tips.propTypes = {
  onStop: PropTypes.bool,
  inRest: PropTypes.bool,
  bigCard: PropTypes.bool,
};

export default Tips;
