import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import './DeleteModal.scss';

function DeleteModal() {
  const dispatch = useDispatch();

  const userCart = useSelector((state) => state.userCart.userCart);
  const dishId = useSelector((state) => state.view.modalContent.dishId);

  return (
    <>
      <div className="modal__title">действительно удалить?</div>
      <div className="modal-action-form__text">
        Подтверждение удаления блюда
      </div>
      <div className="buttons-wrapper">
        <button
          className="button-standard buttons-wrapper__button"
          onClick={() => dispatch(actionCreators.clearModal())}
        >
          отмена
        </button>
        <button
          className="button-standard buttons-wrapper__button"
          onClick={() => {
            dispatch(actionCreators.deleteFromCart(dishId, userCart));
            dispatch(actionCreators.clearModal());
          }}
        >
          удалить
        </button>
      </div>
    </>
  );
}

export default DeleteModal;
