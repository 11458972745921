import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orders: [],
    next: undefined,
    deliveryTimes: [],
    restorantPickupTimes: [],
    lastComments: [],
    lastAddresses: [],
    paymentTypes: [],
    presences: [],
    promocodeAdded: false,
    discount: null,
    surveyQuestions: [],
    restaurantId: null,
    discountAmount: null,
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setRestaurantId: (state, { payload: restaurantId }) => {
            state.restaurantId = restaurantId;
        },
        setDeliveryInfo: (state, {
            payload: { last_comments, last_addresses, payment_types, presences }
        }) => {
            state.lastComments = last_comments;
            state.lastAddresses = last_addresses;
            state.paymentTypes = payment_types;
            state.presences = presences;
        },
        setDeliveryTimes: (state, { payload: deliveryTimes }) => {
            state.deliveryTimes = deliveryTimes;
        },
        setRestorantPickupTime: (state, { payload: restorantPickupTimes }) => {
            state.restorantPickupTimes = restorantPickupTimes;
        },
        setPromocodeStatus: (state, { payload: promocodeAdded }) => {
            state.promocodeAdded = promocodeAdded;
        },
        setInitialOrders: (state, { payload: { orders, next } }) => {
            state.orders = orders;
            state.next = next;
        },
        addOrders: (state, { payload: { orders, next } }) => {
            state.orders.push(...orders)
            state.next = next;
        },
        clearOrders: (state) => {
            state.orders = [];
            state.next = undefined;
        },
        promocodeAdded: (state, { payload: discount }) => {
            state.promocodeAdded = true;
            state.discount = discount;
        },
        setInitialState: (state) => {
            return initialState;
        },
        setSurveyQuestions: (state, { payload: surveyQuestions }) => {
            state.surveyQuestions = surveyQuestions;
        },
        setDiscountAmount: (state, { payload: discountAmount }) => {
            state.discountAmount = discountAmount;
            state.promocodeAdded = true;
        },
    },
});

export const {
    setRestaurantId,
    setDeliveryInfo,
    setDeliveryTimes,
    setRestorantPickupTime,
    setPromocodeStatus,
    setInitialOrders,
    addOrders,
    clearOrders,
    promocodeAdded,
    setInitialState,
    setSurveyQuestions,
    setDiscountAmount,
} = ordersSlice.actions;

export default ordersSlice.reducer;
