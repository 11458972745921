import { useCallback, useState } from 'react';

export function usePopperControl({ initial = 0, offset = -9 } = {}) {
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [popperTop, setPopperTop] = useState(initial);

  const openPopper = useCallback(
    (target) => {
      setPopperAnchor(target);
      setPopperTop(target.getBoundingClientRect().bottom + offset);
    },
    [setPopperAnchor, setPopperTop, offset],
  );

  const closePopper = useCallback(
    () => setPopperAnchor(null),
    [setPopperAnchor],
  );

  return { openPopper, closePopper, popperAnchor, popperTop };
}
