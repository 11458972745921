import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import './Contacts.scss';
import * as actionCreators from '../../store/actions';
import { getPhoneParts } from '../../util/phone';
import { days } from '../../util/dates';
import YandexMap from '../../components/YandexMap/YandexMap';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function Contacts() {
  useTitleUpdate('Контакты');

  const dispatch = useDispatch();

  const companyInfo = useSelector((state) => state.companyInfo.info);
  const contacts = useSelector((state) => state.companyInfo.contacts);
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const contactsPresent = !!contacts.length;

  useEffect(() => {
    if (!contactsPresent && !isLoadingFailed) {
      dispatch(actionCreators.getCompanyContacts());
    }
  }, [contactsPresent, isLoadingFailed, dispatch]);

  if (!companyInfo) {
    return null;
  }

  const phoneParts = getPhoneParts(companyInfo.single_phone);

  return (
    <div>
      <h1 className="heading contacts__heading">Контакты</h1>
      <div className="contacts__content">
        <div className="contacts__map">
          <YandexMap restaurants={contacts} />
        </div>
        <div className="contacts__info">
          <div className="contacts__phone">
            <span className="contacts__phone-label">Единый номер телефона</span>
            <a className="contacts__phone-link" href="tel:+78126400506">
              {phoneParts[0]}
              {phoneParts[1]}
            </a>
            <span className="contacts__phone-label telegram__label">
              Telegram:
            </span>
            <a
              className="link green-link"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://t.me/${companyInfo.telegram_contact_username}`}
            >
              @{companyInfo.telegram_contact_username}
            </a>
          </div>
          <div className="contacts__address-list">
            {contacts.map((contact) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={contact.id}>
                <div className="address">
                  {!!contact.metro_stations?.length && (
                    <>
                      <div className="address__icon">
                        <span
                          className="icon-metro"
                          style={{
                            color: `#${contact.metro_stations[0]?.line_hex_color}`,
                          }}
                        />
                      </div>
                      <div className="address__title">
                        {contact.metro_stations[0]?.name}
                      </div>
                    </>
                  )}
                  <div className="address__text">
                    {contact.address} <br />
                    Добавочный номер: {contact.phone_ext}
                  </div>
                  <div className="address__worktime">
                    <span className="icon-worktime" />
                    <div>
                      {contact.info_timetable.map((time, index) =>
                        time.days.from !== time.days.to ? (
                          <div className="address__text" key={index}>
                            {days[time.days.from]} - {days[time.days.to]} c{' '}
                            {time.span[0]} до {time.span[1]}
                          </div>
                        ) : (
                          <div className="address__text" key={index}>
                            {days[time.days.from]} c {time.span[0]} до{' '}
                            {time.span[1]}
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
