import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import ModalWindow from '../../reusable/ModalWindow';
import LoadingSpinner from '../../reusable/LoadingSpinner';
import { Breadcrumbs } from '../misc/Breadcrumbs/Breadcrumbs';

import clsx from 'clsx';
import './Layout.scss';

export const Layout = ({ children }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);

  const pageIsLoading = useSelector(
    (state) => state.apiConnection.pageIsLoading,
  );
  const loadingIsGlobal = useSelector(
    (state) => state.apiConnection.loadingIsGlobal,
  );
  const isSidebarAlwaysOpened = useSelector(
    (state) => state.view.isSidebarAlwaysOpened,
  );
  const isDataLoading = useSelector(
    (state) => state.apiConnection.isDataLoading,
  );

  const showLayout = !(pageIsLoading && loadingIsGlobal);

  const openSidebar = useCallback(() => setSidebarOpen(true), []);
  const closeSidebar = () => setSidebarOpen(false);

  const onWrapperClick = () => {
    !isSidebarAlwaysOpened && setSidebarOpen(false);
  };

  useEffect(() => {
    setSidebarOpen(isSidebarAlwaysOpened);
  }, [isSidebarAlwaysOpened]);

  return (
    <>
      <div className="general-wrapper">
        <ModalWindow />
        <div
          className={clsx('center-wrapper', 'app-content')}
          onClick={onWrapperClick}
        >
          {isDataLoading && <LoadingSpinner needAbsolutePosition={true} />}
          <Breadcrumbs />
          {!pageIsLoading && children}
        </div>
      </div>
      {showLayout && (
        <>
          <Sidebar
            openSidebar={openSidebar}
            closeSidebar={closeSidebar}
            isOpen={sidebarIsOpen}
          />
          <Header
            openSidebar={openSidebar}
            closeSidebar={closeSidebar}
            sidebarIsOpen={sidebarIsOpen}
          />
          <Footer closeSidebar={closeSidebar} />
        </>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
