import './MessageModal.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function MessageModal({ onClose }) {
  const modalContent = useSelector((state) => state.view.modalContent);

  return (
    <div className="message-modal-container">
      <h2 className="log-in-modal-title message-modal-title">
        {modalContent?.title}
      </h2>
      {modalContent?.additionalMessage && (
        <h2 className="message-modal-additional-message">
          {modalContent?.additionalMessage}
        </h2>
      )}
      <p className="confirm-log-out-text">{modalContent?.message}</p>

      <button
        type="button"
        onClick={onClose}
        className="button-standard message-modal-btn"
      >
        {modalContent?.btnDesk}
      </button>
    </div>
  );
}

MessageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MessageModal;
