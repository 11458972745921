import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DELIVERY_AREAS } from '../../util/constants';
import './DeliveryTerms.scss';
import YandexMap from '../../components/YandexMap/YandexMap';
import { Link } from 'react-router-dom';
import * as actionCreators from '../../store/actions';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

const DeliveryTerms = () => {
  useTitleUpdate('Условия доставки');
  const companyInfo = useSelector((state) => state.companyInfo.info);
  const companyRequisites = useSelector(
    (state) => state.companyInfo.requisites,
  );
  const restaurantsInfo = useSelector(
    (state) => state.restaurants.restaurantsInfo,
  );
  const dispatch = useDispatch();
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const restaurantsInfoPresent = !!restaurantsInfo.length;

  useEffect(() => {
    if (!restaurantsInfoPresent && !isLoadingFailed) {
      dispatch(actionCreators.getCompanyContacts());
    }
  }, [restaurantsInfoPresent, isLoadingFailed, dispatch]);

  useEffect(() => {
    dispatch(actionCreators.getCompanyRequisites());
  }, [dispatch]);

  if (!companyInfo) {
    return null;
  }

  return (
    <div className="inner-container inner-container--lg">
      <div className="delivery-terms">
        <h1 className="heading delivery-terms__title">Условия доставки</h1>
        <div className="delivery-terms__content">
          <div className="delivery-terms__text">
            <div>
              Доставка осуществляется строго в соответствии с границами зон
              доставки ресторанов. Ценовой диапазон доставки определяется
              цветами. С зонами доставки можно ознакомиться в приложении и на
              сайте. В случае, если адрес не входит в зону доставки, ресторан по
              своей возможности может оформить платную доставку, или
              организовать доставку через партнеров (доставка оплачивается
              заказчиком).
            </div>
            <div>
              Минимальная сумма заказа составляет{' '}
              {companyInfo.delivery_min_price} рублей, без учета стоимости
              доставки. У заказов самовывоза нет ограничения по сумме.
            </div>
            <div>
              Доставка осуществляется в течение 60-ти минут. При высокой
              нагрузке время доставки может быть увеличено.
            </div>
            <div>
              В случае, если заказчика нет на месте доставки, курьер ожидает на
              адресе в течение 10-ти минут. Если заказчик не выходит на связь,
              ресторан вправе отменить заказ или привезти его позже по
              согласованию с заказчиком.
            </div>
            <div className="delivery-terms__text-title">Меню:</div>
            <div>
              В меню есть позиции, ограниченные по времени заказа. Данные
              позиции отмечены в меню в приложении и на сайте.
            </div>
            <div>
              В меню есть позиции, предназначенные только для предзаказа. Данные
              позиции отмечены в меню в приложении и на сайте. Позиции
              предзаказа могут быть доставлены только при предварительной
              оплате.
            </div>
            <div>Калорийность блюд указана на порцию, не на 100 грамм.</div>
            <div>Мы не доставляем алкогольную продукцию.</div>
          </div>
          <div className="delivery-terms__areas">
            <div className="delivery-terms__areas-title">Зоны доставки:</div>
            <div className="delivery-terms__areas-map">
              <YandexMap restaurants={restaurantsInfo} />
            </div>
            <div className="delivery-terms__areas-desc">
              <div className="delivery-terms__areas-title">Зоны доставки:</div>
              <ul className="delivery-terms__areas-list">
                {DELIVERY_AREAS.map((area, index) => (
                  <li key={index} className="delivery-terms__areas-list-item">
                    - {area}
                  </li>
                ))}
              </ul>
              <Link
                className="button-standard delivery-terms__button"
                to="/проверка-доставки"
              >
                Проверить возможность доставки
              </Link>
            </div>
          </div>
          <div className="delivery-terms__info">
            <div className="delivery-terms__info-item">
              <div className="delivery-terms__info-item-icon">
                <span className="icon-clock" />
              </div>
              <div className="delivery-terms__info-item-title">
                Время работы доставки:
              </div>
              <div className="delivery-terms__info-item-text">
                Заказы на сегодняшний день принимаются до 21:00.
              </div>
              <div className="delivery-terms__info-item-title">
                Время, на которое можно оформить доставку:
              </div>
              <div className="delivery-terms__info-item-text">
                Ежедневно с 11:00 до 21:45.
              </div>
            </div>
            <div className="delivery-terms__info-item">
              <div className="delivery-terms__info-item-icon">
                <span className="icon-wallet" />
              </div>
              <div className="delivery-terms__info-item-title">
                Способы оплаты:
              </div>
              <div className="delivery-terms__info-item-text">
                Наличными курьеру
              </div>
              <div className="delivery-terms__info-item-text">
                Банковской картой <br /> через терминал у курьера
              </div>
              <div className="delivery-terms__info-item-text">
                Онлайн оплата
              </div>
            </div>
            <div className="delivery-terms__info-item">
              <div className="delivery-terms__info-item-icon">
                <span className="icon-file" />
              </div>
              <div className="delivery-terms__info-item-title">Реквизиты:</div>
              <ul>
                {companyRequisites &&
                  companyRequisites.map(({ details, id }) => (
                    <li key={id} className="delivery-terms__info-item-text">
                      {details}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryTerms;
