import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function isLoggedIn() {
  return !!getAccessToken();
}

export function saveCredentials(token) {
  cookies.set('access_token', token, { path: '/' });
}

export function removeToken() {
  cookies.remove('access_token', { path: '/' });
}

export function tokenHeader(headers = {}) {
  return { ...headers, authorization: `Token ${getAccessToken()}` };
}

function getAccessToken() {
  return cookies.get('access_token');
}
