import { useEffect, useRef, useState } from 'react';
import './Profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { getPhoneParts } from '../../util/phone';
import {
  convertDate,
  getIsWeekStartsOnMonOrTue,
  scrollToFirstSelector,
} from '../../util/misc';
import { validateEmail, validateName } from '../../util/validators';
import passLink from '../../assets/img/passbook_icon.png';
import clsx from 'clsx';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ToolbarPicker } from './ToolbarPicker.jsx';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { Divider } from '../../reusable/Divider/Divider';
import TheCheckbox from '../../reusable/TheCheckbox/TheCheckbox';
import * as actionCreators from '../../store/actions';
import { PATHS } from '../../util/constants';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function Profile() {
  useTitleUpdate('Профиль');
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);

  const [selectedDate, setSelectedDate] = useState(
    userInfo ? new Date(userInfo.birthday) : new Date(),
  );
  const [minDate, setMinDate] = useState(startOfMonth(selectedDate));
  const [maxDate, setMaxDate] = useState(lastDayOfMonth(selectedDate));
  const [viewPicker, setViewPicker] = useState('date');
  const [datePickerPosition, setDatePickerPosition] = useState('to-top');
  const datePickerMarker = useRef(null);
  const datePickerContainer = datePickerMarker.current;

  class LocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, 'LLL', { locale: this.locale });
    }

    format(date, formatString) {
      if (formatString === 'MMM') {
        return format(date, 'LLL', { locale: this.locale });
      }
      return super.format(date, formatString);
    }
  }

  useEffect(() => {
    if (!datePickerContainer) return;

    const datePickerMarkerCurrent = datePickerMarker.current;

    const scrollHandler = () => {
      if (datePickerContainer.valueOf().getBoundingClientRect().top > 300) {
        setDatePickerPosition('to-top');
      } else {
        setDatePickerPosition('to-bottom');
      }
    };
    window.addEventListener('scroll', scrollHandler);
    return () => {
      if (datePickerMarkerCurrent) {
        window.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [datePickerContainer]);

  if (!userInfo) {
    return isAuthorized ? null : (
      <h1 className="heading profile-title">Профиль</h1>
    );
  }

  return (
    <section className="profile-section">
      <h1 className="heading profile-title">Профиль</h1>

      <Formik
        initialValues={{
          first_name: userInfo?.first_name || '',
          last_name: userInfo?.last_name || '',
          email: userInfo?.email || '',
          notify_about_orders_by_email: userInfo
            ? userInfo.notify_about_orders_by_email
            : true,
          birthday: userInfo ? userInfo.birthday : '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.birthday = convertDate(values.birthday);
          dispatch(
            actionCreators.patchUserData(values, setSubmitting, PATHS.profile),
          );
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          resetForm,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isValid,
          handleSubmit,
          setTouched,
        }) => (
          <Form
            className="profile-form"
            onSubmit={
              isValid
                ? handleSubmit
                : (ev) => {
                    ev.preventDefault();
                    setTouched(
                      {
                        first_name: true,
                        last_name: true,
                        birthday: true,
                        email: true,
                      },
                      true,
                    );
                    scrollToFirstSelector('.profile-form-message');
                  }
            }
          >
            <div className="profile-header-bar">
              <div className="profile-points">
                <span className="icon-points" />

                <div className="profile-points-wrap">
                  <span className="profile-points-title">Баллы:</span>

                  <span className="profile-points-count">
                    {userInfo?.loyalty_points || 0}
                  </span>
                </div>
              </div>

              <Link
                className="green-link"
                style={{ marginTop: 10 }}
                to="/как-получить-баллы"
              >
                Как получить баллы?
              </Link>
            </div>

            <Divider className="profile-divider" />

            <label className="profile-form-label">
              <Field
                type="text"
                name="first_name"
                className="profile-form-item"
                placeholder="Имя"
                aria-label="Укажите ваше имя"
                aria-invalid={
                  !!(errors.first_name && touched.first_name) || undefined
                }
                onBlur={handleBlur}
                validate={(value) => validateName(value, 2, 30)}
              />

              <span className="profile-form-decorator" />
              {errors.first_name && touched.first_name && (
                <span className="profile-form-message">
                  {errors.first_name}
                </span>
              )}
            </label>

            <label className="profile-form-label">
              <input
                type="tel"
                name="userPhone"
                className="profile-form-item"
                value={userInfo ? getPhoneParts(userInfo.phone).join('') : ''}
                placeholder="Телефон"
                aria-label="Ваш телефон"
                disabled
              />
            </label>

            <label className="profile-form-label">
              <Field
                type="text"
                name="last_name"
                className="profile-form-item"
                placeholder="Фамилия"
                aria-label="Укажите вашу фамилию"
                aria-invalid={
                  !!(errors.last_name && touched.last_name) || undefined
                }
                onBlur={handleBlur}
                validate={(value) => validateName(value, 2, 150)}
              />

              <span className="profile-form-decorator" />
              {errors.last_name && touched.last_name && (
                <span className="profile-form-message">{errors.last_name}</span>
              )}
            </label>

            <label className="profile-form-label">
              <Field
                type="email"
                name="email"
                className="profile-form-item"
                placeholder="Email"
                aria-label="Укажите ваш адрес электронной почты"
                aria-invalid={!!(errors.email && touched.email) || undefined}
                onBlur={handleBlur}
                validate={(value) => validateEmail(value, 254)}
              />

              <span className="profile-form-decorator" />
              {errors.email && touched.email && (
                <span className="profile-form-message">{errors.email}</span>
              )}
            </label>
            <div ref={datePickerMarker} />
            <div
              className={clsx(
                'date-picker-container',
                !getIsWeekStartsOnMonOrTue(selectedDate) &&
                  'six-weeks-container',
                datePickerPosition,
              )}
            >
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ruLocale}>
                <KeyboardDatePicker
                  classes={{
                    root: 'profile-form-datepicker',
                  }}
                  name="birthday"
                  variant="inline"
                  format="dd.MM.yyyy"
                  label="Дата рождения"
                  helperText={null}
                  error={errors.birthday && touched.birthday}
                  onError={(error, value) => {
                    if (!value) {
                      error = 'required field';
                    }
                    if (error !== errors.birthday && error !== '') {
                      setFieldError('birthday', error);
                    }
                  }}
                  onAccept={() => {
                    setFieldError('birthday', null);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Укажите дату вашего рождения',
                  }}
                  onBlur={() => {
                    setFieldTouched('birthday', true);
                  }}
                  value={values.birthday || null}
                  onChange={(date) => {
                    setFieldValue('birthday', date, true);
                    setSelectedDate(
                      !date || date.toString() === 'Invalid Date'
                        ? new Date()
                        : date,
                    );
                  }}
                  onOpen={() => {
                    setSelectedDate(
                      !values.birthday ||
                        values.birthday.toString() === 'Invalid Date'
                        ? new Date()
                        : new Date(values.birthday),
                    );
                  }}
                  autoOk
                  onMonthChange={(date) => {
                    setSelectedDate(date);
                    if (viewPicker === 'month') {
                      setViewPicker('date');
                    }
                  }}
                  onYearChange={(date) => {
                    setSelectedDate(date);
                    if (viewPicker === 'year') {
                      setViewPicker('month');
                    }
                  }}
                  orientation="landscape"
                  PopoverProps={{
                    disablePortal: true,
                    disableScrollLock: true,
                    disableAutoFocus: true,
                    disableEnforceFocus: true,
                    disableRestoreFocus: true,
                  }}
                  disableFuture={true}
                  minDate={viewPicker === 'year' ? minDate : undefined}
                  maxDate={viewPicker === 'year' ? maxDate : new Date()}
                  ToolbarComponent={(props) =>
                    ToolbarPicker({
                      ...props,
                      selectedDate,
                      setSelectedDate,
                      minDate,
                      setMinDate,
                      maxDate,
                      setMaxDate,
                      setViewPicker,
                      viewPicker,
                    })
                  }
                />
              </MuiPickersUtilsProvider>
            </div>

            <TheCheckbox
              handleBlur={handleBlur}
              containerClass="notify"
              name="notify_about_orders_by_email"
              autoComplete="false"
              label="Получать информацию о заказах по email"
            />

            <a
              className="profile-passbook-link"
              href="https://app.getcard.me/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="profile-passbook-img"
                src={passLink}
                alt="добавить в passbook"
              />

              <span>Добавить в Passbook</span>
            </a>

            <div className="profile-btn-group">
              <button
                className="btn profile-cancel-btn"
                type="button"
                onClick={resetForm}
                disabled={!Object.values(touched).some((val) => val)}
              >
                отмена
              </button>

              <button
                className="btn profile-save-btn"
                type="submit"
                disabled={isSubmitting}
              >
                сохранить
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default Profile;
