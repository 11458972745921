import { MESSAGE_TYPES, MODAL_TYPES, PATHS } from '../../util/constants';
import { getTryLaterMessage } from '../../util/misc';
import { setModalContent } from '../view/view';

export const setMessage = ({
  title,
  message,
  btnDesk,
  messageStatus,
  redirectPath,
  ...rest
} = {}) => {
  const modalContent = {
    modalType: MODAL_TYPES.MESSAGE,
    title,
    message,
    btnDesk: btnDesk ?? 'ок',
    redirectPath,
    ...rest,
  };

  switch (messageStatus) {
    case MESSAGE_TYPES.OK:
      return setModalContent({
        ...modalContent,
        title: title ?? 'Отлично!',
      });
    case MESSAGE_TYPES.SUCCESS_PROFILE_EDIT:
      return setModalContent({
        ...modalContent,
        title: title ?? 'Отлично!',
        message: 'Ваши данные сохранены.',
        timeOutToClose: 2000,
      });
    case MESSAGE_TYPES.WARNING:
      return setModalContent({
        ...modalContent,
        title: title ?? 'Внимание!',
      });
    case MESSAGE_TYPES.TRY_LATER_ERROR:
      return setModalContent({
        ...modalContent,
        title: title ?? 'Ошибка!',
        message: getTryLaterMessage(
          message ?? 'Извините, что-то пошло не так!',
        ),
        redirectPath: redirectPath ?? PATHS.index,
      });
    case MESSAGE_TYPES.ERROR:
      return setModalContent({
        ...modalContent,
        title: title ?? 'Ошибка!',
      });
    default:
      return setModalContent({
        ...modalContent,
        title: title ?? '',
        message: message ?? '',
      });
  }
};