import './IconEmoji.scss';
import PropTypes from 'prop-types';

export function IconEmoji({ type }) {
  return (
    <span className={`icon-${type}`}>
      <span className="icon-path1" />
      <span className="icon-path2 icon-size" />
      <span className="icon-path3 icon-size" />
      <span className="icon-path4 icon-size" />
      <span className="icon-path5 icon-size" />
    </span>
  );
}

IconEmoji.propTypes = {
  type: PropTypes.oneOf(['bad', 'fine']).isRequired,
};
