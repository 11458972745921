import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './SearchPage.scss';
import DishGrid from '../../reusable/DishGrid/DishGrid';
import EmptyPage from '../../reusable/EmptyPage/EmptyPage';
import * as actionCreators from '../../store/actions';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function SearchPage() {
  useTitleUpdate('Поиск');

  const { query } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const searchResult = useSelector((state) => state.searchResults.searchResult);
  const searchResultPresent = !!searchResult.length;
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );

  useEffect(() => {
    if (query.length < 2) {
      history.push('/');
    }
  }, [query, history]);

  useEffect(() => {
    if (!searchResultPresent && !isLoadingFailed) {
      dispatch(actionCreators.getSearchResults(query));
    }
  }, []);

  if (!searchResult.length) {
    return <EmptyPage topText="По данному запросу ничего не найдено" />;
  }

  return (
    <section className="search-result__section">
      <DishGrid dishes={searchResult} suggestionsSearch={true} />
    </section>
  );
}

export default SearchPage;
