import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    restaurantsInfo: [],
    currentRestaurantInfo: null,
};

const restaurantsSlice = createSlice({
    name: 'restaurants',
    initialState,
    reducers: {
        setRestaurantsInfo: (state, { payload }) => {
            state.restaurantsInfo = payload;
        },
        setCurrentRestaurantInfo: (state, { payload }) => {
            state.currentRestaurantInfo = payload;
        },
    },
});

export const {
    setRestaurantsInfo,
    setCurrentRestaurantInfo
} = restaurantsSlice.actions;

export default restaurantsSlice.reducer;
