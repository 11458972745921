import { useEffect, useState } from 'react';
import './Questionnaire.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { getPhoneParts } from '../../util/phone';
import {
  convertDate,
  dateTimeToISOString,
  fileListToBase64,
  findRequiredObject,
  getCurrentTime,
} from '../../util/misc';
import {
  validatePhone,
  validateRestaurantId,
  validateQuestionnaireAnswer,
} from '../../util/validators';
import { QuestionConstructor } from './QuestionConstructor';
import * as actionCreators from '../../store/actions';
import { Divider } from '../../reusable/Divider/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { MODAL_TYPES, PHONE_MASK } from '../../util/constants';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

function Questionnaire() {
  useTitleUpdate('Отзыв о заказе');
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => state.currentUser.userInfo);
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const restaurantsList = useSelector((state) => state.companyInfo.contacts);
  const isLoadingFailed = useSelector(
    (state) => state.apiConnection.isLoadingFailed,
  );
  const restaurantId = useSelector((state) => state.orders.restaurantId);
  const surveyQuestions = useSelector((state) => state.orders.surveyQuestions);
  const restaurantsListPresent = !!restaurantsList.length;
  const surveyQuestionsPresent = !!surveyQuestions.length;
  // sorted questions
  const [sortedQuestions, setSortedQuestions] = useState([]);

  useEffect(() => {
    if (!restaurantsListPresent && !isLoadingFailed) {
      dispatch(actionCreators.getCompanyContacts());
    }
  }, [restaurantsListPresent, isLoadingFailed, dispatch]);

  // optional questions
  const [optionalAnswer, setOptionalAnswer] = useState({});

  useEffect(() => {
    if (!surveyQuestionsPresent && !isLoadingFailed && isAuthorized) {
      dispatch(actionCreators.getSurveyQuestions());
    }
    if (surveyQuestionsPresent) {
      const validateOptionalAnswer = {};
      surveyQuestions.forEach((question) => {
        if (question.is_mandatory) {
          validateOptionalAnswer[question.question] = -1;
        }
      });
      setOptionalAnswer(validateOptionalAnswer);
    }
  }, [surveyQuestionsPresent, isLoadingFailed, isAuthorized]);

  const { orderId } = useParams();

  useEffect(() => {
    if (!isAuthorized && orderId) {
      history.push('/');
      dispatch(
        actionCreators.setModalContent({
          modalType: MODAL_TYPES.LOG_IN,
          btnType: 'addToBasket',
        }),
      );
    }
  }, [orderId, dispatch, history, isAuthorized]);

  // static questions
  const [orderNumber, setOrderNumber] = useState(orderId || '');
  const [firstName, setFirstName] = useState(userInfo?.first_name || '');
  const [lastName, setLastName] = useState(userInfo?.last_name || '');
  const [phone, setPhone] = useState(
    !!userInfo ? getPhoneParts(userInfo.phone).join('') : '',
  );
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedRestaurant, setSelectedRestaurant] = useState('');

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.first_name);
      setLastName(userInfo.last_name);
      setPhone(getPhoneParts(userInfo.phone).join(''));
    }
  }, [userInfo]);

  useEffect(() => {
    const surveyQuestionsSorted = surveyQuestions.toSorted((a, b) =>
      a.sort_order > b.sort_order ? 1 : -1,
    );
    setSortedQuestions(surveyQuestionsSorted);
  }, [surveyQuestions]);

  // input type file
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileUpload = (ev) => {
    const files = Array.from(ev.target.files).slice(0, 3);
    setSelectedFiles(files);
  };
  const handleFileClean = (ev) => {
    ev.preventDefault();
    const files = selectedFiles.filter(
      (file) => file.name !== ev.target.dataset.name,
    );
    setSelectedFiles(files);
  };

  function getSurveyType(order) {
    if (!!order) {
      return 'by_order';
    }
    return 'not_by_order';
  }

  const submitHandler = (ev) => {
    ev.preventDefault();

    const answersValues = {
      client: userInfo?.id || null,
      first_name: validateQuestionnaireAnswer(firstName, 2, 30),
      last_name: validateQuestionnaireAnswer(lastName, 2, 30),
      phone: validatePhone(phone),
      survey_type: getSurveyType(orderNumber),
      images: selectedFiles,
      order: orderNumber || null,
      dtm: !orderId ? dateTimeToISOString(selectedDate, selectedTime) : null,
      restaurant: !orderId
        ? validateRestaurantId(selectedRestaurant)
        : restaurantId,
    };

    if (
      Object.values(answersValues).includes(-1) ||
      Object.values(optionalAnswer).includes(-1) ||
      Object.values(optionalAnswer).includes('Другое (укажите)')
    ) {
      const combinedValues = !orderId
        ? { ...answersValues, ...optionalAnswer }
        : optionalAnswer;

      for (const prop in combinedValues) {
        if (
          combinedValues[prop] === 'Другое (укажите)' ||
          prop === 'Комментарий'
        ) {
          document
            .getElementById(prop)
            ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
          const element = document.getElementById('textarea' + prop);
          if (element) {
            element.classList.add('text-field-input_error');
          }
          break;
        }
        if (combinedValues[prop] === -1) {
          const element = document.getElementById(prop);
          if (prop === 'dtm') {
            const elements = [...element.getElementsByTagName('input')];
            elements.forEach((el) => {
              if (el.value === '') {
                document
                  .getElementById(el.id)
                  .classList.add('text-field-input_error');
              }
            });
          }
          if (prop === 'first_name' || prop === 'last_name') {
            document
              .getElementById('user-name')
              ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            element.classList.add('text-field-input_error');
            break;
          }
          if (prop === 'phone') {
            document
              .getElementById('phone-input')
              .classList.add('text-field-input_error');
          }

          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
          break;
        }
      }
    } else {
      fileListToBase64(selectedFiles).then((images) => {
        const result = [];
        for (const [key, value] of Object.entries(optionalAnswer)) {
          result.push([key, value]);
        }
        const dataValues = { ...answersValues, result, images };
        dispatch(actionCreators.sendSurveyAnswers(dataValues));
      });
    }
  };

  return (
    <section className="questionnaire-wrapper section-wrapper-medium">
      <h1
        className="heading questionnaire-heading"
        id={orderId ? sortedQuestions[0]?.question : ''}
      >
        отзыв о заказе
      </h1>

      <div className="questionnaire-paper paper-medium">
        <form name="sendSurvey" onSubmit={submitHandler}>
          {!orderId && (
            <ul className="additional-questions">
              <li className="questionnaire-item">
                <h2 className="questionnaire-title">Номер заказа</h2>

                <label className="questionnaire-text-field">
                  <input
                    type="text"
                    className="text-field-input"
                    name="orderNumber"
                    placeholder="Номер заказа (если известен)"
                    aria-label="Введите номер заказа, если он известен"
                    value={orderNumber}
                    onChange={(ev) => setOrderNumber(ev.target.value)}
                  />
                </label>
              </li>

              <li className="questionnaire-item">
                <Divider className="questionnaire-divider" id="user-name" />
                <h2 className="questionnaire-title">Имя и фамилия</h2>

                <label className="questionnaire-text-field">
                  <input
                    type="text"
                    className="text-field-input"
                    name="firstName"
                    placeholder="Имя"
                    aria-label="Введите ваше имя"
                    value={firstName}
                    onChange={(ev) => {
                      setFirstName(ev.target.value);
                      ev.currentTarget.classList.remove(
                        'text-field-input_error',
                      );
                    }}
                    disabled={!!userInfo?.first_name}
                    id="first_name"
                  />
                </label>

                <label className="questionnaire-text-field">
                  <input
                    type="text"
                    className="text-field-input"
                    name="lastName"
                    placeholder="Фамилия"
                    aria-label="Введите вашу фамилию"
                    value={lastName}
                    onChange={(ev) => {
                      setLastName(ev.target.value);
                      ev.currentTarget.classList.remove(
                        'text-field-input_error',
                      );
                    }}
                    disabled={!!userInfo?.last_name}
                    id="last_name"
                  />
                </label>
              </li>

              <li className="questionnaire-item">
                <Divider className="questionnaire-divider" id="phone" />
                <h2 className="questionnaire-title">Телефон</h2>

                <label className="questionnaire-text-field">
                  <MaskedInput
                    className="text-field-input"
                    name="phone"
                    showMask={!isAuthorized}
                    mask={PHONE_MASK}
                    aria-label="Введите ваш телефон"
                    value={phone}
                    onChange={(ev) => {
                      setPhone(ev.target.value);
                      ev.currentTarget.classList.remove(
                        'text-field-input_error',
                      );
                    }}
                    disabled={!!userInfo?.phone}
                    id="phone-input"
                  />
                </label>
              </li>
              <li className="questionnaire-item" id="dtm">
                <Divider className="questionnaire-divider" />
                <h2 className="questionnaire-title">Дата и время посещения</h2>

                <div className="questionnaire-date-time-wrap">
                  <input
                    type="date"
                    className="text-field-input"
                    name="selectedDate"
                    min="1990-12-31"
                    max={convertDate(Date.now())}
                    aria-label="Введите дату посещения"
                    value={selectedDate}
                    onChange={(ev) => {
                      setSelectedDate(ev.target.value);
                      ev.currentTarget.classList.remove(
                        'text-field-input_error',
                      );
                    }}
                    id="input-date"
                  />

                  <input
                    type="time"
                    className="text-field-input"
                    name="selectedTime"
                    max={
                      selectedDate === convertDate(Date.now())
                        ? getCurrentTime()
                        : undefined
                    }
                    aria-label="Введите время посещения"
                    value={selectedTime}
                    onChange={(ev) => {
                      setSelectedTime(ev.target.value);
                      ev.currentTarget.classList.remove(
                        'text-field-input_error',
                      );
                    }}
                    id="input-time"
                  />
                </div>
              </li>

              {restaurantsList.length > 0 && (
                <li className="questionnaire-item" id="restaurant">
                  <Divider className="questionnaire-divider" />
                  <h2 className="questionnaire-title questionnaire-title_list">
                    Ресторан
                  </h2>

                  <FormControl
                    component="fieldset"
                    className="MuiFormControl-root_radio"
                  >
                    <RadioGroup
                      className="questionnaire-radio-group multiple multiple_restaurants"
                      aria-label="Выберите ресторан"
                      name="restaurant"
                      value={selectedRestaurant}
                      onChange={(ev) => setSelectedRestaurant(ev.target.value)}
                    >
                      {restaurantsList.map((restaurant) => (
                        <FormControlLabel
                          key={restaurant.name + restaurant.id}
                          className="questionnaire-radio-desk questionnaire-radio-desk-multiple"
                          value={String(restaurant.id)}
                          control={<Radio />}
                          label={restaurant.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </li>
              )}
            </ul>
          )}

          <ul>
            {isAuthorized &&
              Object.keys(optionalAnswer).map((question, index) => (
                <QuestionConstructor
                  number={index + 1}
                  question={findRequiredObject(sortedQuestions, question)}
                  optionalAnswer={optionalAnswer}
                  key={index}
                  setOptionalAnswer={setOptionalAnswer}
                  needFirstDevider={orderId}
                />
              ))}
          </ul>

          <div className="questionnaire-item">
            <Divider className="questionnaire-divider" />
            <h2 className="questionnaire-title">
              <span>{surveyQuestions.length + 1}. </span>
              Фотографии, если необходимы (до трех штук)
            </h2>

            <div className="questionnaire-upload-field">
              <label className="upload-field-btn button-secondary">
                Выберите файл
                <input
                  className="hidden-input"
                  accept="image/jpeg, image/png"
                  multiple="multiple"
                  name="addImage"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>

              <ul className="upload-field-list">
                {selectedFiles?.map((file) => (
                  <li className="upload-field-item" key={file.name}>
                    <span className="icon-checkbox-check upload-field-icon" />

                    <div className="upload-field-desc">{file.name}</div>

                    <button
                      className="upload-field-clean-btn icon-close"
                      type="button"
                      aria-label="убрать файл из выбранных"
                      data-name={file.name}
                      onClick={handleFileClean}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <Divider className="questionnaire-divider" />
          </div>

          <button
            className="btn profile-save-btn questionnaire-submit-btn"
            type="submit"
          >
            готово
          </button>
        </form>
      </div>
    </section>
  );
}

export default Questionnaire;
