import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import LoadingSpinner from '../../reusable/LoadingSpinner';
import ruLocale from 'date-fns/locale/ru';
import format from 'date-fns/format';
import { getStatusIcon, getPaymentType } from '../../util/orders';
import { getPhoneParts } from '../../util/phone';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as actionCreators from '../../store/actions';
import clsx from 'clsx';
import OrderItem from './OrderItem';
import './Orders.scss';
import { useTitleUpdate } from '../../reusable/hooks/useTitleUpdate';

const Orders = () => {
  useTitleUpdate('Заказы');

  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);

  const orders = useSelector((state) => state.orders.orders);
  const nextPage = useSelector((state) => state.orders.next);

  const currentUser = useSelector((state) => state.currentUser.userInfo);
  const needReloadUserData = useSelector(
    (state) => state.currentUser.needReloadUserData,
  );
  const isTelegramConnected = currentUser?.is_telegram_connected;

  const companyInfo = useSelector((state) => state.companyInfo.info);
  const botStatusName = companyInfo?.telegram_bot_username;
  const botName = companyInfo?.telegram_contact_username;
  const companyPhone = companyInfo?.single_phone;
  const formattedCompanyPhone =
    companyPhone && getPhoneParts(companyPhone).join('');

  useEffect(() => {
    if (!orders.length && nextPage === undefined && isAuthorized) {
      dispatch(actionCreators.getInitialOrders());
    }
  }, [orders, nextPage, isAuthorized, dispatch]);

  const redirectToPayment = (orderId) => {
    dispatch(actionCreators.getPayment(orderId));
  };

  useEffect(() => {
    if (needReloadUserData) {
      dispatch(actionCreators.getCurrentUserInfo(false, history));
    }
  }, []);

  return (
    <section className="orders__section">
      <h1 className="heading orders__title">Заказы</h1>
      {botName && botStatusName && (
        <div className="orders__telegram-info">
          {!isTelegramConnected && (
            <p>
              <a
                className="link green-link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://t.me/${botStatusName}`}
              >
                @{botStatusName}&ensp;
              </a>{' '}
              — получать статусы заказов
            </p>
          )}
          <p>
            <a
              className="link green-link"
              rel="noopener noreferrer"
              href={`https://t.me/${botName}`}
              target="_blank"
            >
              @{botName}
            </a>{' '}
            — связаться с нами
          </p>
        </div>
      )}

      {orders.length ? (
        <InfiniteScroll
          dataLength={orders.length}
          next={() => dispatch(actionCreators.fetchNextOrders(nextPage))}
          hasMore={!!nextPage}
          loader={<LoadingSpinner />}
        >
          {orders.map((order, idx) => (
            <div className="orders__list" key={idx}>
              <div className="order">
                <div className="order__heading">
                  <div className="order__heading_number-container">
                    <div className="order__number">{order.number}</div>
                    <div className="order__content-item order__content-item_status">
                      <div className="order__content-item-value order__content-item-value_status order__content-item-value--status-icon">
                        <span
                          className={`icon-status ${getStatusIcon(order.readable_state)}`}
                        />
                        {order.readable_state}
                      </div>
                    </div>
                  </div>
                  <div className="order__date">
                    {format(order.created, 'dd MMMM yyyy, HH:mm', {
                      locale: ruLocale,
                    })}
                  </div>
                </div>
                <div className="order__content">
                  <div className="order__content-item">
                    <div className="order__content-item-label">Адрес:</div>
                    <div className="order__content-item-value">
                      {order.readable_presence_state === 'Доставка'
                        ? order.address
                        : `${order.restaurant.name}- ${order.restaurant.address}`}
                    </div>
                  </div>
                  <div className="order__content-item">
                    <div className="order__content-item-label">
                      Вариант заказа:
                    </div>
                    <div className="order__content-item-value">
                      {order?.readable_presence_state}
                    </div>
                  </div>
                  <div className="order__content-item">
                    <div className="order__content-item-label">
                      Номер телефона ресторана:
                    </div>
                    <div className="order__content-item-value">
                      <a
                        className="link green-link"
                        href={`tel:+${companyPhone}`}
                      >
                        {formattedCompanyPhone}
                      </a>
                      {order.restaurant.phone_ext &&
                        ` доб. ${order.restaurant.phone_ext}`}
                    </div>
                  </div>
                  <div className="order__content-item">
                    <div className="order__content-item-label">
                      Время доставки:
                    </div>
                    <div className="order__content-item-value">
                      {order.is_asap
                        ? 'Как можно скорее'
                        : format(order.arriving, 'dd MMMM yyyy, HH:mm', {
                          locale: ruLocale,
                        })}
                    </div>
                  </div>
                  <div className="order__content-item">
                    <div className="order__content-item-label">
                      Количество персон:
                    </div>
                    <div className="order__content-item-value">
                      {order.people_amount || 'Приборы не нужны'}
                    </div>
                  </div>
                  <div className="order__content-item">
                    <div className="order__content-item-label">Тип оплаты:</div>
                    <div className="order__content-item-value">
                      {getPaymentType(order.payment_type)}
                      {!order.is_finished &&
                        order.payment_type !== 'yandex' &&
                        !order.paid && (
                          <div>
                            <span
                              className="link green-link"
                              onClick={() => redirectToPayment(order.id)}
                            >
                              Оплатить онлайн
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  {!order.is_finished &&
                    (order.paid || order.payment_type === 'yandex') && (
                      <div className="order__content-item">
                        <div className="order__content-item-label">
                          Статус оплаты:
                        </div>
                        <div className="order__content-item-value">
                          {order.paid
                            ? 'Оплачен'
                            : order.payment_type === 'yandex' &&
                            (order.payment_status !== 'canceled' ? (
                              <>
                                Не оплачен
                                <br />
                                <a
                                  className="link green-link"
                                  rel="noopener noreferrer"
                                  href={order.payment_url}
                                >
                                  Оплатить
                                </a>
                              </>
                            ) : (
                              <>
                                Платеж был отменен
                                <br />
                                <span
                                  className="link green-link"
                                  onClick={() => redirectToPayment(order.id)}
                                >
                                  попробовать оплатить снова
                                </span>
                              </>
                            ))}
                        </div>
                      </div>
                    )}
                  {order.comment && (
                    <div className="order__content-item order__content-item--big">
                      <div className="order__content-item-label">
                        Комментарий пользователя:
                      </div>
                      <div className="order__content-item-value order-comment">
                        {order.comment}
                      </div>
                    </div>
                  )}
                </div>

                <div className="order__dish-list-wrapper">
                  <div className="divider orders__dish-list-divider" />
                  <div className="order__dish-list">
                    {order.positions.map((position, posIdx) => (
                      <OrderItem
                        key={`${idx}-${posIdx}`}
                        position={position}
                        idx={idx}
                        posIdx={posIdx}
                      />
                    ))}
                  </div>
                </div>
                <div className="order__total-price-wrapper">
                  <div className="order__price">
                    <span className="total-price__title">Сумма заказа:</span>
                    <span className="total-price__value">
                      {order.dishes_price_before_discount}{' '}
                      <span className="rub-sign"> ₽</span>
                    </span>
                  </div>
                  <div className="order__price">
                    <span className="total-price__title">Доставка:</span>
                    <span className="total-price__value">
                      {order.delivery_price} &#8381;
                    </span>
                  </div>
                  {!!order.loyalty_points_price_total &&
                    !order.promo_code_discount && (
                      <div className="order__points">
                        <span className="order__points-title">
                          Списано баллов:
                        </span>
                        <span className="order__points-value">{`${order.loyalty_points_price_total}`}</span>
                      </div>
                    )}
                  {!!order.promo_code_discount && (
                    <div className="order__points">
                      <span className="order__points-title">
                        Сумма скидки по промокоду:
                      </span>
                      <span className="order__points-value">{`${order.promo_code_discount} ₽`}</span>
                    </div>
                  )}
                  <div className="order__price order__price_total">
                    <span className="total-price__title">Итого:</span>
                    <span className="total-price__value">
                      {+order.price_total} <span className="rub-sign"> ₽</span>
                    </span>
                  </div>
                </div>
                <div className="divider-container">
                  <div className="divider orders__dish-item-divider" />
                </div>

                <div
                  className={clsx('order__btn-wrapper', {
                    'order__btn-wrapper--without-survey':
                      !order.is_survey_available,
                  })}
                >
                  {order.is_survey_available && (
                    <div className="order__btn-survey">
                      <Link
                        className="link green-link"
                        to={`/отзыв/${order.number}`}
                        onClick={() =>
                          dispatch(
                            actionCreators.setRestaurantId(order.restaurant.id),
                          )
                        }
                      >
                        <span className="icon-star" />
                        Оценить заказ
                      </Link>
                    </div>
                  )}
                  <button
                    className="btn order__btn-repeat-order"
                    onClick={() =>
                      dispatch(actionCreators.repeatOrder(order.id))
                    }
                  >
                    Повторить заказ
                  </button>
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="empty-box">
          <div className="empty-box__icon">
            <span className="icon-empty2" />
          </div>
          <div className="empty-box__text">У вас еще нет заказов</div>
        </div>
      )}
    </section>
  );
};

export default Orders;
