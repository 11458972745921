import './ProductComposition.scss';
import PropTypes from 'prop-types';

function ProductComposition({ calories, carbs, fats, proteins }) {
  const productCompositionArray = [
    { name: 'calories', desc: 'ккал', amount: calories },
    { name: 'proteins', desc: 'белки', amount: proteins },
    { name: 'fats', desc: 'жиры', amount: fats },
    { name: 'carbs', desc: 'углеводы', amount: carbs },
  ];

  function signsAfterDot(amount) {
    return (+amount).toFixed(1).replace(/\.0$/, '');
  }

  return (
    <div className="product-composition">
      {productCompositionArray.map(
        (partOfComposition, idx) =>
          !!partOfComposition.amount && (
            <div className="product-composition__item" key={idx}>
              <div className="product-composition__title">
                {partOfComposition.desc}
              </div>
              <div className="product-composition__value">
                {signsAfterDot(partOfComposition.amount)}
              </div>
            </div>
          ),
      )}
    </div>
  );
}

ProductComposition.propTypes = {
  calories: PropTypes.number,
  carbs: PropTypes.number,
  fats: PropTypes.number,
  proteins: PropTypes.number,
};

export default ProductComposition;
